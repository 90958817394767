@charset "UTF-8";
@import url(../../node_modules/modern-css-reset/src/reset.css);
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/fonts/Open-Sans-italic-300.woff) format("woff"), url(/fonts/Open-Sans-italic-300.ttf) format("truetype"), url(/fonts/Open-Sans-italic-300.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/fonts/Open-Sans-italic-400.woff) format("woff"), url(/fonts/Open-Sans-italic-400.ttf) format("truetype"), url(/fonts/Open-Sans-italic-400.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url(/fonts/Open-Sans-italic-700.woff) format("woff"), url(/fonts/Open-Sans-italic-700.ttf) format("truetype"), url(/fonts/Open-Sans-italic-700.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/Open-Sans-normal-300.woff) format("woff"), url(/fonts/Open-Sans-normal-300.ttf) format("truetype"), url(/fonts/Open-Sans-normal-300.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Open-Sans-normal-400.eot);
  src: local("Open Sans"), url(/fonts/Open-Sans-normal-400.woff) format("woff"), url(/fonts/Open-Sans-normal-400.svg#OpenSans) format("svg"), url(/fonts/Open-Sans-normal-400.ttf) format("truetype"), url(/fonts/Open-Sans-normal-400.eot?#iefix) format("embedded-opentype"), url(/fonts/Open-Sans-normal-400.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Open-Sans-normal-600.woff) format("woff"), url(/fonts/Open-Sans-normal-600.ttf) format("truetype"), url(/fonts/Open-Sans-normal-600.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Open-Sans-normal-700.woff) format("woff"), url(/fonts/Open-Sans-normal-700.ttf) format("truetype"), url(/fonts/Open-Sans-normal-700.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/Open-Sans-normal-800.woff) format("woff"), url(/fonts/Open-Sans-normal-800.ttf) format("truetype"), url(/fonts/Open-Sans-normal-800.woff2) format("woff2");
  font-display: swap; }

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
/**
 * BASE SIZE
 * All calculations are based on this. It’s recommended that
 * you keep it at 1rem because that is the root font size. You
 * can set it to whatever you like and whatever unit you like.
 */
/**
 * SIZE SCALE
 * This is a Major Third scale that powers all the utilities that
 * it is relevant for (font-size, margin, padding). All items are
 * calcuated off the base size, so change that and cascade across
 * your whole project.
 */
/*Typographic scale*/
.is-pulled-left {
  float: left !important; }

.is-pulled-right {
  float: right !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1.25rem 1.25rem;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 1.5rem;
  height: 19px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 1.5rem;
    height: 3px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -8px; }
  .hamburger-inner::after {
    bottom: -8px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 8px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 16px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 8px, 0) rotate(-45deg); }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/fonts/slick.eot");
  src: url("/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/fonts/slick.woff") format("woff"), url("/fonts/slick.ttf") format("truetype"), url("/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
  Slider
*/
.slick-loading .slick-list {
  background: #fff url("/media/css/img/ajax-loader.gif") center center no-repeat; }

/*=================================
  Previous and Next icon buttons
==================================*/
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
  transform: translate(0, -50%); }
  .slick-prev:hover .slick-prev-icon,
  .slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
  .slick-prev:focus .slick-next-icon,
  .slick-next:hover .slick-prev-icon,
  .slick-next:hover .slick-next-icon,
  .slick-next:focus .slick-prev-icon,
  .slick-next:focus .slick-next-icon {
    opacity: 1; }
  .slick-prev:focus,
  .slick-next:focus {
    top: calc(50% - 1px); }
    .slick-prev:focus .slick-prev-icon,
    .slick-prev:focus .slick-next-icon,
    .slick-next:focus .slick-prev-icon,
    .slick-next:focus .slick-next-icon {
      color: orange;
      font-size: 28px;
      margin-left: -2px; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    cursor: default; }
    .slick-prev.slick-disabled .slick-prev-icon,
    .slick-prev.slick-disabled .slick-next-icon,
    .slick-next.slick-disabled .slick-prev-icon,
    .slick-next.slick-disabled .slick-next-icon {
      opacity: 0.25; }
  .slick-prev .slick-prev-icon,
  .slick-prev .slick-next-icon,
  .slick-next .slick-prev-icon,
  .slick-next .slick-next-icon {
    display: block;
    color: black;
    opacity: 0.75;
    font-family: "slick";
    font-size: 24px;
    line-height: 1; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev .slick-prev-icon:before {
    content: "←"; }
    [dir="rtl"] .slick-prev .slick-prev-icon:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next .slick-next-icon:before {
    content: "→"; }
    [dir="rtl"] .slick-next .slick-next-icon:before {
      content: "←"; }

/*==========================
  Slide navigation dots
===========================*/
.slick-slider {
  margin-bottom: 30px; }
  .slick-slider.slick-dotted {
    margin-bottom: 60px; }

.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0; }
    .slick-dots li button {
      display: block;
      height: 20px;
      width: 20px;
      margin-top: -4px;
      margin-left: -4px;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      border: 0;
      background: transparent;
      cursor: pointer; }
      .slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
        opacity: 1; }
      .slick-dots li button:focus .slick-dot-icon:before {
        color: orange; }
      .slick-dots li button .slick-dot-icon {
        color: black;
        opacity: 0.25; }
        .slick-dots li button .slick-dot-icon:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "•";
          font-family: "slick";
          font-size: 12px;
          line-height: 1;
          text-align: center;
          transition: all .05s linear; }
    .slick-dots li.slick-active button:focus .slick-dot-icon {
      color: orange;
      opacity: 1; }
    .slick-dots li.slick-active button .slick-dot-icon {
      color: black;
      opacity: 1; }
      .slick-dots li.slick-active button .slick-dot-icon:before {
        margin-top: -3px;
        margin-left: -2px;
        font-size: 18px; }

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

/*===========================
  Pause/play icon button
============================*/
.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -32px;
  z-index: 10;
  opacity: 0.75;
  background: none;
  border: 0;
  cursor: pointer;
  color: black; }
  .slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
    opacity: 1; }
  .slick-autoplay-toggle-button:focus {
    color: orange; }
  .slick-autoplay-toggle-button .slick-pause-icon:before {
    content: "⏸";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }
  .slick-autoplay-toggle-button .slick-play-icon:before {
    content: "▶";
    width: 20px;
    height: 20px;
    font-family: "slick";
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    text-align: center; }

/* Bulma Utilities */
/* Bulma Utilities */
.button, .input, .textarea, .select select, .file-cta,
.file-name {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  position: relative;
  vertical-align: top; }
  .button:focus, .input:focus, .textarea:focus, .select select:focus, .file-cta:focus,
  .file-name:focus, .is-focused.button, .is-focused.input, .is-focused.textarea, .select select.is-focused, .is-focused.file-cta,
  .is-focused.file-name, .button:active, .input:active, .textarea:active, .select select:active, .file-cta:active,
  .file-name:active, .is-active.button, .is-active.input, .is-active.textarea, .select select.is-active, .is-active.file-cta,
  .is-active.file-name {
    outline: none; }
  .button[disabled], .input[disabled], .textarea[disabled], .select select[disabled], .file-cta[disabled],
  .file-name[disabled],
  fieldset[disabled] .button,
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select,
  fieldset[disabled] .file-cta,
  fieldset[disabled] .file-name {
    cursor: not-allowed; }

.button, .file {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.select:not(.is-multiple):not(.is-loading)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em; }

.table:not(:last-child), .table-container:not(:last-child), .level:not(:last-child) {
  margin-bottom: 1.333rem; }

.button.is-loading::after, .select.is-loading::after, .control.is-loading::after {
  animation: spinAround 500ms infinite linear;
  border: 2px solid #f2f2f2;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em; }

/* Bulma Base */
/* Bulma Elements */
.button {
  background-color: white;
  border-color: #f2f2f2;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap; }
  .button strong {
    color: inherit; }
  .button .icon, .button .icon.is-small, .button .icon.is-medium, .button .icon.is-large {
    height: 1.5em;
    width: 1.5em; }
  .button .icon:first-child:not(:last-child) {
    margin-left: calc(-0.5em - 1px);
    margin-right: 0.25em; }
  .button .icon:last-child:not(:first-child) {
    margin-left: 0.25em;
    margin-right: calc(-0.5em - 1px); }
  .button .icon:first-child:last-child {
    margin-left: calc(-0.5em - 1px);
    margin-right: calc(-0.5em - 1px); }
  .button:hover, .button.is-hovered {
    border-color: #b5b5b5;
    color: #363636; }
  .button:focus, .button.is-focused {
    border-color: #485fc7;
    color: #363636; }
    .button:focus:not(:active), .button.is-focused:not(:active) {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .button:active, .button.is-active {
    border-color: #4a4a4a;
    color: #363636; }
  .button.is-text {
    background-color: transparent;
    border-color: transparent;
    color: #4a4a4a;
    text-decoration: underline; }
    .button.is-text:hover, .button.is-text.is-hovered, .button.is-text:focus, .button.is-text.is-focused {
      background-color: whitesmoke;
      color: #363636; }
    .button.is-text:active, .button.is-text.is-active {
      background-color: #e8e8e8;
      color: #363636; }
    .button.is-text[disabled],
    fieldset[disabled] .button.is-text {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none; }
  .button.is-ghost {
    background: none;
    border-color: transparent;
    color: #485fc7;
    text-decoration: none; }
    .button.is-ghost:hover, .button.is-ghost.is-hovered {
      color: #485fc7;
      text-decoration: underline; }
  .button.is-white {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
    .button.is-white:hover, .button.is-white.is-hovered {
      background-color: #f9f9f9;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white:focus, .button.is-white.is-focused {
      border-color: transparent;
      color: #0a0a0a; }
      .button.is-white:focus:not(:active), .button.is-white.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
    .button.is-white:active, .button.is-white.is-active {
      background-color: #f2f2f2;
      border-color: transparent;
      color: #0a0a0a; }
    .button.is-white[disabled],
    fieldset[disabled] .button.is-white {
      background-color: white;
      border-color: transparent;
      box-shadow: none; }
    .button.is-white.is-inverted {
      background-color: #0a0a0a;
      color: white; }
      .button.is-white.is-inverted:hover, .button.is-white.is-inverted.is-hovered {
        background-color: black; }
      .button.is-white.is-inverted[disabled],
      fieldset[disabled] .button.is-white.is-inverted {
        background-color: #0a0a0a;
        border-color: transparent;
        box-shadow: none;
        color: white; }
    .button.is-white.is-loading::after {
      border-color: transparent transparent #0a0a0a #0a0a0a !important; }
    .button.is-white.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-white.is-outlined:hover, .button.is-white.is-outlined.is-hovered, .button.is-white.is-outlined:focus, .button.is-white.is-outlined.is-focused {
        background-color: white;
        border-color: white;
        color: #0a0a0a; }
      .button.is-white.is-outlined.is-loading::after {
        border-color: transparent transparent white white !important; }
      .button.is-white.is-outlined.is-loading:hover::after, .button.is-white.is-outlined.is-loading.is-hovered::after, .button.is-white.is-outlined.is-loading:focus::after, .button.is-white.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-white.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
    .button.is-white.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-white.is-inverted.is-outlined:hover, .button.is-white.is-inverted.is-outlined.is-hovered, .button.is-white.is-inverted.is-outlined:focus, .button.is-white.is-inverted.is-outlined.is-focused {
        background-color: #0a0a0a;
        color: white; }
      .button.is-white.is-inverted.is-outlined.is-loading:hover::after, .button.is-white.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-white.is-inverted.is-outlined.is-loading:focus::after, .button.is-white.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent white white !important; }
      .button.is-white.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-white.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
  .button.is-black {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
    .button.is-black:hover, .button.is-black.is-hovered {
      background-color: #040404;
      border-color: transparent;
      color: white; }
    .button.is-black:focus, .button.is-black.is-focused {
      border-color: transparent;
      color: white; }
      .button.is-black:focus:not(:active), .button.is-black.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
    .button.is-black:active, .button.is-black.is-active {
      background-color: black;
      border-color: transparent;
      color: white; }
    .button.is-black[disabled],
    fieldset[disabled] .button.is-black {
      background-color: #0a0a0a;
      border-color: transparent;
      box-shadow: none; }
    .button.is-black.is-inverted {
      background-color: white;
      color: #0a0a0a; }
      .button.is-black.is-inverted:hover, .button.is-black.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-black.is-inverted[disabled],
      fieldset[disabled] .button.is-black.is-inverted {
        background-color: white;
        border-color: transparent;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-loading::after {
      border-color: transparent transparent white white !important; }
    .button.is-black.is-outlined {
      background-color: transparent;
      border-color: #0a0a0a;
      color: #0a0a0a; }
      .button.is-black.is-outlined:hover, .button.is-black.is-outlined.is-hovered, .button.is-black.is-outlined:focus, .button.is-black.is-outlined.is-focused {
        background-color: #0a0a0a;
        border-color: #0a0a0a;
        color: white; }
      .button.is-black.is-outlined.is-loading::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-black.is-outlined.is-loading:hover::after, .button.is-black.is-outlined.is-loading.is-hovered::after, .button.is-black.is-outlined.is-loading:focus::after, .button.is-black.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent white white !important; }
      .button.is-black.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-outlined {
        background-color: transparent;
        border-color: #0a0a0a;
        box-shadow: none;
        color: #0a0a0a; }
    .button.is-black.is-inverted.is-outlined {
      background-color: transparent;
      border-color: white;
      color: white; }
      .button.is-black.is-inverted.is-outlined:hover, .button.is-black.is-inverted.is-outlined.is-hovered, .button.is-black.is-inverted.is-outlined:focus, .button.is-black.is-inverted.is-outlined.is-focused {
        background-color: white;
        color: #0a0a0a; }
      .button.is-black.is-inverted.is-outlined.is-loading:hover::after, .button.is-black.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-black.is-inverted.is-outlined.is-loading:focus::after, .button.is-black.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #0a0a0a #0a0a0a !important; }
      .button.is-black.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-black.is-inverted.is-outlined {
        background-color: transparent;
        border-color: white;
        box-shadow: none;
        color: white; }
  .button.is-light {
    background-color: whitesmoke;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-light:hover, .button.is-light.is-hovered {
      background-color: #eeeeee;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-light:focus, .button.is-light.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-light:focus:not(:active), .button.is-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
    .button.is-light:active, .button.is-light.is-active {
      background-color: #e8e8e8;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-light[disabled],
    fieldset[disabled] .button.is-light {
      background-color: whitesmoke;
      border-color: transparent;
      box-shadow: none; }
    .button.is-light.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: whitesmoke; }
      .button.is-light.is-inverted:hover, .button.is-light.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-inverted[disabled],
      fieldset[disabled] .button.is-light.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-light.is-outlined {
      background-color: transparent;
      border-color: whitesmoke;
      color: whitesmoke; }
      .button.is-light.is-outlined:hover, .button.is-light.is-outlined.is-hovered, .button.is-light.is-outlined:focus, .button.is-light.is-outlined.is-focused {
        background-color: whitesmoke;
        border-color: whitesmoke;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-outlined.is-loading::after {
        border-color: transparent transparent whitesmoke whitesmoke !important; }
      .button.is-light.is-outlined.is-loading:hover::after, .button.is-light.is-outlined.is-loading.is-hovered::after, .button.is-light.is-outlined.is-loading:focus::after, .button.is-light.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-light.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-outlined {
        background-color: transparent;
        border-color: whitesmoke;
        box-shadow: none;
        color: whitesmoke; }
    .button.is-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-light.is-inverted.is-outlined:hover, .button.is-light.is-inverted.is-outlined.is-hovered, .button.is-light.is-inverted.is-outlined:focus, .button.is-light.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: whitesmoke; }
      .button.is-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-light.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent whitesmoke whitesmoke !important; }
      .button.is-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
  .button.is-dark {
    background-color: #363636;
    border-color: transparent;
    color: #fff; }
    .button.is-dark:hover, .button.is-dark.is-hovered {
      background-color: #2f2f2f;
      border-color: transparent;
      color: #fff; }
    .button.is-dark:focus, .button.is-dark.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-dark:focus:not(:active), .button.is-dark.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
    .button.is-dark:active, .button.is-dark.is-active {
      background-color: #292929;
      border-color: transparent;
      color: #fff; }
    .button.is-dark[disabled],
    fieldset[disabled] .button.is-dark {
      background-color: #363636;
      border-color: transparent;
      box-shadow: none; }
    .button.is-dark.is-inverted {
      background-color: #fff;
      color: #363636; }
      .button.is-dark.is-inverted:hover, .button.is-dark.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-dark.is-inverted[disabled],
      fieldset[disabled] .button.is-dark.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #363636; }
    .button.is-dark.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-dark.is-outlined {
      background-color: transparent;
      border-color: #363636;
      color: #363636; }
      .button.is-dark.is-outlined:hover, .button.is-dark.is-outlined.is-hovered, .button.is-dark.is-outlined:focus, .button.is-dark.is-outlined.is-focused {
        background-color: #363636;
        border-color: #363636;
        color: #fff; }
      .button.is-dark.is-outlined.is-loading::after {
        border-color: transparent transparent #363636 #363636 !important; }
      .button.is-dark.is-outlined.is-loading:hover::after, .button.is-dark.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-outlined.is-loading:focus::after, .button.is-dark.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-dark.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-outlined {
        background-color: transparent;
        border-color: #363636;
        box-shadow: none;
        color: #363636; }
    .button.is-dark.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-dark.is-inverted.is-outlined:hover, .button.is-dark.is-inverted.is-outlined.is-hovered, .button.is-dark.is-inverted.is-outlined:focus, .button.is-dark.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #363636; }
      .button.is-dark.is-inverted.is-outlined.is-loading:hover::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-dark.is-inverted.is-outlined.is-loading:focus::after, .button.is-dark.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #363636 #363636 !important; }
      .button.is-dark.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-dark.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
  .button.is-primary {
    background-color: #0070b8;
    border-color: transparent;
    color: #fff; }
    .button.is-primary:hover, .button.is-primary.is-hovered {
      background-color: #0068ab;
      border-color: transparent;
      color: #fff; }
    .button.is-primary:focus, .button.is-primary.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
    .button.is-primary:active, .button.is-primary.is-active {
      background-color: #00609f;
      border-color: transparent;
      color: #fff; }
    .button.is-primary[disabled],
    fieldset[disabled] .button.is-primary {
      background-color: #0070b8;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary.is-inverted {
      background-color: #fff;
      color: #0070b8; }
      .button.is-primary.is-inverted:hover, .button.is-primary.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-primary.is-inverted[disabled],
      fieldset[disabled] .button.is-primary.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #0070b8; }
    .button.is-primary.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-primary.is-outlined {
      background-color: transparent;
      border-color: #0070b8;
      color: #0070b8; }
      .button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
        background-color: #0070b8;
        border-color: #0070b8;
        color: #fff; }
      .button.is-primary.is-outlined.is-loading::after {
        border-color: transparent transparent #0070b8 #0070b8 !important; }
      .button.is-primary.is-outlined.is-loading:hover::after, .button.is-primary.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-outlined.is-loading:focus::after, .button.is-primary.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-primary.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-outlined {
        background-color: transparent;
        border-color: #0070b8;
        box-shadow: none;
        color: #0070b8; }
    .button.is-primary.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-primary.is-inverted.is-outlined:hover, .button.is-primary.is-inverted.is-outlined.is-hovered, .button.is-primary.is-inverted.is-outlined:focus, .button.is-primary.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #0070b8; }
      .button.is-primary.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #0070b8 #0070b8 !important; }
      .button.is-primary.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-primary.is-light {
      background-color: #cce2f1;
      color: rgba(0, 112, 184, 0.5); }
      .button.is-primary.is-light:hover, .button.is-primary.is-light.is-hovered {
        background-color: #c2dcee;
        border-color: transparent;
        color: rgba(0, 112, 184, 0.5); }
      .button.is-primary.is-light:active, .button.is-primary.is-light.is-active {
        background-color: #b8d7ec;
        border-color: transparent;
        color: rgba(0, 112, 184, 0.5); }
  .button.is-link {
    background-color: #485fc7;
    border-color: transparent;
    color: #fff; }
    .button.is-link:hover, .button.is-link.is-hovered {
      background-color: #3e56c4;
      border-color: transparent;
      color: #fff; }
    .button.is-link:focus, .button.is-link.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-link:focus:not(:active), .button.is-link.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
    .button.is-link:active, .button.is-link.is-active {
      background-color: #3a51bb;
      border-color: transparent;
      color: #fff; }
    .button.is-link[disabled],
    fieldset[disabled] .button.is-link {
      background-color: #485fc7;
      border-color: transparent;
      box-shadow: none; }
    .button.is-link.is-inverted {
      background-color: #fff;
      color: #485fc7; }
      .button.is-link.is-inverted:hover, .button.is-link.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-link.is-inverted[disabled],
      fieldset[disabled] .button.is-link.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #485fc7; }
    .button.is-link.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-link.is-outlined {
      background-color: transparent;
      border-color: #485fc7;
      color: #485fc7; }
      .button.is-link.is-outlined:hover, .button.is-link.is-outlined.is-hovered, .button.is-link.is-outlined:focus, .button.is-link.is-outlined.is-focused {
        background-color: #485fc7;
        border-color: #485fc7;
        color: #fff; }
      .button.is-link.is-outlined.is-loading::after {
        border-color: transparent transparent #485fc7 #485fc7 !important; }
      .button.is-link.is-outlined.is-loading:hover::after, .button.is-link.is-outlined.is-loading.is-hovered::after, .button.is-link.is-outlined.is-loading:focus::after, .button.is-link.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-link.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-outlined {
        background-color: transparent;
        border-color: #485fc7;
        box-shadow: none;
        color: #485fc7; }
    .button.is-link.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-link.is-inverted.is-outlined:hover, .button.is-link.is-inverted.is-outlined.is-hovered, .button.is-link.is-inverted.is-outlined:focus, .button.is-link.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #485fc7; }
      .button.is-link.is-inverted.is-outlined.is-loading:hover::after, .button.is-link.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-link.is-inverted.is-outlined.is-loading:focus::after, .button.is-link.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #485fc7 #485fc7 !important; }
      .button.is-link.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-link.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-link.is-light {
      background-color: #eff1fa;
      color: #3850b7; }
      .button.is-link.is-light:hover, .button.is-link.is-light.is-hovered {
        background-color: #e6e9f7;
        border-color: transparent;
        color: #3850b7; }
      .button.is-link.is-light:active, .button.is-link.is-light.is-active {
        background-color: #dce0f4;
        border-color: transparent;
        color: #3850b7; }
  .button.is-info {
    background-color: #3e8ed0;
    border-color: transparent;
    color: #fff; }
    .button.is-info:hover, .button.is-info.is-hovered {
      background-color: #3488ce;
      border-color: transparent;
      color: #fff; }
    .button.is-info:focus, .button.is-info.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-info:focus:not(:active), .button.is-info.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
    .button.is-info:active, .button.is-info.is-active {
      background-color: #3082c5;
      border-color: transparent;
      color: #fff; }
    .button.is-info[disabled],
    fieldset[disabled] .button.is-info {
      background-color: #3e8ed0;
      border-color: transparent;
      box-shadow: none; }
    .button.is-info.is-inverted {
      background-color: #fff;
      color: #3e8ed0; }
      .button.is-info.is-inverted:hover, .button.is-info.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-info.is-inverted[disabled],
      fieldset[disabled] .button.is-info.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #3e8ed0; }
    .button.is-info.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-info.is-outlined {
      background-color: transparent;
      border-color: #3e8ed0;
      color: #3e8ed0; }
      .button.is-info.is-outlined:hover, .button.is-info.is-outlined.is-hovered, .button.is-info.is-outlined:focus, .button.is-info.is-outlined.is-focused {
        background-color: #3e8ed0;
        border-color: #3e8ed0;
        color: #fff; }
      .button.is-info.is-outlined.is-loading::after {
        border-color: transparent transparent #3e8ed0 #3e8ed0 !important; }
      .button.is-info.is-outlined.is-loading:hover::after, .button.is-info.is-outlined.is-loading.is-hovered::after, .button.is-info.is-outlined.is-loading:focus::after, .button.is-info.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-info.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-outlined {
        background-color: transparent;
        border-color: #3e8ed0;
        box-shadow: none;
        color: #3e8ed0; }
    .button.is-info.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-info.is-inverted.is-outlined:hover, .button.is-info.is-inverted.is-outlined.is-hovered, .button.is-info.is-inverted.is-outlined:focus, .button.is-info.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #3e8ed0; }
      .button.is-info.is-inverted.is-outlined.is-loading:hover::after, .button.is-info.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-info.is-inverted.is-outlined.is-loading:focus::after, .button.is-info.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #3e8ed0 #3e8ed0 !important; }
      .button.is-info.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-info.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-info.is-light {
      background-color: #eff5fb;
      color: #296fa8; }
      .button.is-info.is-light:hover, .button.is-info.is-light.is-hovered {
        background-color: #e4eff9;
        border-color: transparent;
        color: #296fa8; }
      .button.is-info.is-light:active, .button.is-info.is-light.is-active {
        background-color: #dae9f6;
        border-color: transparent;
        color: #296fa8; }
  .button.is-success {
    background-color: #63aa16;
    border-color: transparent;
    color: #fff; }
    .button.is-success:hover, .button.is-success.is-hovered {
      background-color: #5c9f15;
      border-color: transparent;
      color: #fff; }
    .button.is-success:focus, .button.is-success.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-success:focus:not(:active), .button.is-success.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(99, 170, 22, 0.25); }
    .button.is-success:active, .button.is-success.is-active {
      background-color: #569313;
      border-color: transparent;
      color: #fff; }
    .button.is-success[disabled],
    fieldset[disabled] .button.is-success {
      background-color: #63aa16;
      border-color: transparent;
      box-shadow: none; }
    .button.is-success.is-inverted {
      background-color: #fff;
      color: #63aa16; }
      .button.is-success.is-inverted:hover, .button.is-success.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-success.is-inverted[disabled],
      fieldset[disabled] .button.is-success.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #63aa16; }
    .button.is-success.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-success.is-outlined {
      background-color: transparent;
      border-color: #63aa16;
      color: #63aa16; }
      .button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
        background-color: #63aa16;
        border-color: #63aa16;
        color: #fff; }
      .button.is-success.is-outlined.is-loading::after {
        border-color: transparent transparent #63aa16 #63aa16 !important; }
      .button.is-success.is-outlined.is-loading:hover::after, .button.is-success.is-outlined.is-loading.is-hovered::after, .button.is-success.is-outlined.is-loading:focus::after, .button.is-success.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-success.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-outlined {
        background-color: transparent;
        border-color: #63aa16;
        box-shadow: none;
        color: #63aa16; }
    .button.is-success.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-success.is-inverted.is-outlined:hover, .button.is-success.is-inverted.is-outlined.is-hovered, .button.is-success.is-inverted.is-outlined:focus, .button.is-success.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #63aa16; }
      .button.is-success.is-inverted.is-outlined.is-loading:hover::after, .button.is-success.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-success.is-inverted.is-outlined.is-loading:focus::after, .button.is-success.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #63aa16 #63aa16 !important; }
      .button.is-success.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-success.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-success.is-light {
      background-color: #f5fded;
      color: #61a716; }
      .button.is-success.is-light:hover, .button.is-success.is-light.is-hovered {
        background-color: #effbe2;
        border-color: transparent;
        color: #61a716; }
      .button.is-success.is-light:active, .button.is-success.is-light.is-active {
        background-color: #e9fad6;
        border-color: transparent;
        color: #61a716; }
  .button.is-warning {
    background-color: #ffe08a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:hover, .button.is-warning.is-hovered {
      background-color: #ffdc7d;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning:focus, .button.is-warning.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning:focus:not(:active), .button.is-warning.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
    .button.is-warning:active, .button.is-warning.is-active {
      background-color: #ffd970;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-warning[disabled],
    fieldset[disabled] .button.is-warning {
      background-color: #ffe08a;
      border-color: transparent;
      box-shadow: none; }
    .button.is-warning.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #ffe08a; }
      .button.is-warning.is-inverted:hover, .button.is-warning.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted[disabled],
      fieldset[disabled] .button.is-warning.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #ffe08a; }
    .button.is-warning.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-warning.is-outlined {
      background-color: transparent;
      border-color: #ffe08a;
      color: #ffe08a; }
      .button.is-warning.is-outlined:hover, .button.is-warning.is-outlined.is-hovered, .button.is-warning.is-outlined:focus, .button.is-warning.is-outlined.is-focused {
        background-color: #ffe08a;
        border-color: #ffe08a;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-outlined.is-loading::after {
        border-color: transparent transparent #ffe08a #ffe08a !important; }
      .button.is-warning.is-outlined.is-loading:hover::after, .button.is-warning.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-outlined.is-loading:focus::after, .button.is-warning.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-warning.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-outlined {
        background-color: transparent;
        border-color: #ffe08a;
        box-shadow: none;
        color: #ffe08a; }
    .button.is-warning.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-warning.is-inverted.is-outlined:hover, .button.is-warning.is-inverted.is-outlined.is-hovered, .button.is-warning.is-inverted.is-outlined:focus, .button.is-warning.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffe08a; }
      .button.is-warning.is-inverted.is-outlined.is-loading:hover::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-warning.is-inverted.is-outlined.is-loading:focus::after, .button.is-warning.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #ffe08a #ffe08a !important; }
      .button.is-warning.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-warning.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-warning.is-light {
      background-color: #fffaeb;
      color: #946c00; }
      .button.is-warning.is-light:hover, .button.is-warning.is-light.is-hovered {
        background-color: #fff6de;
        border-color: transparent;
        color: #946c00; }
      .button.is-warning.is-light:active, .button.is-warning.is-light.is-active {
        background-color: #fff3d1;
        border-color: transparent;
        color: #946c00; }
  .button.is-danger {
    background-color: #d0021b;
    border-color: transparent;
    color: #fff; }
    .button.is-danger:hover, .button.is-danger.is-hovered {
      background-color: #c30219;
      border-color: transparent;
      color: #fff; }
    .button.is-danger:focus, .button.is-danger.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-danger:focus:not(:active), .button.is-danger.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(208, 2, 27, 0.25); }
    .button.is-danger:active, .button.is-danger.is-active {
      background-color: #b70218;
      border-color: transparent;
      color: #fff; }
    .button.is-danger[disabled],
    fieldset[disabled] .button.is-danger {
      background-color: #d0021b;
      border-color: transparent;
      box-shadow: none; }
    .button.is-danger.is-inverted {
      background-color: #fff;
      color: #d0021b; }
      .button.is-danger.is-inverted:hover, .button.is-danger.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-danger.is-inverted[disabled],
      fieldset[disabled] .button.is-danger.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #d0021b; }
    .button.is-danger.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-danger.is-outlined {
      background-color: transparent;
      border-color: #d0021b;
      color: #d0021b; }
      .button.is-danger.is-outlined:hover, .button.is-danger.is-outlined.is-hovered, .button.is-danger.is-outlined:focus, .button.is-danger.is-outlined.is-focused {
        background-color: #d0021b;
        border-color: #d0021b;
        color: #fff; }
      .button.is-danger.is-outlined.is-loading::after {
        border-color: transparent transparent #d0021b #d0021b !important; }
      .button.is-danger.is-outlined.is-loading:hover::after, .button.is-danger.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-outlined.is-loading:focus::after, .button.is-danger.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-danger.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-outlined {
        background-color: transparent;
        border-color: #d0021b;
        box-shadow: none;
        color: #d0021b; }
    .button.is-danger.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-danger.is-inverted.is-outlined:hover, .button.is-danger.is-inverted.is-outlined.is-hovered, .button.is-danger.is-inverted.is-outlined:focus, .button.is-danger.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #d0021b; }
      .button.is-danger.is-inverted.is-outlined.is-loading:hover::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-danger.is-inverted.is-outlined.is-loading:focus::after, .button.is-danger.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #d0021b #d0021b !important; }
      .button.is-danger.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-danger.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-danger.is-light {
      background-color: #ffebed;
      color: #f80220; }
      .button.is-danger.is-light:hover, .button.is-danger.is-light.is-hovered {
        background-color: #ffdee2;
        border-color: transparent;
        color: #f80220; }
      .button.is-danger.is-light:active, .button.is-danger.is-light.is-active {
        background-color: #ffd2d7;
        border-color: transparent;
        color: #f80220; }
  .button.is-primary-light {
    background-color: #cce2f1;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
    .button.is-primary-light:hover, .button.is-primary-light.is-hovered {
      background-color: #c2dcee;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-primary-light:focus, .button.is-primary-light.is-focused {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
      .button.is-primary-light:focus:not(:active), .button.is-primary-light.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(204, 226, 241, 0.25); }
    .button.is-primary-light:active, .button.is-primary-light.is-active {
      background-color: #b8d7ec;
      border-color: transparent;
      color: rgba(0, 0, 0, 0.7); }
    .button.is-primary-light[disabled],
    fieldset[disabled] .button.is-primary-light {
      background-color: #cce2f1;
      border-color: transparent;
      box-shadow: none; }
    .button.is-primary-light.is-inverted {
      background-color: rgba(0, 0, 0, 0.7);
      color: #cce2f1; }
      .button.is-primary-light.is-inverted:hover, .button.is-primary-light.is-inverted.is-hovered {
        background-color: rgba(0, 0, 0, 0.7); }
      .button.is-primary-light.is-inverted[disabled],
      fieldset[disabled] .button.is-primary-light.is-inverted {
        background-color: rgba(0, 0, 0, 0.7);
        border-color: transparent;
        box-shadow: none;
        color: #cce2f1; }
    .button.is-primary-light.is-loading::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
    .button.is-primary-light.is-outlined {
      background-color: transparent;
      border-color: #cce2f1;
      color: #cce2f1; }
      .button.is-primary-light.is-outlined:hover, .button.is-primary-light.is-outlined.is-hovered, .button.is-primary-light.is-outlined:focus, .button.is-primary-light.is-outlined.is-focused {
        background-color: #cce2f1;
        border-color: #cce2f1;
        color: rgba(0, 0, 0, 0.7); }
      .button.is-primary-light.is-outlined.is-loading::after {
        border-color: transparent transparent #cce2f1 #cce2f1 !important; }
      .button.is-primary-light.is-outlined.is-loading:hover::after, .button.is-primary-light.is-outlined.is-loading.is-hovered::after, .button.is-primary-light.is-outlined.is-loading:focus::after, .button.is-primary-light.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 0, 0, 0.7) rgba(0, 0, 0, 0.7) !important; }
      .button.is-primary-light.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-light.is-outlined {
        background-color: transparent;
        border-color: #cce2f1;
        box-shadow: none;
        color: #cce2f1; }
    .button.is-primary-light.is-inverted.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.7);
      color: rgba(0, 0, 0, 0.7); }
      .button.is-primary-light.is-inverted.is-outlined:hover, .button.is-primary-light.is-inverted.is-outlined.is-hovered, .button.is-primary-light.is-inverted.is-outlined:focus, .button.is-primary-light.is-inverted.is-outlined.is-focused {
        background-color: rgba(0, 0, 0, 0.7);
        color: #cce2f1; }
      .button.is-primary-light.is-inverted.is-outlined.is-loading:hover::after, .button.is-primary-light.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-primary-light.is-inverted.is-outlined.is-loading:focus::after, .button.is-primary-light.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #cce2f1 #cce2f1 !important; }
      .button.is-primary-light.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-primary-light.is-inverted.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.7);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.7); }
    .button.is-primary-light.is-light {
      background-color: #eff6fb;
      color: #205274; }
      .button.is-primary-light.is-light:hover, .button.is-primary-light.is-light.is-hovered {
        background-color: #e5f0f8;
        border-color: transparent;
        color: #205274; }
      .button.is-primary-light.is-light:active, .button.is-primary-light.is-light.is-active {
        background-color: #dbebf5;
        border-color: transparent;
        color: #205274; }
  .button.is-secondary {
    background-color: rgba(0, 112, 184, 0.4);
    border-color: transparent;
    color: #fff; }
    .button.is-secondary:hover, .button.is-secondary.is-hovered {
      background-color: rgba(0, 104, 171, 0.4);
      border-color: transparent;
      color: #fff; }
    .button.is-secondary:focus, .button.is-secondary.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-secondary:focus:not(:active), .button.is-secondary.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
    .button.is-secondary:active, .button.is-secondary.is-active {
      background-color: rgba(0, 96, 159, 0.4);
      border-color: transparent;
      color: #fff; }
    .button.is-secondary[disabled],
    fieldset[disabled] .button.is-secondary {
      background-color: rgba(0, 112, 184, 0.4);
      border-color: transparent;
      box-shadow: none; }
    .button.is-secondary.is-inverted {
      background-color: #fff;
      color: rgba(0, 112, 184, 0.4); }
      .button.is-secondary.is-inverted:hover, .button.is-secondary.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-secondary.is-inverted[disabled],
      fieldset[disabled] .button.is-secondary.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: rgba(0, 112, 184, 0.4); }
    .button.is-secondary.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-secondary.is-outlined {
      background-color: transparent;
      border-color: rgba(0, 112, 184, 0.4);
      color: rgba(0, 112, 184, 0.4); }
      .button.is-secondary.is-outlined:hover, .button.is-secondary.is-outlined.is-hovered, .button.is-secondary.is-outlined:focus, .button.is-secondary.is-outlined.is-focused {
        background-color: rgba(0, 112, 184, 0.4);
        border-color: rgba(0, 112, 184, 0.4);
        color: #fff; }
      .button.is-secondary.is-outlined.is-loading::after {
        border-color: transparent transparent rgba(0, 112, 184, 0.4) rgba(0, 112, 184, 0.4) !important; }
      .button.is-secondary.is-outlined.is-loading:hover::after, .button.is-secondary.is-outlined.is-loading.is-hovered::after, .button.is-secondary.is-outlined.is-loading:focus::after, .button.is-secondary.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-secondary.is-outlined[disabled],
      fieldset[disabled] .button.is-secondary.is-outlined {
        background-color: transparent;
        border-color: rgba(0, 112, 184, 0.4);
        box-shadow: none;
        color: rgba(0, 112, 184, 0.4); }
    .button.is-secondary.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-secondary.is-inverted.is-outlined:hover, .button.is-secondary.is-inverted.is-outlined.is-hovered, .button.is-secondary.is-inverted.is-outlined:focus, .button.is-secondary.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: rgba(0, 112, 184, 0.4); }
      .button.is-secondary.is-inverted.is-outlined.is-loading:hover::after, .button.is-secondary.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-secondary.is-inverted.is-outlined.is-loading:focus::after, .button.is-secondary.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent rgba(0, 112, 184, 0.4) rgba(0, 112, 184, 0.4) !important; }
      .button.is-secondary.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-secondary.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-secondary.is-light {
      background-color: rgba(235, 247, 255, 0.4);
      color: rgba(0, 146, 240, 0.4); }
      .button.is-secondary.is-light:hover, .button.is-secondary.is-light.is-hovered {
        background-color: rgba(222, 242, 255, 0.4);
        border-color: transparent;
        color: rgba(0, 146, 240, 0.4); }
      .button.is-secondary.is-light:active, .button.is-secondary.is-light.is-active {
        background-color: rgba(209, 237, 255, 0.4);
        border-color: transparent;
        color: rgba(0, 146, 240, 0.4); }
  .button.is-secondary-dark {
    background-color: #01375d;
    border-color: transparent;
    color: #fff; }
    .button.is-secondary-dark:hover, .button.is-secondary-dark.is-hovered {
      background-color: #013050;
      border-color: transparent;
      color: #fff; }
    .button.is-secondary-dark:focus, .button.is-secondary-dark.is-focused {
      border-color: transparent;
      color: #fff; }
      .button.is-secondary-dark:focus:not(:active), .button.is-secondary-dark.is-focused:not(:active) {
        box-shadow: 0 0 0 0.125em rgba(1, 55, 93, 0.25); }
    .button.is-secondary-dark:active, .button.is-secondary-dark.is-active {
      background-color: #012844;
      border-color: transparent;
      color: #fff; }
    .button.is-secondary-dark[disabled],
    fieldset[disabled] .button.is-secondary-dark {
      background-color: #01375d;
      border-color: transparent;
      box-shadow: none; }
    .button.is-secondary-dark.is-inverted {
      background-color: #fff;
      color: #01375d; }
      .button.is-secondary-dark.is-inverted:hover, .button.is-secondary-dark.is-inverted.is-hovered {
        background-color: #f2f2f2; }
      .button.is-secondary-dark.is-inverted[disabled],
      fieldset[disabled] .button.is-secondary-dark.is-inverted {
        background-color: #fff;
        border-color: transparent;
        box-shadow: none;
        color: #01375d; }
    .button.is-secondary-dark.is-loading::after {
      border-color: transparent transparent #fff #fff !important; }
    .button.is-secondary-dark.is-outlined {
      background-color: transparent;
      border-color: #01375d;
      color: #01375d; }
      .button.is-secondary-dark.is-outlined:hover, .button.is-secondary-dark.is-outlined.is-hovered, .button.is-secondary-dark.is-outlined:focus, .button.is-secondary-dark.is-outlined.is-focused {
        background-color: #01375d;
        border-color: #01375d;
        color: #fff; }
      .button.is-secondary-dark.is-outlined.is-loading::after {
        border-color: transparent transparent #01375d #01375d !important; }
      .button.is-secondary-dark.is-outlined.is-loading:hover::after, .button.is-secondary-dark.is-outlined.is-loading.is-hovered::after, .button.is-secondary-dark.is-outlined.is-loading:focus::after, .button.is-secondary-dark.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #fff #fff !important; }
      .button.is-secondary-dark.is-outlined[disabled],
      fieldset[disabled] .button.is-secondary-dark.is-outlined {
        background-color: transparent;
        border-color: #01375d;
        box-shadow: none;
        color: #01375d; }
    .button.is-secondary-dark.is-inverted.is-outlined {
      background-color: transparent;
      border-color: #fff;
      color: #fff; }
      .button.is-secondary-dark.is-inverted.is-outlined:hover, .button.is-secondary-dark.is-inverted.is-outlined.is-hovered, .button.is-secondary-dark.is-inverted.is-outlined:focus, .button.is-secondary-dark.is-inverted.is-outlined.is-focused {
        background-color: #fff;
        color: #01375d; }
      .button.is-secondary-dark.is-inverted.is-outlined.is-loading:hover::after, .button.is-secondary-dark.is-inverted.is-outlined.is-loading.is-hovered::after, .button.is-secondary-dark.is-inverted.is-outlined.is-loading:focus::after, .button.is-secondary-dark.is-inverted.is-outlined.is-loading.is-focused::after {
        border-color: transparent transparent #01375d #01375d !important; }
      .button.is-secondary-dark.is-inverted.is-outlined[disabled],
      fieldset[disabled] .button.is-secondary-dark.is-inverted.is-outlined {
        background-color: transparent;
        border-color: #fff;
        box-shadow: none;
        color: #fff; }
    .button.is-secondary-dark.is-light {
      background-color: #ebf7ff;
      color: #179efd; }
      .button.is-secondary-dark.is-light:hover, .button.is-secondary-dark.is-light.is-hovered {
        background-color: #def1ff;
        border-color: transparent;
        color: #179efd; }
      .button.is-secondary-dark.is-light:active, .button.is-secondary-dark.is-light.is-active {
        background-color: #d2ecff;
        border-color: transparent;
        color: #179efd; }
  .button.is-small {
    font-size: 0.75rem; }
    .button.is-small:not(.is-rounded) {
      border-radius: 2px; }
  .button.is-normal {
    font-size: 1rem; }
  .button.is-medium {
    font-size: 1.333rem; }
  .button.is-large {
    font-size: 1.777rem; }
  .button[disabled],
  fieldset[disabled] .button {
    background-color: white;
    border-color: #f2f2f2;
    box-shadow: none;
    opacity: 0.5; }
  .button.is-fullwidth {
    display: flex;
    width: 100%; }
  .button.is-loading {
    color: transparent !important;
    pointer-events: none; }
    .button.is-loading::after {
      position: absolute;
      left: calc(50% - (1em * 0.5));
      top: calc(50% - (1em * 0.5));
      position: absolute !important; }
  .button.is-static {
    background-color: whitesmoke;
    border-color: #f2f2f2;
    color: #7a7a7a;
    box-shadow: none;
    pointer-events: none; }
  .button.is-rounded {
    border-radius: 9999px;
    padding-left: calc(1em + 0.25em);
    padding-right: calc(1em + 0.25em); }

.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .buttons .button {
    margin-bottom: 0.5rem; }
    .buttons .button:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.5rem; }
  .buttons:last-child {
    margin-bottom: -0.5rem; }
  .buttons:not(:last-child) {
    margin-bottom: 1rem; }
  .buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large) {
    font-size: 0.75rem; }
    .buttons.are-small .button:not(.is-normal):not(.is-medium):not(.is-large):not(.is-rounded) {
      border-radius: 2px; }
  .buttons.are-medium .button:not(.is-small):not(.is-normal):not(.is-large) {
    font-size: 1.333rem; }
  .buttons.are-large .button:not(.is-small):not(.is-normal):not(.is-medium) {
    font-size: 1.777rem; }
  .buttons.has-addons .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .buttons.has-addons .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px; }
  .buttons.has-addons .button:last-child {
    margin-right: 0; }
  .buttons.has-addons .button:hover, .buttons.has-addons .button.is-hovered {
    z-index: 2; }
  .buttons.has-addons .button:focus, .buttons.has-addons .button.is-focused, .buttons.has-addons .button:active, .buttons.has-addons .button.is-active, .buttons.has-addons .button.is-selected {
    z-index: 3; }
    .buttons.has-addons .button:focus:hover, .buttons.has-addons .button.is-focused:hover, .buttons.has-addons .button:active:hover, .buttons.has-addons .button.is-active:hover, .buttons.has-addons .button.is-selected:hover {
      z-index: 4; }
  .buttons.has-addons .button.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  .buttons.is-centered {
    justify-content: center; }
    .buttons.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
      margin-left: 0.25rem;
      margin-right: 0.25rem; }
  .buttons.is-right {
    justify-content: flex-end; }
    .buttons.is-right:not(.has-addons) .button:not(.is-fullwidth) {
      margin-left: 0.25rem;
      margin-right: 0.25rem; }

.table {
  background-color: white;
  color: #363636; }
  .table td,
  .table th {
    border: 1px solid #f2f2f2;
    border-width: 0 0 1px;
    padding: 0.5em 0.75em;
    vertical-align: top; }
    .table td.is-white,
    .table th.is-white {
      background-color: white;
      border-color: white;
      color: #0a0a0a; }
    .table td.is-black,
    .table th.is-black {
      background-color: #0a0a0a;
      border-color: #0a0a0a;
      color: white; }
    .table td.is-light,
    .table th.is-light {
      background-color: whitesmoke;
      border-color: whitesmoke;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-dark,
    .table th.is-dark {
      background-color: #363636;
      border-color: #363636;
      color: #fff; }
    .table td.is-primary,
    .table th.is-primary {
      background-color: #0070b8;
      border-color: #0070b8;
      color: #fff; }
    .table td.is-link,
    .table th.is-link {
      background-color: #485fc7;
      border-color: #485fc7;
      color: #fff; }
    .table td.is-info,
    .table th.is-info {
      background-color: #3e8ed0;
      border-color: #3e8ed0;
      color: #fff; }
    .table td.is-success,
    .table th.is-success {
      background-color: #63aa16;
      border-color: #63aa16;
      color: #fff; }
    .table td.is-warning,
    .table th.is-warning {
      background-color: #ffe08a;
      border-color: #ffe08a;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-danger,
    .table th.is-danger {
      background-color: #d0021b;
      border-color: #d0021b;
      color: #fff; }
    .table td.is-primary-light,
    .table th.is-primary-light {
      background-color: #cce2f1;
      border-color: #cce2f1;
      color: rgba(0, 0, 0, 0.7); }
    .table td.is-secondary,
    .table th.is-secondary {
      background-color: rgba(0, 112, 184, 0.4);
      border-color: rgba(0, 112, 184, 0.4);
      color: #fff; }
    .table td.is-secondary-dark,
    .table th.is-secondary-dark {
      background-color: #01375d;
      border-color: #01375d;
      color: #fff; }
    .table td.is-narrow,
    .table th.is-narrow {
      white-space: nowrap;
      width: 1%; }
    .table td.is-selected,
    .table th.is-selected {
      background-color: #0070b8;
      color: #fff; }
      .table td.is-selected a,
      .table td.is-selected strong,
      .table th.is-selected a,
      .table th.is-selected strong {
        color: currentColor; }
    .table td.is-vcentered,
    .table th.is-vcentered {
      vertical-align: middle; }
  .table th {
    color: #363636; }
    .table th:not([align]) {
      text-align: inherit; }
  .table tr.is-selected {
    background-color: #0070b8;
    color: #fff; }
    .table tr.is-selected a,
    .table tr.is-selected strong {
      color: currentColor; }
    .table tr.is-selected td,
    .table tr.is-selected th {
      border-color: #fff;
      color: currentColor; }
  .table thead {
    background-color: transparent; }
    .table thead td,
    .table thead th {
      border-width: 0 0 2px;
      color: #363636; }
  .table tfoot {
    background-color: transparent; }
    .table tfoot td,
    .table tfoot th {
      border-width: 2px 0 0;
      color: #363636; }
  .table tbody {
    background-color: transparent; }
    .table tbody tr:last-child td,
    .table tbody tr:last-child th {
      border-bottom-width: 0; }
  .table.is-bordered td,
  .table.is-bordered th {
    border-width: 1px; }
  .table.is-bordered tr:last-child td,
  .table.is-bordered tr:last-child th {
    border-bottom-width: 1px; }
  .table.is-fullwidth {
    width: 100%; }
  .table.is-hoverable tbody tr:not(.is-selected):hover {
    background-color: #fafafa; }
  .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
    background-color: #fafafa; }
    .table.is-hoverable.is-striped tbody tr:not(.is-selected):hover:nth-child(even) {
      background-color: whitesmoke; }
  .table.is-narrow td,
  .table.is-narrow th {
    padding: 0.25em 0.5em; }
  .table.is-striped tbody tr:not(.is-selected):nth-child(even) {
    background-color: #fafafa; }

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%; }

/* Bulma Form */
/* Bulma Form */
.input, .textarea, .select select {
  background-color: white;
  border-color: #000;
  border-radius: 24px;
  color: #363636; }
  .input::-moz-placeholder, .textarea::-moz-placeholder, .select select::-moz-placeholder {
    color: #6a6a6a; }
  .input::-webkit-input-placeholder, .textarea::-webkit-input-placeholder, .select select::-webkit-input-placeholder {
    color: #6a6a6a; }
  .input:-moz-placeholder, .textarea:-moz-placeholder, .select select:-moz-placeholder {
    color: #6a6a6a; }
  .input:-ms-input-placeholder, .textarea:-ms-input-placeholder, .select select:-ms-input-placeholder {
    color: #6a6a6a; }
  .input:hover, .textarea:hover, .select select:hover, .is-hovered.input, .is-hovered.textarea, .select select.is-hovered {
    border-color: #b5b5b5; }
  .input:focus, .textarea:focus, .select select:focus, .is-focused.input, .is-focused.textarea, .select select.is-focused, .input:active, .textarea:active, .select select:active, .is-active.input, .is-active.textarea, .select select.is-active {
    border-color: #0070b8;
    box-shadow: 0 0 0 0.125em bulmaRgba(#0070b8, 1); }
  .input[disabled], .textarea[disabled], .select select[disabled],
  fieldset[disabled] .input,
  fieldset[disabled] .textarea,
  fieldset[disabled] .select select,
  .select fieldset[disabled] select {
    background-color: whitesmoke;
    border-color: whitesmoke;
    box-shadow: none;
    color: #7a7a7a; }
    .input[disabled]::-moz-placeholder, .textarea[disabled]::-moz-placeholder, .select select[disabled]::-moz-placeholder,
    fieldset[disabled] .input::-moz-placeholder,
    fieldset[disabled] .textarea::-moz-placeholder,
    fieldset[disabled] .select select::-moz-placeholder,
    .select fieldset[disabled] select::-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]::-webkit-input-placeholder, .textarea[disabled]::-webkit-input-placeholder, .select select[disabled]::-webkit-input-placeholder,
    fieldset[disabled] .input::-webkit-input-placeholder,
    fieldset[disabled] .textarea::-webkit-input-placeholder,
    fieldset[disabled] .select select::-webkit-input-placeholder,
    .select fieldset[disabled] select::-webkit-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-moz-placeholder, .textarea[disabled]:-moz-placeholder, .select select[disabled]:-moz-placeholder,
    fieldset[disabled] .input:-moz-placeholder,
    fieldset[disabled] .textarea:-moz-placeholder,
    fieldset[disabled] .select select:-moz-placeholder,
    .select fieldset[disabled] select:-moz-placeholder {
      color: rgba(122, 122, 122, 0.3); }
    .input[disabled]:-ms-input-placeholder, .textarea[disabled]:-ms-input-placeholder, .select select[disabled]:-ms-input-placeholder,
    fieldset[disabled] .input:-ms-input-placeholder,
    fieldset[disabled] .textarea:-ms-input-placeholder,
    fieldset[disabled] .select select:-ms-input-placeholder,
    .select fieldset[disabled] select:-ms-input-placeholder {
      color: rgba(122, 122, 122, 0.3); }

.input, .textarea {
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  max-width: 100%;
  width: 100%; }
  .input[readonly], .textarea[readonly] {
    box-shadow: none; }
  .is-white.input, .is-white.textarea {
    border-color: white; }
    .is-white.input:focus, .is-white.textarea:focus, .is-white.is-focused.input, .is-white.is-focused.textarea, .is-white.input:active, .is-white.textarea:active, .is-white.is-active.input, .is-white.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .is-black.input, .is-black.textarea {
    border-color: #0a0a0a; }
    .is-black.input:focus, .is-black.textarea:focus, .is-black.is-focused.input, .is-black.is-focused.textarea, .is-black.input:active, .is-black.textarea:active, .is-black.is-active.input, .is-black.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .is-light.input, .is-light.textarea {
    border-color: whitesmoke; }
    .is-light.input:focus, .is-light.textarea:focus, .is-light.is-focused.input, .is-light.is-focused.textarea, .is-light.input:active, .is-light.textarea:active, .is-light.is-active.input, .is-light.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .is-dark.input, .is-dark.textarea {
    border-color: #363636; }
    .is-dark.input:focus, .is-dark.textarea:focus, .is-dark.is-focused.input, .is-dark.is-focused.textarea, .is-dark.input:active, .is-dark.textarea:active, .is-dark.is-active.input, .is-dark.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
  .is-primary.input, .is-primary.textarea {
    border-color: #0070b8; }
    .is-primary.input:focus, .is-primary.textarea:focus, .is-primary.is-focused.input, .is-primary.is-focused.textarea, .is-primary.input:active, .is-primary.textarea:active, .is-primary.is-active.input, .is-primary.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
  .is-link.input, .is-link.textarea {
    border-color: #485fc7; }
    .is-link.input:focus, .is-link.textarea:focus, .is-link.is-focused.input, .is-link.is-focused.textarea, .is-link.input:active, .is-link.textarea:active, .is-link.is-active.input, .is-link.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .is-info.input, .is-info.textarea {
    border-color: #3e8ed0; }
    .is-info.input:focus, .is-info.textarea:focus, .is-info.is-focused.input, .is-info.is-focused.textarea, .is-info.input:active, .is-info.textarea:active, .is-info.is-active.input, .is-info.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
  .is-success.input, .is-success.textarea {
    border-color: #63aa16; }
    .is-success.input:focus, .is-success.textarea:focus, .is-success.is-focused.input, .is-success.is-focused.textarea, .is-success.input:active, .is-success.textarea:active, .is-success.is-active.input, .is-success.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(99, 170, 22, 0.25); }
  .is-warning.input, .is-warning.textarea {
    border-color: #ffe08a; }
    .is-warning.input:focus, .is-warning.textarea:focus, .is-warning.is-focused.input, .is-warning.is-focused.textarea, .is-warning.input:active, .is-warning.textarea:active, .is-warning.is-active.input, .is-warning.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
  .is-danger.input, .is-danger.textarea {
    border-color: #d0021b; }
    .is-danger.input:focus, .is-danger.textarea:focus, .is-danger.is-focused.input, .is-danger.is-focused.textarea, .is-danger.input:active, .is-danger.textarea:active, .is-danger.is-active.input, .is-danger.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(208, 2, 27, 0.25); }
  .is-primary-light.input, .is-primary-light.textarea {
    border-color: #cce2f1; }
    .is-primary-light.input:focus, .is-primary-light.textarea:focus, .is-primary-light.is-focused.input, .is-primary-light.is-focused.textarea, .is-primary-light.input:active, .is-primary-light.textarea:active, .is-primary-light.is-active.input, .is-primary-light.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(204, 226, 241, 0.25); }
  .is-secondary.input, .is-secondary.textarea {
    border-color: rgba(0, 112, 184, 0.4); }
    .is-secondary.input:focus, .is-secondary.textarea:focus, .is-secondary.is-focused.input, .is-secondary.is-focused.textarea, .is-secondary.input:active, .is-secondary.textarea:active, .is-secondary.is-active.input, .is-secondary.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
  .is-secondary-dark.input, .is-secondary-dark.textarea {
    border-color: #01375d; }
    .is-secondary-dark.input:focus, .is-secondary-dark.textarea:focus, .is-secondary-dark.is-focused.input, .is-secondary-dark.is-focused.textarea, .is-secondary-dark.input:active, .is-secondary-dark.textarea:active, .is-secondary-dark.is-active.input, .is-secondary-dark.is-active.textarea {
      box-shadow: 0 0 0 0.125em rgba(1, 55, 93, 0.25); }
  .is-small.input, .is-small.textarea {
    border-radius: 2px;
    font-size: 0.75rem; }
  .is-medium.input, .is-medium.textarea {
    font-size: 1.333rem; }
  .is-large.input, .is-large.textarea {
    font-size: 1.777rem; }
  .is-fullwidth.input, .is-fullwidth.textarea {
    display: block;
    width: 100%; }
  .is-inline.input, .is-inline.textarea {
    display: inline;
    width: auto; }

.input.is-rounded {
  border-radius: 9999px;
  padding-left: calc(calc(0.75em - 1px) + 0.375em);
  padding-right: calc(calc(0.75em - 1px) + 0.375em); }

.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0; }

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  padding: calc(0.75em - 1px);
  resize: vertical; }
  .textarea:not([rows]) {
    max-height: 40em;
    min-height: 8em; }
  .textarea[rows] {
    height: initial; }
  .textarea.has-fixed-size {
    resize: none; }

.checkbox, .radio {
  cursor: pointer;
  display: inline-block;
  line-height: 1.25;
  position: relative; }
  .checkbox input, .radio input {
    cursor: pointer; }
  .checkbox:hover, .radio:hover {
    color: #363636; }
  .checkbox[disabled], .radio[disabled],
  fieldset[disabled] .checkbox,
  fieldset[disabled] .radio,
  .checkbox input[disabled],
  .radio input[disabled] {
    color: #7a7a7a;
    cursor: not-allowed; }

.radio + .radio {
  margin-left: 0.5em; }

.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top; }
  .select:not(.is-multiple) {
    height: 2.5em; }
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #0070b8;
    right: 1.125em;
    z-index: 4; }
  .select.is-rounded select {
    border-radius: 9999px;
    padding-left: 1em; }
  .select select {
    cursor: pointer;
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: none; }
    .select select::-ms-expand {
      display: none; }
    .select select[disabled]:hover,
    fieldset[disabled] .select select:hover {
      border-color: whitesmoke; }
    .select select:not([multiple]) {
      padding-right: 2.5em; }
    .select select[multiple] {
      height: auto;
      padding: 0; }
      .select select[multiple] option {
        padding: 0.5em 1em; }
  .select:not(.is-multiple):not(.is-loading):hover::after {
    border-color: #363636; }
  .select.is-white:not(:hover)::after {
    border-color: white; }
  .select.is-white select {
    border-color: white; }
    .select.is-white select:hover, .select.is-white select.is-hovered {
      border-color: #f2f2f2; }
    .select.is-white select:focus, .select.is-white select.is-focused, .select.is-white select:active, .select.is-white select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 0.25); }
  .select.is-black:not(:hover)::after {
    border-color: #0a0a0a; }
  .select.is-black select {
    border-color: #0a0a0a; }
    .select.is-black select:hover, .select.is-black select.is-hovered {
      border-color: black; }
    .select.is-black select:focus, .select.is-black select.is-focused, .select.is-black select:active, .select.is-black select.is-active {
      box-shadow: 0 0 0 0.125em rgba(10, 10, 10, 0.25); }
  .select.is-light:not(:hover)::after {
    border-color: whitesmoke; }
  .select.is-light select {
    border-color: whitesmoke; }
    .select.is-light select:hover, .select.is-light select.is-hovered {
      border-color: #e8e8e8; }
    .select.is-light select:focus, .select.is-light select.is-focused, .select.is-light select:active, .select.is-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(245, 245, 245, 0.25); }
  .select.is-dark:not(:hover)::after {
    border-color: #363636; }
  .select.is-dark select {
    border-color: #363636; }
    .select.is-dark select:hover, .select.is-dark select.is-hovered {
      border-color: #292929; }
    .select.is-dark select:focus, .select.is-dark select.is-focused, .select.is-dark select:active, .select.is-dark select.is-active {
      box-shadow: 0 0 0 0.125em rgba(54, 54, 54, 0.25); }
  .select.is-primary:not(:hover)::after {
    border-color: #0070b8; }
  .select.is-primary select {
    border-color: #0070b8; }
    .select.is-primary select:hover, .select.is-primary select.is-hovered {
      border-color: #00609f; }
    .select.is-primary select:focus, .select.is-primary select.is-focused, .select.is-primary select:active, .select.is-primary select.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
  .select.is-link:not(:hover)::after {
    border-color: #485fc7; }
  .select.is-link select {
    border-color: #485fc7; }
    .select.is-link select:hover, .select.is-link select.is-hovered {
      border-color: #3a51bb; }
    .select.is-link select:focus, .select.is-link select.is-focused, .select.is-link select:active, .select.is-link select.is-active {
      box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25); }
  .select.is-info:not(:hover)::after {
    border-color: #3e8ed0; }
  .select.is-info select {
    border-color: #3e8ed0; }
    .select.is-info select:hover, .select.is-info select.is-hovered {
      border-color: #3082c5; }
    .select.is-info select:focus, .select.is-info select.is-focused, .select.is-info select:active, .select.is-info select.is-active {
      box-shadow: 0 0 0 0.125em rgba(62, 142, 208, 0.25); }
  .select.is-success:not(:hover)::after {
    border-color: #63aa16; }
  .select.is-success select {
    border-color: #63aa16; }
    .select.is-success select:hover, .select.is-success select.is-hovered {
      border-color: #569313; }
    .select.is-success select:focus, .select.is-success select.is-focused, .select.is-success select:active, .select.is-success select.is-active {
      box-shadow: 0 0 0 0.125em rgba(99, 170, 22, 0.25); }
  .select.is-warning:not(:hover)::after {
    border-color: #ffe08a; }
  .select.is-warning select {
    border-color: #ffe08a; }
    .select.is-warning select:hover, .select.is-warning select.is-hovered {
      border-color: #ffd970; }
    .select.is-warning select:focus, .select.is-warning select.is-focused, .select.is-warning select:active, .select.is-warning select.is-active {
      box-shadow: 0 0 0 0.125em rgba(255, 224, 138, 0.25); }
  .select.is-danger:not(:hover)::after {
    border-color: #d0021b; }
  .select.is-danger select {
    border-color: #d0021b; }
    .select.is-danger select:hover, .select.is-danger select.is-hovered {
      border-color: #b70218; }
    .select.is-danger select:focus, .select.is-danger select.is-focused, .select.is-danger select:active, .select.is-danger select.is-active {
      box-shadow: 0 0 0 0.125em rgba(208, 2, 27, 0.25); }
  .select.is-primary-light:not(:hover)::after {
    border-color: #cce2f1; }
  .select.is-primary-light select {
    border-color: #cce2f1; }
    .select.is-primary-light select:hover, .select.is-primary-light select.is-hovered {
      border-color: #b8d7ec; }
    .select.is-primary-light select:focus, .select.is-primary-light select.is-focused, .select.is-primary-light select:active, .select.is-primary-light select.is-active {
      box-shadow: 0 0 0 0.125em rgba(204, 226, 241, 0.25); }
  .select.is-secondary:not(:hover)::after {
    border-color: rgba(0, 112, 184, 0.4); }
  .select.is-secondary select {
    border-color: rgba(0, 112, 184, 0.4); }
    .select.is-secondary select:hover, .select.is-secondary select.is-hovered {
      border-color: rgba(0, 96, 159, 0.4); }
    .select.is-secondary select:focus, .select.is-secondary select.is-focused, .select.is-secondary select:active, .select.is-secondary select.is-active {
      box-shadow: 0 0 0 0.125em rgba(0, 112, 184, 0.25); }
  .select.is-secondary-dark:not(:hover)::after {
    border-color: #01375d; }
  .select.is-secondary-dark select {
    border-color: #01375d; }
    .select.is-secondary-dark select:hover, .select.is-secondary-dark select.is-hovered {
      border-color: #012844; }
    .select.is-secondary-dark select:focus, .select.is-secondary-dark select.is-focused, .select.is-secondary-dark select:active, .select.is-secondary-dark select.is-active {
      box-shadow: 0 0 0 0.125em rgba(1, 55, 93, 0.25); }
  .select.is-small {
    border-radius: 2px;
    font-size: 0.75rem; }
  .select.is-medium {
    font-size: 1.333rem; }
  .select.is-large {
    font-size: 1.777rem; }
  .select.is-disabled::after {
    border-color: #7a7a7a; }
  .select.is-fullwidth {
    width: 100%; }
    .select.is-fullwidth select {
      width: 100%; }
  .select.is-loading::after {
    margin-top: 0;
    position: absolute;
    right: 0.625em;
    top: 0.625em;
    transform: none; }
  .select.is-loading.is-small:after {
    font-size: 0.75rem; }
  .select.is-loading.is-medium:after {
    font-size: 1.333rem; }
  .select.is-loading.is-large:after {
    font-size: 1.777rem; }

.file {
  align-items: stretch;
  display: flex;
  justify-content: flex-start;
  position: relative; }
  .file.is-white .file-cta {
    background-color: white;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:hover .file-cta, .file.is-white.is-hovered .file-cta {
    background-color: #f9f9f9;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-white:focus .file-cta, .file.is-white.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 255, 255, 0.25);
    color: #0a0a0a; }
  .file.is-white:active .file-cta, .file.is-white.is-active .file-cta {
    background-color: #f2f2f2;
    border-color: transparent;
    color: #0a0a0a; }
  .file.is-black .file-cta {
    background-color: #0a0a0a;
    border-color: transparent;
    color: white; }
  .file.is-black:hover .file-cta, .file.is-black.is-hovered .file-cta {
    background-color: #040404;
    border-color: transparent;
    color: white; }
  .file.is-black:focus .file-cta, .file.is-black.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(10, 10, 10, 0.25);
    color: white; }
  .file.is-black:active .file-cta, .file.is-black.is-active .file-cta {
    background-color: black;
    border-color: transparent;
    color: white; }
  .file.is-light .file-cta {
    background-color: whitesmoke;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:hover .file-cta, .file.is-light.is-hovered .file-cta {
    background-color: #eeeeee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:focus .file-cta, .file.is-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(245, 245, 245, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-light:active .file-cta, .file.is-light.is-active .file-cta {
    background-color: #e8e8e8;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-dark .file-cta {
    background-color: #363636;
    border-color: transparent;
    color: #fff; }
  .file.is-dark:hover .file-cta, .file.is-dark.is-hovered .file-cta {
    background-color: #2f2f2f;
    border-color: transparent;
    color: #fff; }
  .file.is-dark:focus .file-cta, .file.is-dark.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(54, 54, 54, 0.25);
    color: #fff; }
  .file.is-dark:active .file-cta, .file.is-dark.is-active .file-cta {
    background-color: #292929;
    border-color: transparent;
    color: #fff; }
  .file.is-primary .file-cta {
    background-color: #0070b8;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:hover .file-cta, .file.is-primary.is-hovered .file-cta {
    background-color: #0068ab;
    border-color: transparent;
    color: #fff; }
  .file.is-primary:focus .file-cta, .file.is-primary.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(0, 112, 184, 0.25);
    color: #fff; }
  .file.is-primary:active .file-cta, .file.is-primary.is-active .file-cta {
    background-color: #00609f;
    border-color: transparent;
    color: #fff; }
  .file.is-link .file-cta {
    background-color: #485fc7;
    border-color: transparent;
    color: #fff; }
  .file.is-link:hover .file-cta, .file.is-link.is-hovered .file-cta {
    background-color: #3e56c4;
    border-color: transparent;
    color: #fff; }
  .file.is-link:focus .file-cta, .file.is-link.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(72, 95, 199, 0.25);
    color: #fff; }
  .file.is-link:active .file-cta, .file.is-link.is-active .file-cta {
    background-color: #3a51bb;
    border-color: transparent;
    color: #fff; }
  .file.is-info .file-cta {
    background-color: #3e8ed0;
    border-color: transparent;
    color: #fff; }
  .file.is-info:hover .file-cta, .file.is-info.is-hovered .file-cta {
    background-color: #3488ce;
    border-color: transparent;
    color: #fff; }
  .file.is-info:focus .file-cta, .file.is-info.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(62, 142, 208, 0.25);
    color: #fff; }
  .file.is-info:active .file-cta, .file.is-info.is-active .file-cta {
    background-color: #3082c5;
    border-color: transparent;
    color: #fff; }
  .file.is-success .file-cta {
    background-color: #63aa16;
    border-color: transparent;
    color: #fff; }
  .file.is-success:hover .file-cta, .file.is-success.is-hovered .file-cta {
    background-color: #5c9f15;
    border-color: transparent;
    color: #fff; }
  .file.is-success:focus .file-cta, .file.is-success.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(99, 170, 22, 0.25);
    color: #fff; }
  .file.is-success:active .file-cta, .file.is-success.is-active .file-cta {
    background-color: #569313;
    border-color: transparent;
    color: #fff; }
  .file.is-warning .file-cta {
    background-color: #ffe08a;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:hover .file-cta, .file.is-warning.is-hovered .file-cta {
    background-color: #ffdc7d;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:focus .file-cta, .file.is-warning.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(255, 224, 138, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-warning:active .file-cta, .file.is-warning.is-active .file-cta {
    background-color: #ffd970;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-danger .file-cta {
    background-color: #d0021b;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:hover .file-cta, .file.is-danger.is-hovered .file-cta {
    background-color: #c30219;
    border-color: transparent;
    color: #fff; }
  .file.is-danger:focus .file-cta, .file.is-danger.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(208, 2, 27, 0.25);
    color: #fff; }
  .file.is-danger:active .file-cta, .file.is-danger.is-active .file-cta {
    background-color: #b70218;
    border-color: transparent;
    color: #fff; }
  .file.is-primary-light .file-cta {
    background-color: #cce2f1;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-primary-light:hover .file-cta, .file.is-primary-light.is-hovered .file-cta {
    background-color: #c2dcee;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-primary-light:focus .file-cta, .file.is-primary-light.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(204, 226, 241, 0.25);
    color: rgba(0, 0, 0, 0.7); }
  .file.is-primary-light:active .file-cta, .file.is-primary-light.is-active .file-cta {
    background-color: #b8d7ec;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.7); }
  .file.is-secondary .file-cta {
    background-color: rgba(0, 112, 184, 0.4);
    border-color: transparent;
    color: #fff; }
  .file.is-secondary:hover .file-cta, .file.is-secondary.is-hovered .file-cta {
    background-color: rgba(0, 104, 171, 0.4);
    border-color: transparent;
    color: #fff; }
  .file.is-secondary:focus .file-cta, .file.is-secondary.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(0, 112, 184, 0.25);
    color: #fff; }
  .file.is-secondary:active .file-cta, .file.is-secondary.is-active .file-cta {
    background-color: rgba(0, 96, 159, 0.4);
    border-color: transparent;
    color: #fff; }
  .file.is-secondary-dark .file-cta {
    background-color: #01375d;
    border-color: transparent;
    color: #fff; }
  .file.is-secondary-dark:hover .file-cta, .file.is-secondary-dark.is-hovered .file-cta {
    background-color: #013050;
    border-color: transparent;
    color: #fff; }
  .file.is-secondary-dark:focus .file-cta, .file.is-secondary-dark.is-focused .file-cta {
    border-color: transparent;
    box-shadow: 0 0 0.5em rgba(1, 55, 93, 0.25);
    color: #fff; }
  .file.is-secondary-dark:active .file-cta, .file.is-secondary-dark.is-active .file-cta {
    background-color: #012844;
    border-color: transparent;
    color: #fff; }
  .file.is-small {
    font-size: 0.75rem; }
  .file.is-normal {
    font-size: 1rem; }
  .file.is-medium {
    font-size: 1.333rem; }
    .file.is-medium .file-icon .fa {
      font-size: 21px; }
  .file.is-large {
    font-size: 1.777rem; }
    .file.is-large .file-icon .fa {
      font-size: 28px; }
  .file.has-name .file-cta {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .file.has-name .file-name {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .file.has-name.is-empty .file-cta {
    border-radius: 4px; }
  .file.has-name.is-empty .file-name {
    display: none; }
  .file.is-boxed .file-label {
    flex-direction: column; }
  .file.is-boxed .file-cta {
    flex-direction: column;
    height: auto;
    padding: 1em 3em; }
  .file.is-boxed .file-name {
    border-width: 0 1px 1px; }
  .file.is-boxed .file-icon {
    height: 1.5em;
    width: 1.5em; }
    .file.is-boxed .file-icon .fa {
      font-size: 21px; }
  .file.is-boxed.is-small .file-icon .fa {
    font-size: 14px; }
  .file.is-boxed.is-medium .file-icon .fa {
    font-size: 28px; }
  .file.is-boxed.is-large .file-icon .fa {
    font-size: 35px; }
  .file.is-boxed.has-name .file-cta {
    border-radius: 4px 4px 0 0; }
  .file.is-boxed.has-name .file-name {
    border-radius: 0 0 4px 4px;
    border-width: 0 1px 1px; }
  .file.is-centered {
    justify-content: center; }
  .file.is-fullwidth .file-label {
    width: 100%; }
  .file.is-fullwidth .file-name {
    flex-grow: 1;
    max-width: none; }
  .file.is-right {
    justify-content: flex-end; }
    .file.is-right .file-cta {
      border-radius: 0 4px 4px 0; }
    .file.is-right .file-name {
      border-radius: 4px 0 0 4px;
      border-width: 1px 0 1px 1px;
      order: -1; }

.file-label {
  align-items: stretch;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  overflow: hidden;
  position: relative; }
  .file-label:hover .file-cta {
    background-color: #eeeeee;
    color: #363636; }
  .file-label:hover .file-name {
    border-color: #ececec; }
  .file-label:active .file-cta {
    background-color: #e8e8e8;
    color: #363636; }
  .file-label:active .file-name {
    border-color: #e5e5e5; }

.file-input {
  height: 100%;
  left: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%; }

.file-cta,
.file-name {
  border-color: #f2f2f2;
  border-radius: 4px;
  font-size: 1em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap; }

.file-cta {
  background-color: whitesmoke;
  color: #4a4a4a; }

.file-name {
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  display: block;
  max-width: 16em;
  overflow: hidden;
  text-align: inherit;
  text-overflow: ellipsis; }

.file-icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  margin-right: 0.5em;
  width: 1em; }
  .file-icon .fa {
    font-size: 14px; }

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700; }
  .label:not(:last-child) {
    margin-bottom: 0.5em; }
  .label.is-small {
    font-size: 0.75rem; }
  .label.is-medium {
    font-size: 1.333rem; }
  .label.is-large {
    font-size: 1.777rem; }

.help {
  display: block;
  font-size: 0.875rem;
  margin-top: 0.25rem; }
  .help.is-white {
    color: white; }
  .help.is-black {
    color: #0a0a0a; }
  .help.is-light {
    color: whitesmoke; }
  .help.is-dark {
    color: #363636; }
  .help.is-primary {
    color: #0070b8; }
  .help.is-link {
    color: #485fc7; }
  .help.is-info {
    color: #3e8ed0; }
  .help.is-success {
    color: #63aa16; }
  .help.is-warning {
    color: #ffe08a; }
  .help.is-danger {
    color: #d0021b; }
  .help.is-primary-light {
    color: #cce2f1; }
  .help.is-secondary {
    color: rgba(0, 112, 184, 0.4); }
  .help.is-secondary-dark {
    color: #01375d; }

.field:not(:last-child) {
  margin-bottom: 0.75rem; }

.field.has-addons {
  display: flex;
  justify-content: flex-start; }
  .field.has-addons .control:not(:last-child) {
    margin-right: -1px; }
  .field.has-addons .control:not(:first-child):not(:last-child) .button,
  .field.has-addons .control:not(:first-child):not(:last-child) .input,
  .field.has-addons .control:not(:first-child):not(:last-child) .select select {
    border-radius: 0; }
  .field.has-addons .control:first-child:not(:only-child) .button,
  .field.has-addons .control:first-child:not(:only-child) .input,
  .field.has-addons .control:first-child:not(:only-child) .select select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .field.has-addons .control:last-child:not(:only-child) .button,
  .field.has-addons .control:last-child:not(:only-child) .input,
  .field.has-addons .control:last-child:not(:only-child) .select select {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .field.has-addons .control .button:not([disabled]):hover, .field.has-addons .control .button:not([disabled]).is-hovered,
  .field.has-addons .control .input:not([disabled]):hover,
  .field.has-addons .control .input:not([disabled]).is-hovered,
  .field.has-addons .control .select select:not([disabled]):hover,
  .field.has-addons .control .select select:not([disabled]).is-hovered {
    z-index: 2; }
  .field.has-addons .control .button:not([disabled]):focus, .field.has-addons .control .button:not([disabled]).is-focused, .field.has-addons .control .button:not([disabled]):active, .field.has-addons .control .button:not([disabled]).is-active,
  .field.has-addons .control .input:not([disabled]):focus,
  .field.has-addons .control .input:not([disabled]).is-focused,
  .field.has-addons .control .input:not([disabled]):active,
  .field.has-addons .control .input:not([disabled]).is-active,
  .field.has-addons .control .select select:not([disabled]):focus,
  .field.has-addons .control .select select:not([disabled]).is-focused,
  .field.has-addons .control .select select:not([disabled]):active,
  .field.has-addons .control .select select:not([disabled]).is-active {
    z-index: 3; }
    .field.has-addons .control .button:not([disabled]):focus:hover, .field.has-addons .control .button:not([disabled]).is-focused:hover, .field.has-addons .control .button:not([disabled]):active:hover, .field.has-addons .control .button:not([disabled]).is-active:hover,
    .field.has-addons .control .input:not([disabled]):focus:hover,
    .field.has-addons .control .input:not([disabled]).is-focused:hover,
    .field.has-addons .control .input:not([disabled]):active:hover,
    .field.has-addons .control .input:not([disabled]).is-active:hover,
    .field.has-addons .control .select select:not([disabled]):focus:hover,
    .field.has-addons .control .select select:not([disabled]).is-focused:hover,
    .field.has-addons .control .select select:not([disabled]):active:hover,
    .field.has-addons .control .select select:not([disabled]).is-active:hover {
      z-index: 4; }
  .field.has-addons .control.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  .field.has-addons.has-addons-centered {
    justify-content: center; }
  .field.has-addons.has-addons-right {
    justify-content: flex-end; }
  .field.has-addons.has-addons-fullwidth .control {
    flex-grow: 1;
    flex-shrink: 0; }

.field.is-grouped {
  display: flex;
  justify-content: flex-start; }
  .field.is-grouped > .control {
    flex-shrink: 0; }
    .field.is-grouped > .control:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.75rem; }
    .field.is-grouped > .control.is-expanded {
      flex-grow: 1;
      flex-shrink: 1; }
  .field.is-grouped.is-grouped-centered {
    justify-content: center; }
  .field.is-grouped.is-grouped-right {
    justify-content: flex-end; }
  .field.is-grouped.is-grouped-multiline {
    flex-wrap: wrap; }
    .field.is-grouped.is-grouped-multiline > .control:last-child, .field.is-grouped.is-grouped-multiline > .control:not(:last-child) {
      margin-bottom: 0.75rem; }
    .field.is-grouped.is-grouped-multiline:last-child {
      margin-bottom: -0.75rem; }
    .field.is-grouped.is-grouped-multiline:not(:last-child) {
      margin-bottom: 0; }

@media screen and (min-width: 769px), print {
  .field.is-horizontal {
    display: flex; } }

.field-label .label {
  font-size: inherit; }

@media screen and (max-width: 768px) {
  .field-label {
    margin-bottom: 0.5rem; } }

@media screen and (min-width: 769px), print {
  .field-label {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    margin-right: 1.5rem;
    text-align: right; }
    .field-label.is-small {
      font-size: 0.75rem;
      padding-top: 0.375em; }
    .field-label.is-normal {
      padding-top: 0.375em; }
    .field-label.is-medium {
      font-size: 1.333rem;
      padding-top: 0.375em; }
    .field-label.is-large {
      font-size: 1.777rem;
      padding-top: 0.375em; } }

.field-body .field .field {
  margin-bottom: 0; }

@media screen and (min-width: 769px), print {
  .field-body {
    display: flex;
    flex-basis: 0;
    flex-grow: 5;
    flex-shrink: 1; }
    .field-body .field {
      margin-bottom: 0; }
    .field-body > .field {
      flex-shrink: 1; }
      .field-body > .field:not(.is-narrow) {
        flex-grow: 1; }
      .field-body > .field:not(:last-child) {
        margin-right: 0.75rem; } }

.control {
  box-sizing: border-box;
  clear: both;
  font-size: 1rem;
  position: relative;
  text-align: inherit; }
  .control.has-icons-left .input:focus ~ .icon,
  .control.has-icons-left .select:focus ~ .icon, .control.has-icons-right .input:focus ~ .icon,
  .control.has-icons-right .select:focus ~ .icon {
    color: #4a4a4a; }
  .control.has-icons-left .input.is-small ~ .icon,
  .control.has-icons-left .select.is-small ~ .icon, .control.has-icons-right .input.is-small ~ .icon,
  .control.has-icons-right .select.is-small ~ .icon {
    font-size: 0.75rem; }
  .control.has-icons-left .input.is-medium ~ .icon,
  .control.has-icons-left .select.is-medium ~ .icon, .control.has-icons-right .input.is-medium ~ .icon,
  .control.has-icons-right .select.is-medium ~ .icon {
    font-size: 1.333rem; }
  .control.has-icons-left .input.is-large ~ .icon,
  .control.has-icons-left .select.is-large ~ .icon, .control.has-icons-right .input.is-large ~ .icon,
  .control.has-icons-right .select.is-large ~ .icon {
    font-size: 1.777rem; }
  .control.has-icons-left .icon, .control.has-icons-right .icon {
    color: #f2f2f2;
    height: 2.5em;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 2.5em;
    z-index: 4; }
  .control.has-icons-left .input,
  .control.has-icons-left .select select {
    padding-left: 2.5em; }
  .control.has-icons-left .icon.is-left {
    left: 0; }
  .control.has-icons-right .input,
  .control.has-icons-right .select select {
    padding-right: 2.5em; }
  .control.has-icons-right .icon.is-right {
    right: 0; }
  .control.is-loading::after {
    position: absolute !important;
    right: 0.625em;
    top: 0.625em;
    z-index: 4; }
  .control.is-loading.is-small:after {
    font-size: 0.75rem; }
  .control.is-loading.is-medium:after {
    font-size: 1.333rem; }
  .control.is-loading.is-large:after {
    font-size: 1.777rem; }

/* Bulma Components */
.level {
  align-items: center;
  justify-content: space-between; }
  .level code {
    border-radius: 4px; }
  .level img {
    display: inline-block;
    vertical-align: top; }
  .level.is-mobile {
    display: flex; }
    .level.is-mobile .level-left,
    .level.is-mobile .level-right {
      display: flex; }
    .level.is-mobile .level-left + .level-right {
      margin-top: 0; }
    .level.is-mobile .level-item:not(:last-child) {
      margin-bottom: 0;
      margin-right: 0.6665rem; }
    .level.is-mobile .level-item:not(.is-narrow) {
      flex-grow: 1; }
  @media screen and (min-width: 769px), print {
    .level {
      display: flex; }
      .level > .level-item:not(.is-narrow) {
        flex-grow: 1; } }

.level-item {
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center; }
  .level-item .title,
  .level-item .subtitle {
    margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    .level-item:not(:last-child) {
      margin-bottom: 0.6665rem; } }

.level-left,
.level-right {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0; }
  .level-left .level-item.is-flexible,
  .level-right .level-item.is-flexible {
    flex-grow: 1; }
  @media screen and (min-width: 769px), print {
    .level-left .level-item:not(:last-child),
    .level-right .level-item:not(:last-child) {
      margin-right: 0.6665rem; } }

.level-left {
  align-items: center;
  justify-content: flex-start; }
  @media screen and (max-width: 768px) {
    .level-left + .level-right {
      margin-top: 1.5rem; } }
  @media screen and (min-width: 769px), print {
    .level-left {
      display: flex; } }

.level-right {
  align-items: center;
  justify-content: flex-end; }
  @media screen and (min-width: 769px), print {
    .level-right {
      display: flex; } }

/* Bulma Grid */
.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem; }
  .columns.is-mobile > .column.is-narrow {
    flex: none;
    width: unset; }
  .columns.is-mobile > .column.is-full {
    flex: none;
    width: 100%; }
  .columns.is-mobile > .column.is-three-quarters {
    flex: none;
    width: 75%; }
  .columns.is-mobile > .column.is-two-thirds {
    flex: none;
    width: 66.6666%; }
  .columns.is-mobile > .column.is-half {
    flex: none;
    width: 50%; }
  .columns.is-mobile > .column.is-one-third {
    flex: none;
    width: 33.3333%; }
  .columns.is-mobile > .column.is-one-quarter {
    flex: none;
    width: 25%; }
  .columns.is-mobile > .column.is-one-fifth {
    flex: none;
    width: 20%; }
  .columns.is-mobile > .column.is-two-fifths {
    flex: none;
    width: 40%; }
  .columns.is-mobile > .column.is-three-fifths {
    flex: none;
    width: 60%; }
  .columns.is-mobile > .column.is-four-fifths {
    flex: none;
    width: 80%; }
  .columns.is-mobile > .column.is-offset-three-quarters {
    margin-left: 75%; }
  .columns.is-mobile > .column.is-offset-two-thirds {
    margin-left: 66.6666%; }
  .columns.is-mobile > .column.is-offset-half {
    margin-left: 50%; }
  .columns.is-mobile > .column.is-offset-one-third {
    margin-left: 33.3333%; }
  .columns.is-mobile > .column.is-offset-one-quarter {
    margin-left: 25%; }
  .columns.is-mobile > .column.is-offset-one-fifth {
    margin-left: 20%; }
  .columns.is-mobile > .column.is-offset-two-fifths {
    margin-left: 40%; }
  .columns.is-mobile > .column.is-offset-three-fifths {
    margin-left: 60%; }
  .columns.is-mobile > .column.is-offset-four-fifths {
    margin-left: 80%; }
  .columns.is-mobile > .column.is-0 {
    flex: none;
    width: 0%; }
  .columns.is-mobile > .column.is-offset-0 {
    margin-left: 0%; }
  .columns.is-mobile > .column.is-1 {
    flex: none;
    width: 8.33333%; }
  .columns.is-mobile > .column.is-offset-1 {
    margin-left: 8.33333%; }
  .columns.is-mobile > .column.is-2 {
    flex: none;
    width: 16.66667%; }
  .columns.is-mobile > .column.is-offset-2 {
    margin-left: 16.66667%; }
  .columns.is-mobile > .column.is-3 {
    flex: none;
    width: 25%; }
  .columns.is-mobile > .column.is-offset-3 {
    margin-left: 25%; }
  .columns.is-mobile > .column.is-4 {
    flex: none;
    width: 33.33333%; }
  .columns.is-mobile > .column.is-offset-4 {
    margin-left: 33.33333%; }
  .columns.is-mobile > .column.is-5 {
    flex: none;
    width: 41.66667%; }
  .columns.is-mobile > .column.is-offset-5 {
    margin-left: 41.66667%; }
  .columns.is-mobile > .column.is-6 {
    flex: none;
    width: 50%; }
  .columns.is-mobile > .column.is-offset-6 {
    margin-left: 50%; }
  .columns.is-mobile > .column.is-7 {
    flex: none;
    width: 58.33333%; }
  .columns.is-mobile > .column.is-offset-7 {
    margin-left: 58.33333%; }
  .columns.is-mobile > .column.is-8 {
    flex: none;
    width: 66.66667%; }
  .columns.is-mobile > .column.is-offset-8 {
    margin-left: 66.66667%; }
  .columns.is-mobile > .column.is-9 {
    flex: none;
    width: 75%; }
  .columns.is-mobile > .column.is-offset-9 {
    margin-left: 75%; }
  .columns.is-mobile > .column.is-10 {
    flex: none;
    width: 83.33333%; }
  .columns.is-mobile > .column.is-offset-10 {
    margin-left: 83.33333%; }
  .columns.is-mobile > .column.is-11 {
    flex: none;
    width: 91.66667%; }
  .columns.is-mobile > .column.is-offset-11 {
    margin-left: 91.66667%; }
  .columns.is-mobile > .column.is-12 {
    flex: none;
    width: 100%; }
  .columns.is-mobile > .column.is-offset-12 {
    margin-left: 100%; }
  @media screen and (max-width: 768px) {
    .column.is-narrow-mobile {
      flex: none;
      width: unset; }
    .column.is-full-mobile {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-mobile {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-mobile {
      flex: none;
      width: 66.6666%; }
    .column.is-half-mobile {
      flex: none;
      width: 50%; }
    .column.is-one-third-mobile {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-mobile {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-mobile {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-mobile {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-mobile {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-mobile {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-mobile {
      margin-left: 75%; }
    .column.is-offset-two-thirds-mobile {
      margin-left: 66.6666%; }
    .column.is-offset-half-mobile {
      margin-left: 50%; }
    .column.is-offset-one-third-mobile {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-mobile {
      margin-left: 25%; }
    .column.is-offset-one-fifth-mobile {
      margin-left: 20%; }
    .column.is-offset-two-fifths-mobile {
      margin-left: 40%; }
    .column.is-offset-three-fifths-mobile {
      margin-left: 60%; }
    .column.is-offset-four-fifths-mobile {
      margin-left: 80%; }
    .column.is-0-mobile {
      flex: none;
      width: 0%; }
    .column.is-offset-0-mobile {
      margin-left: 0%; }
    .column.is-1-mobile {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-mobile {
      margin-left: 8.33333%; }
    .column.is-2-mobile {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-mobile {
      margin-left: 16.66667%; }
    .column.is-3-mobile {
      flex: none;
      width: 25%; }
    .column.is-offset-3-mobile {
      margin-left: 25%; }
    .column.is-4-mobile {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-mobile {
      margin-left: 33.33333%; }
    .column.is-5-mobile {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-mobile {
      margin-left: 41.66667%; }
    .column.is-6-mobile {
      flex: none;
      width: 50%; }
    .column.is-offset-6-mobile {
      margin-left: 50%; }
    .column.is-7-mobile {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-mobile {
      margin-left: 58.33333%; }
    .column.is-8-mobile {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-mobile {
      margin-left: 66.66667%; }
    .column.is-9-mobile {
      flex: none;
      width: 75%; }
    .column.is-offset-9-mobile {
      margin-left: 75%; }
    .column.is-10-mobile {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-mobile {
      margin-left: 83.33333%; }
    .column.is-11-mobile {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-mobile {
      margin-left: 91.66667%; }
    .column.is-12-mobile {
      flex: none;
      width: 100%; }
    .column.is-offset-12-mobile {
      margin-left: 100%; } }
  @media screen and (min-width: 769px), print {
    .column.is-narrow, .column.is-narrow-tablet {
      flex: none;
      width: unset; }
    .column.is-full, .column.is-full-tablet {
      flex: none;
      width: 100%; }
    .column.is-three-quarters, .column.is-three-quarters-tablet {
      flex: none;
      width: 75%; }
    .column.is-two-thirds, .column.is-two-thirds-tablet {
      flex: none;
      width: 66.6666%; }
    .column.is-half, .column.is-half-tablet {
      flex: none;
      width: 50%; }
    .column.is-one-third, .column.is-one-third-tablet {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter, .column.is-one-quarter-tablet {
      flex: none;
      width: 25%; }
    .column.is-one-fifth, .column.is-one-fifth-tablet {
      flex: none;
      width: 20%; }
    .column.is-two-fifths, .column.is-two-fifths-tablet {
      flex: none;
      width: 40%; }
    .column.is-three-fifths, .column.is-three-fifths-tablet {
      flex: none;
      width: 60%; }
    .column.is-four-fifths, .column.is-four-fifths-tablet {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
      margin-left: 75%; }
    .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
      margin-left: 66.6666%; }
    .column.is-offset-half, .column.is-offset-half-tablet {
      margin-left: 50%; }
    .column.is-offset-one-third, .column.is-offset-one-third-tablet {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
      margin-left: 25%; }
    .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
      margin-left: 20%; }
    .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
      margin-left: 40%; }
    .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
      margin-left: 60%; }
    .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
      margin-left: 80%; }
    .column.is-0, .column.is-0-tablet {
      flex: none;
      width: 0%; }
    .column.is-offset-0, .column.is-offset-0-tablet {
      margin-left: 0%; }
    .column.is-1, .column.is-1-tablet {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1, .column.is-offset-1-tablet {
      margin-left: 8.33333%; }
    .column.is-2, .column.is-2-tablet {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2, .column.is-offset-2-tablet {
      margin-left: 16.66667%; }
    .column.is-3, .column.is-3-tablet {
      flex: none;
      width: 25%; }
    .column.is-offset-3, .column.is-offset-3-tablet {
      margin-left: 25%; }
    .column.is-4, .column.is-4-tablet {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4, .column.is-offset-4-tablet {
      margin-left: 33.33333%; }
    .column.is-5, .column.is-5-tablet {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5, .column.is-offset-5-tablet {
      margin-left: 41.66667%; }
    .column.is-6, .column.is-6-tablet {
      flex: none;
      width: 50%; }
    .column.is-offset-6, .column.is-offset-6-tablet {
      margin-left: 50%; }
    .column.is-7, .column.is-7-tablet {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7, .column.is-offset-7-tablet {
      margin-left: 58.33333%; }
    .column.is-8, .column.is-8-tablet {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8, .column.is-offset-8-tablet {
      margin-left: 66.66667%; }
    .column.is-9, .column.is-9-tablet {
      flex: none;
      width: 75%; }
    .column.is-offset-9, .column.is-offset-9-tablet {
      margin-left: 75%; }
    .column.is-10, .column.is-10-tablet {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10, .column.is-offset-10-tablet {
      margin-left: 83.33333%; }
    .column.is-11, .column.is-11-tablet {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11, .column.is-offset-11-tablet {
      margin-left: 91.66667%; }
    .column.is-12, .column.is-12-tablet {
      flex: none;
      width: 100%; }
    .column.is-offset-12, .column.is-offset-12-tablet {
      margin-left: 100%; } }
  @media screen and (max-width: 1023px) {
    .column.is-narrow-touch {
      flex: none;
      width: unset; }
    .column.is-full-touch {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-touch {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-touch {
      flex: none;
      width: 66.6666%; }
    .column.is-half-touch {
      flex: none;
      width: 50%; }
    .column.is-one-third-touch {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-touch {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-touch {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-touch {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-touch {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-touch {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-touch {
      margin-left: 75%; }
    .column.is-offset-two-thirds-touch {
      margin-left: 66.6666%; }
    .column.is-offset-half-touch {
      margin-left: 50%; }
    .column.is-offset-one-third-touch {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-touch {
      margin-left: 25%; }
    .column.is-offset-one-fifth-touch {
      margin-left: 20%; }
    .column.is-offset-two-fifths-touch {
      margin-left: 40%; }
    .column.is-offset-three-fifths-touch {
      margin-left: 60%; }
    .column.is-offset-four-fifths-touch {
      margin-left: 80%; }
    .column.is-0-touch {
      flex: none;
      width: 0%; }
    .column.is-offset-0-touch {
      margin-left: 0%; }
    .column.is-1-touch {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-touch {
      margin-left: 8.33333%; }
    .column.is-2-touch {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-touch {
      margin-left: 16.66667%; }
    .column.is-3-touch {
      flex: none;
      width: 25%; }
    .column.is-offset-3-touch {
      margin-left: 25%; }
    .column.is-4-touch {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-touch {
      margin-left: 33.33333%; }
    .column.is-5-touch {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-touch {
      margin-left: 41.66667%; }
    .column.is-6-touch {
      flex: none;
      width: 50%; }
    .column.is-offset-6-touch {
      margin-left: 50%; }
    .column.is-7-touch {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-touch {
      margin-left: 58.33333%; }
    .column.is-8-touch {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-touch {
      margin-left: 66.66667%; }
    .column.is-9-touch {
      flex: none;
      width: 75%; }
    .column.is-offset-9-touch {
      margin-left: 75%; }
    .column.is-10-touch {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-touch {
      margin-left: 83.33333%; }
    .column.is-11-touch {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-touch {
      margin-left: 91.66667%; }
    .column.is-12-touch {
      flex: none;
      width: 100%; }
    .column.is-offset-12-touch {
      margin-left: 100%; } }
  @media screen and (min-width: 1024px) {
    .column.is-narrow-desktop {
      flex: none;
      width: unset; }
    .column.is-full-desktop {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-desktop {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-desktop {
      flex: none;
      width: 66.6666%; }
    .column.is-half-desktop {
      flex: none;
      width: 50%; }
    .column.is-one-third-desktop {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-desktop {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-desktop {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-desktop {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-desktop {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-desktop {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-desktop {
      margin-left: 75%; }
    .column.is-offset-two-thirds-desktop {
      margin-left: 66.6666%; }
    .column.is-offset-half-desktop {
      margin-left: 50%; }
    .column.is-offset-one-third-desktop {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-desktop {
      margin-left: 25%; }
    .column.is-offset-one-fifth-desktop {
      margin-left: 20%; }
    .column.is-offset-two-fifths-desktop {
      margin-left: 40%; }
    .column.is-offset-three-fifths-desktop {
      margin-left: 60%; }
    .column.is-offset-four-fifths-desktop {
      margin-left: 80%; }
    .column.is-0-desktop {
      flex: none;
      width: 0%; }
    .column.is-offset-0-desktop {
      margin-left: 0%; }
    .column.is-1-desktop {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-desktop {
      margin-left: 8.33333%; }
    .column.is-2-desktop {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-desktop {
      margin-left: 16.66667%; }
    .column.is-3-desktop {
      flex: none;
      width: 25%; }
    .column.is-offset-3-desktop {
      margin-left: 25%; }
    .column.is-4-desktop {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-desktop {
      margin-left: 33.33333%; }
    .column.is-5-desktop {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-desktop {
      margin-left: 41.66667%; }
    .column.is-6-desktop {
      flex: none;
      width: 50%; }
    .column.is-offset-6-desktop {
      margin-left: 50%; }
    .column.is-7-desktop {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-desktop {
      margin-left: 58.33333%; }
    .column.is-8-desktop {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-desktop {
      margin-left: 66.66667%; }
    .column.is-9-desktop {
      flex: none;
      width: 75%; }
    .column.is-offset-9-desktop {
      margin-left: 75%; }
    .column.is-10-desktop {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-desktop {
      margin-left: 83.33333%; }
    .column.is-11-desktop {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-desktop {
      margin-left: 91.66667%; }
    .column.is-12-desktop {
      flex: none;
      width: 100%; }
    .column.is-offset-12-desktop {
      margin-left: 100%; } }
  @media screen and (min-width: 1216px) {
    .column.is-narrow-widescreen {
      flex: none;
      width: unset; }
    .column.is-full-widescreen {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-widescreen {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-widescreen {
      flex: none;
      width: 66.6666%; }
    .column.is-half-widescreen {
      flex: none;
      width: 50%; }
    .column.is-one-third-widescreen {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-widescreen {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-widescreen {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-widescreen {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-widescreen {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-widescreen {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-widescreen {
      margin-left: 75%; }
    .column.is-offset-two-thirds-widescreen {
      margin-left: 66.6666%; }
    .column.is-offset-half-widescreen {
      margin-left: 50%; }
    .column.is-offset-one-third-widescreen {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-widescreen {
      margin-left: 25%; }
    .column.is-offset-one-fifth-widescreen {
      margin-left: 20%; }
    .column.is-offset-two-fifths-widescreen {
      margin-left: 40%; }
    .column.is-offset-three-fifths-widescreen {
      margin-left: 60%; }
    .column.is-offset-four-fifths-widescreen {
      margin-left: 80%; }
    .column.is-0-widescreen {
      flex: none;
      width: 0%; }
    .column.is-offset-0-widescreen {
      margin-left: 0%; }
    .column.is-1-widescreen {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-widescreen {
      margin-left: 8.33333%; }
    .column.is-2-widescreen {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-widescreen {
      margin-left: 16.66667%; }
    .column.is-3-widescreen {
      flex: none;
      width: 25%; }
    .column.is-offset-3-widescreen {
      margin-left: 25%; }
    .column.is-4-widescreen {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-widescreen {
      margin-left: 33.33333%; }
    .column.is-5-widescreen {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-widescreen {
      margin-left: 41.66667%; }
    .column.is-6-widescreen {
      flex: none;
      width: 50%; }
    .column.is-offset-6-widescreen {
      margin-left: 50%; }
    .column.is-7-widescreen {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-widescreen {
      margin-left: 58.33333%; }
    .column.is-8-widescreen {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-widescreen {
      margin-left: 66.66667%; }
    .column.is-9-widescreen {
      flex: none;
      width: 75%; }
    .column.is-offset-9-widescreen {
      margin-left: 75%; }
    .column.is-10-widescreen {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-widescreen {
      margin-left: 83.33333%; }
    .column.is-11-widescreen {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-widescreen {
      margin-left: 91.66667%; }
    .column.is-12-widescreen {
      flex: none;
      width: 100%; }
    .column.is-offset-12-widescreen {
      margin-left: 100%; } }
  @media screen and (min-width: 1408px) {
    .column.is-narrow-fullhd {
      flex: none;
      width: unset; }
    .column.is-full-fullhd {
      flex: none;
      width: 100%; }
    .column.is-three-quarters-fullhd {
      flex: none;
      width: 75%; }
    .column.is-two-thirds-fullhd {
      flex: none;
      width: 66.6666%; }
    .column.is-half-fullhd {
      flex: none;
      width: 50%; }
    .column.is-one-third-fullhd {
      flex: none;
      width: 33.3333%; }
    .column.is-one-quarter-fullhd {
      flex: none;
      width: 25%; }
    .column.is-one-fifth-fullhd {
      flex: none;
      width: 20%; }
    .column.is-two-fifths-fullhd {
      flex: none;
      width: 40%; }
    .column.is-three-fifths-fullhd {
      flex: none;
      width: 60%; }
    .column.is-four-fifths-fullhd {
      flex: none;
      width: 80%; }
    .column.is-offset-three-quarters-fullhd {
      margin-left: 75%; }
    .column.is-offset-two-thirds-fullhd {
      margin-left: 66.6666%; }
    .column.is-offset-half-fullhd {
      margin-left: 50%; }
    .column.is-offset-one-third-fullhd {
      margin-left: 33.3333%; }
    .column.is-offset-one-quarter-fullhd {
      margin-left: 25%; }
    .column.is-offset-one-fifth-fullhd {
      margin-left: 20%; }
    .column.is-offset-two-fifths-fullhd {
      margin-left: 40%; }
    .column.is-offset-three-fifths-fullhd {
      margin-left: 60%; }
    .column.is-offset-four-fifths-fullhd {
      margin-left: 80%; }
    .column.is-0-fullhd {
      flex: none;
      width: 0%; }
    .column.is-offset-0-fullhd {
      margin-left: 0%; }
    .column.is-1-fullhd {
      flex: none;
      width: 8.33333%; }
    .column.is-offset-1-fullhd {
      margin-left: 8.33333%; }
    .column.is-2-fullhd {
      flex: none;
      width: 16.66667%; }
    .column.is-offset-2-fullhd {
      margin-left: 16.66667%; }
    .column.is-3-fullhd {
      flex: none;
      width: 25%; }
    .column.is-offset-3-fullhd {
      margin-left: 25%; }
    .column.is-4-fullhd {
      flex: none;
      width: 33.33333%; }
    .column.is-offset-4-fullhd {
      margin-left: 33.33333%; }
    .column.is-5-fullhd {
      flex: none;
      width: 41.66667%; }
    .column.is-offset-5-fullhd {
      margin-left: 41.66667%; }
    .column.is-6-fullhd {
      flex: none;
      width: 50%; }
    .column.is-offset-6-fullhd {
      margin-left: 50%; }
    .column.is-7-fullhd {
      flex: none;
      width: 58.33333%; }
    .column.is-offset-7-fullhd {
      margin-left: 58.33333%; }
    .column.is-8-fullhd {
      flex: none;
      width: 66.66667%; }
    .column.is-offset-8-fullhd {
      margin-left: 66.66667%; }
    .column.is-9-fullhd {
      flex: none;
      width: 75%; }
    .column.is-offset-9-fullhd {
      margin-left: 75%; }
    .column.is-10-fullhd {
      flex: none;
      width: 83.33333%; }
    .column.is-offset-10-fullhd {
      margin-left: 83.33333%; }
    .column.is-11-fullhd {
      flex: none;
      width: 91.66667%; }
    .column.is-offset-11-fullhd {
      margin-left: 91.66667%; }
    .column.is-12-fullhd {
      flex: none;
      width: 100%; }
    .column.is-offset-12-fullhd {
      margin-left: 100%; } }

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem; }
  .columns:last-child {
    margin-bottom: -0.75rem; }
  .columns:not(:last-child) {
    margin-bottom: calc(1.5rem - 0.75rem); }
  .columns.is-centered {
    justify-content: center; }
  .columns.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0; }
    .columns.is-gapless > .column {
      margin: 0;
      padding: 0 !important; }
    .columns.is-gapless:not(:last-child) {
      margin-bottom: 1.5rem; }
    .columns.is-gapless:last-child {
      margin-bottom: 0; }
  .columns.is-mobile {
    display: flex; }
  .columns.is-multiline {
    flex-wrap: wrap; }
  .columns.is-vcentered {
    align-items: center; }
  @media screen and (min-width: 769px), print {
    .columns:not(.is-desktop) {
      display: flex; } }
  @media screen and (min-width: 1024px) {
    .columns.is-desktop {
      display: flex; } }

.columns.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap)); }
  .columns.is-variable > .column {
    padding-left: var(--columnGap);
    padding-right: var(--columnGap); }
  .columns.is-variable.is-0 {
    --columnGap: 0rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-0-mobile {
      --columnGap: 0rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-0-tablet {
      --columnGap: 0rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-0-tablet-only {
      --columnGap: 0rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-0-touch {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-0-desktop {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-0-desktop-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-0-widescreen {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-0-widescreen-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-0-fullhd {
      --columnGap: 0rem; } }
  .columns.is-variable.is-1 {
    --columnGap: 0.25rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-1-mobile {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-1-tablet {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-1-tablet-only {
      --columnGap: 0.25rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-1-touch {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-1-desktop {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-1-desktop-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-1-widescreen {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-1-widescreen-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-1-fullhd {
      --columnGap: 0.25rem; } }
  .columns.is-variable.is-2 {
    --columnGap: 0.5rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-2-mobile {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-2-tablet {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-2-tablet-only {
      --columnGap: 0.5rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-2-touch {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-2-desktop {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-2-desktop-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-2-widescreen {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-2-widescreen-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-2-fullhd {
      --columnGap: 0.5rem; } }
  .columns.is-variable.is-3 {
    --columnGap: 0.75rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-3-mobile {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-3-tablet {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-3-tablet-only {
      --columnGap: 0.75rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-3-touch {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-3-desktop {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-3-desktop-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-3-widescreen {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-3-widescreen-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-3-fullhd {
      --columnGap: 0.75rem; } }
  .columns.is-variable.is-4 {
    --columnGap: 1rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-4-mobile {
      --columnGap: 1rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-4-tablet {
      --columnGap: 1rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-4-tablet-only {
      --columnGap: 1rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-4-touch {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-4-desktop {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-4-desktop-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-4-widescreen {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-4-widescreen-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-4-fullhd {
      --columnGap: 1rem; } }
  .columns.is-variable.is-5 {
    --columnGap: 1.25rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-5-mobile {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-5-tablet {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-5-tablet-only {
      --columnGap: 1.25rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-5-touch {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-5-desktop {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-5-desktop-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-5-widescreen {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-5-widescreen-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-5-fullhd {
      --columnGap: 1.25rem; } }
  .columns.is-variable.is-6 {
    --columnGap: 1.5rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-6-mobile {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-6-tablet {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-6-tablet-only {
      --columnGap: 1.5rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-6-touch {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-6-desktop {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-6-desktop-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-6-widescreen {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-6-widescreen-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-6-fullhd {
      --columnGap: 1.5rem; } }
  .columns.is-variable.is-7 {
    --columnGap: 1.75rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-7-mobile {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-7-tablet {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-7-tablet-only {
      --columnGap: 1.75rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-7-touch {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-7-desktop {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-7-desktop-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-7-widescreen {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-7-widescreen-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-7-fullhd {
      --columnGap: 1.75rem; } }
  .columns.is-variable.is-8 {
    --columnGap: 2rem; }
  @media screen and (max-width: 768px) {
    .columns.is-variable.is-8-mobile {
      --columnGap: 2rem; } }
  @media screen and (min-width: 769px), print {
    .columns.is-variable.is-8-tablet {
      --columnGap: 2rem; } }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    .columns.is-variable.is-8-tablet-only {
      --columnGap: 2rem; } }
  @media screen and (max-width: 1023px) {
    .columns.is-variable.is-8-touch {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1024px) {
    .columns.is-variable.is-8-desktop {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .columns.is-variable.is-8-desktop-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1216px) {
    .columns.is-variable.is-8-widescreen {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1216px) and (max-width: 1407px) {
    .columns.is-variable.is-8-widescreen-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1408px) {
    .columns.is-variable.is-8-fullhd {
      --columnGap: 2rem; } }

/*https://bulma.io/documentation/columns/*/
/* Bulma Helpers */
.has-text-white {
  color: white !important; }

a.has-text-white:hover, a.has-text-white:focus {
  color: #e6e6e6 !important; }

.has-background-white {
  background-color: white !important; }

.has-text-black {
  color: #0a0a0a !important; }

a.has-text-black:hover, a.has-text-black:focus {
  color: black !important; }

.has-background-black {
  background-color: #0a0a0a !important; }

.has-text-light {
  color: whitesmoke !important; }

a.has-text-light:hover, a.has-text-light:focus {
  color: #dbdbdb !important; }

.has-background-light {
  background-color: whitesmoke !important; }

.has-text-dark {
  color: #363636 !important; }

a.has-text-dark:hover, a.has-text-dark:focus {
  color: #1c1c1c !important; }

.has-background-dark {
  background-color: #363636 !important; }

.has-text-primary {
  color: #0070b8 !important; }

a.has-text-primary:hover, a.has-text-primary:focus {
  color: #005185 !important; }

.has-background-primary {
  background-color: #0070b8 !important; }

.has-text-primary-light {
  color: #cce2f1 !important; }

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #a4cbe6 !important; }

.has-background-primary-light {
  background-color: #cce2f1 !important; }

.has-text-primary-dark {
  color: rgba(0, 112, 184, 0.5) !important; }

a.has-text-primary-dark:hover, a.has-text-primary-dark:focus {
  color: rgba(0, 143, 235, 0.5) !important; }

.has-background-primary-dark {
  background-color: rgba(0, 112, 184, 0.5) !important; }

.has-text-link {
  color: #485fc7 !important; }

a.has-text-link:hover, a.has-text-link:focus {
  color: #3449a8 !important; }

.has-background-link {
  background-color: #485fc7 !important; }

.has-text-link-light {
  color: #eff1fa !important; }

a.has-text-link-light:hover, a.has-text-link-light:focus {
  color: #c8cfee !important; }

.has-background-link-light {
  background-color: #eff1fa !important; }

.has-text-link-dark {
  color: #3850b7 !important; }

a.has-text-link-dark:hover, a.has-text-link-dark:focus {
  color: #576dcb !important; }

.has-background-link-dark {
  background-color: #3850b7 !important; }

.has-text-info {
  color: #3e8ed0 !important; }

a.has-text-info:hover, a.has-text-info:focus {
  color: #2b74b1 !important; }

.has-background-info {
  background-color: #3e8ed0 !important; }

.has-text-info-light {
  color: #eff5fb !important; }

a.has-text-info-light:hover, a.has-text-info-light:focus {
  color: #c6ddf1 !important; }

.has-background-info-light {
  background-color: #eff5fb !important; }

.has-text-info-dark {
  color: #296fa8 !important; }

a.has-text-info-dark:hover, a.has-text-info-dark:focus {
  color: #368ace !important; }

.has-background-info-dark {
  background-color: #296fa8 !important; }

.has-text-success {
  color: #63aa16 !important; }

a.has-text-success:hover, a.has-text-success:focus {
  color: #497d10 !important; }

.has-background-success {
  background-color: #63aa16 !important; }

.has-text-success-light {
  color: #f5fded !important; }

a.has-text-success-light:hover, a.has-text-success-light:focus {
  color: #dcf7c0 !important; }

.has-background-success-light {
  background-color: #f5fded !important; }

.has-text-success-dark {
  color: #61a716 !important; }

a.has-text-success-dark:hover, a.has-text-success-dark:focus {
  color: #7cd41b !important; }

.has-background-success-dark {
  background-color: #61a716 !important; }

.has-text-warning {
  color: #ffe08a !important; }

a.has-text-warning:hover, a.has-text-warning:focus {
  color: #ffd257 !important; }

.has-background-warning {
  background-color: #ffe08a !important; }

.has-text-warning-light {
  color: #fffaeb !important; }

a.has-text-warning-light:hover, a.has-text-warning-light:focus {
  color: #ffecb8 !important; }

.has-background-warning-light {
  background-color: #fffaeb !important; }

.has-text-warning-dark {
  color: #946c00 !important; }

a.has-text-warning-dark:hover, a.has-text-warning-dark:focus {
  color: #c79200 !important; }

.has-background-warning-dark {
  background-color: #946c00 !important; }

.has-text-danger {
  color: #d0021b !important; }

a.has-text-danger:hover, a.has-text-danger:focus {
  color: #9d0214 !important; }

.has-background-danger {
  background-color: #d0021b !important; }

.has-text-danger-light {
  color: #ffebed !important; }

a.has-text-danger-light:hover, a.has-text-danger-light:focus {
  color: #feb8c1 !important; }

.has-background-danger-light {
  background-color: #ffebed !important; }

.has-text-danger-dark {
  color: #f80220 !important; }

a.has-text-danger-dark:hover, a.has-text-danger-dark:focus {
  color: #fd3049 !important; }

.has-background-danger-dark {
  background-color: #f80220 !important; }

.has-text-primary-light {
  color: #cce2f1 !important; }

a.has-text-primary-light:hover, a.has-text-primary-light:focus {
  color: #a4cbe6 !important; }

.has-background-primary-light {
  background-color: #cce2f1 !important; }

.has-text-primary-light-light {
  color: #eff6fb !important; }

a.has-text-primary-light-light:hover, a.has-text-primary-light-light:focus {
  color: #c7dff0 !important; }

.has-background-primary-light-light {
  background-color: #eff6fb !important; }

.has-text-primary-light-dark {
  color: #205274 !important; }

a.has-text-primary-light-dark:hover, a.has-text-primary-light-dark:focus {
  color: #2b6e9c !important; }

.has-background-primary-light-dark {
  background-color: #205274 !important; }

.has-text-secondary {
  color: rgba(0, 112, 184, 0.4) !important; }

a.has-text-secondary:hover, a.has-text-secondary:focus {
  color: rgba(0, 81, 133, 0.4) !important; }

.has-background-secondary {
  background-color: rgba(0, 112, 184, 0.4) !important; }

.has-text-secondary-light {
  color: rgba(235, 247, 255, 0.4) !important; }

a.has-text-secondary-light:hover, a.has-text-secondary-light:focus {
  color: rgba(184, 227, 255, 0.4) !important; }

.has-background-secondary-light {
  background-color: rgba(235, 247, 255, 0.4) !important; }

.has-text-secondary-dark {
  color: rgba(0, 146, 240, 0.4) !important; }

a.has-text-secondary-dark:hover, a.has-text-secondary-dark:focus {
  color: rgba(36, 169, 255, 0.4) !important; }

.has-background-secondary-dark {
  background-color: rgba(0, 146, 240, 0.4) !important; }

.has-text-secondary-dark {
  color: #01375d !important; }

a.has-text-secondary-dark:hover, a.has-text-secondary-dark:focus {
  color: #00192b !important; }

.has-background-secondary-dark {
  background-color: #01375d !important; }

.has-text-secondary-dark-light {
  color: #ebf7ff !important; }

a.has-text-secondary-dark-light:hover, a.has-text-secondary-dark-light:focus {
  color: #b8e1fe !important; }

.has-background-secondary-dark-light {
  background-color: #ebf7ff !important; }

.has-text-secondary-dark-dark {
  color: #179efd !important; }

a.has-text-secondary-dark-dark:hover, a.has-text-secondary-dark-dark:focus {
  color: #49b3fd !important; }

.has-background-secondary-dark-dark {
  background-color: #179efd !important; }

.has-text-black-bis {
  color: #121212 !important; }

.has-background-black-bis {
  background-color: #121212 !important; }

.has-text-black-ter {
  color: #242424 !important; }

.has-background-black-ter {
  background-color: #242424 !important; }

.has-text-grey-darker {
  color: #363636 !important; }

.has-background-grey-darker {
  background-color: #363636 !important; }

.has-text-grey-dark {
  color: #4a4a4a !important; }

.has-background-grey-dark {
  background-color: #4a4a4a !important; }

.has-text-grey {
  color: #7a7a7a !important; }

.has-background-grey {
  background-color: #7a7a7a !important; }

.has-text-grey-light {
  color: #b5b5b5 !important; }

.has-background-grey-light {
  background-color: #b5b5b5 !important; }

.has-text-grey-lighter {
  color: #f2f2f2 !important; }

.has-background-grey-lighter {
  background-color: #f2f2f2 !important; }

.has-text-white-ter {
  color: whitesmoke !important; }

.has-background-white-ter {
  background-color: whitesmoke !important; }

.has-text-white-bis {
  color: #fafafa !important; }

.has-background-white-bis {
  background-color: #fafafa !important; }

/*https://bulma.io/documentation/helpers/color-helpers/*/
.is-flex-direction-row {
  flex-direction: row !important; }

.is-flex-direction-row-reverse {
  flex-direction: row-reverse !important; }

.is-flex-direction-column {
  flex-direction: column !important; }

.is-flex-direction-column-reverse {
  flex-direction: column-reverse !important; }

.is-flex-wrap-nowrap {
  flex-wrap: nowrap !important; }

.is-flex-wrap-wrap {
  flex-wrap: wrap !important; }

.is-flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.is-justify-content-flex-start {
  justify-content: flex-start !important; }

.is-justify-content-flex-end {
  justify-content: flex-end !important; }

.is-justify-content-center {
  justify-content: center !important; }

.is-justify-content-space-between {
  justify-content: space-between !important; }

.is-justify-content-space-around {
  justify-content: space-around !important; }

.is-justify-content-space-evenly {
  justify-content: space-evenly !important; }

.is-justify-content-start {
  justify-content: start !important; }

.is-justify-content-end {
  justify-content: end !important; }

.is-justify-content-left {
  justify-content: left !important; }

.is-justify-content-right {
  justify-content: right !important; }

.is-align-content-flex-start {
  align-content: flex-start !important; }

.is-align-content-flex-end {
  align-content: flex-end !important; }

.is-align-content-center {
  align-content: center !important; }

.is-align-content-space-between {
  align-content: space-between !important; }

.is-align-content-space-around {
  align-content: space-around !important; }

.is-align-content-space-evenly {
  align-content: space-evenly !important; }

.is-align-content-stretch {
  align-content: stretch !important; }

.is-align-content-start {
  align-content: start !important; }

.is-align-content-end {
  align-content: end !important; }

.is-align-content-baseline {
  align-content: baseline !important; }

.is-align-items-stretch {
  align-items: stretch !important; }

.is-align-items-flex-start {
  align-items: flex-start !important; }

.is-align-items-flex-end {
  align-items: flex-end !important; }

.is-align-items-center {
  align-items: center !important; }

.is-align-items-baseline {
  align-items: baseline !important; }

.is-align-items-start {
  align-items: start !important; }

.is-align-items-end {
  align-items: end !important; }

.is-align-items-self-start {
  align-items: self-start !important; }

.is-align-items-self-end {
  align-items: self-end !important; }

.is-align-self-auto {
  align-self: auto !important; }

.is-align-self-flex-start {
  align-self: flex-start !important; }

.is-align-self-flex-end {
  align-self: flex-end !important; }

.is-align-self-center {
  align-self: center !important; }

.is-align-self-baseline {
  align-self: baseline !important; }

.is-align-self-stretch {
  align-self: stretch !important; }

.is-flex-grow-0 {
  flex-grow: 0 !important; }

.is-flex-grow-1 {
  flex-grow: 1 !important; }

.is-flex-grow-2 {
  flex-grow: 2 !important; }

.is-flex-grow-3 {
  flex-grow: 3 !important; }

.is-flex-grow-4 {
  flex-grow: 4 !important; }

.is-flex-grow-5 {
  flex-grow: 5 !important; }

.is-flex-shrink-0 {
  flex-shrink: 0 !important; }

.is-flex-shrink-1 {
  flex-shrink: 1 !important; }

.is-flex-shrink-2 {
  flex-shrink: 2 !important; }

.is-flex-shrink-3 {
  flex-shrink: 3 !important; }

.is-flex-shrink-4 {
  flex-shrink: 4 !important; }

.is-flex-shrink-5 {
  flex-shrink: 5 !important; }

.is-marginless {
  margin: 0 !important; }

.is-paddingless {
  padding: 0 !important; }

.m-0 {
  margin: 0rem !important; }

.mt-0 {
  margin-top: 0rem !important; }

.mr-0 {
  margin-right: 0rem !important; }

.mb-0 {
  margin-bottom: 0rem !important; }

.ml-0 {
  margin-left: 0rem !important; }

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important; }

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.m-1 {
  margin: 0.75rem !important; }

.mt-1 {
  margin-top: 0.75rem !important; }

.mr-1 {
  margin-right: 0.75rem !important; }

.mb-1 {
  margin-bottom: 0.75rem !important; }

.ml-1 {
  margin-left: 0.75rem !important; }

.mx-1 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important; }

.my-1 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2 {
  margin-top: 1rem !important; }

.mr-2 {
  margin-right: 1rem !important; }

.mb-2 {
  margin-bottom: 1rem !important; }

.ml-2 {
  margin-left: 1rem !important; }

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important; }

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-3 {
  margin: 1.333rem !important; }

.mt-3 {
  margin-top: 1.333rem !important; }

.mr-3 {
  margin-right: 1.333rem !important; }

.mb-3 {
  margin-bottom: 1.333rem !important; }

.ml-3 {
  margin-left: 1.333rem !important; }

.mx-3 {
  margin-left: 1.333rem !important;
  margin-right: 1.333rem !important; }

.my-3 {
  margin-top: 1.333rem !important;
  margin-bottom: 1.333rem !important; }

.m-4 {
  margin: 1.777rem !important; }

.mt-4 {
  margin-top: 1.777rem !important; }

.mr-4 {
  margin-right: 1.777rem !important; }

.mb-4 {
  margin-bottom: 1.777rem !important; }

.ml-4 {
  margin-left: 1.777rem !important; }

.mx-4 {
  margin-left: 1.777rem !important;
  margin-right: 1.777rem !important; }

.my-4 {
  margin-top: 1.777rem !important;
  margin-bottom: 1.777rem !important; }

.m-5 {
  margin: 2.369rem !important; }

.mt-5 {
  margin-top: 2.369rem !important; }

.mr-5 {
  margin-right: 2.369rem !important; }

.mb-5 {
  margin-bottom: 2.369rem !important; }

.ml-5 {
  margin-left: 2.369rem !important; }

.mx-5 {
  margin-left: 2.369rem !important;
  margin-right: 2.369rem !important; }

.my-5 {
  margin-top: 2.369rem !important;
  margin-bottom: 2.369rem !important; }

.m-6 {
  margin: 3.157rem !important; }

.mt-6 {
  margin-top: 3.157rem !important; }

.mr-6 {
  margin-right: 3.157rem !important; }

.mb-6 {
  margin-bottom: 3.157rem !important; }

.ml-6 {
  margin-left: 3.157rem !important; }

.mx-6 {
  margin-left: 3.157rem !important;
  margin-right: 3.157rem !important; }

.my-6 {
  margin-top: 3.157rem !important;
  margin-bottom: 3.157rem !important; }

.m-7 {
  margin: 4.209rem !important; }

.mt-7 {
  margin-top: 4.209rem !important; }

.mr-7 {
  margin-right: 4.209rem !important; }

.mb-7 {
  margin-bottom: 4.209rem !important; }

.ml-7 {
  margin-left: 4.209rem !important; }

.mx-7 {
  margin-left: 4.209rem !important;
  margin-right: 4.209rem !important; }

.my-7 {
  margin-top: 4.209rem !important;
  margin-bottom: 4.209rem !important; }

.p-0 {
  padding: 0rem !important; }

.pt-0 {
  padding-top: 0rem !important; }

.pr-0 {
  padding-right: 0rem !important; }

.pb-0 {
  padding-bottom: 0rem !important; }

.pl-0 {
  padding-left: 0rem !important; }

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important; }

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.p-1 {
  padding: 0.75rem !important; }

.pt-1 {
  padding-top: 0.75rem !important; }

.pr-1 {
  padding-right: 0.75rem !important; }

.pb-1 {
  padding-bottom: 0.75rem !important; }

.pl-1 {
  padding-left: 0.75rem !important; }

.px-1 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important; }

.py-1 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2 {
  padding-top: 1rem !important; }

.pr-2 {
  padding-right: 1rem !important; }

.pb-2 {
  padding-bottom: 1rem !important; }

.pl-2 {
  padding-left: 1rem !important; }

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-3 {
  padding: 1.333rem !important; }

.pt-3 {
  padding-top: 1.333rem !important; }

.pr-3 {
  padding-right: 1.333rem !important; }

.pb-3 {
  padding-bottom: 1.333rem !important; }

.pl-3 {
  padding-left: 1.333rem !important; }

.px-3 {
  padding-left: 1.333rem !important;
  padding-right: 1.333rem !important; }

.py-3 {
  padding-top: 1.333rem !important;
  padding-bottom: 1.333rem !important; }

.p-4 {
  padding: 1.777rem !important; }

.pt-4 {
  padding-top: 1.777rem !important; }

.pr-4 {
  padding-right: 1.777rem !important; }

.pb-4 {
  padding-bottom: 1.777rem !important; }

.pl-4 {
  padding-left: 1.777rem !important; }

.px-4 {
  padding-left: 1.777rem !important;
  padding-right: 1.777rem !important; }

.py-4 {
  padding-top: 1.777rem !important;
  padding-bottom: 1.777rem !important; }

.p-5 {
  padding: 2.369rem !important; }

.pt-5 {
  padding-top: 2.369rem !important; }

.pr-5 {
  padding-right: 2.369rem !important; }

.pb-5 {
  padding-bottom: 2.369rem !important; }

.pl-5 {
  padding-left: 2.369rem !important; }

.px-5 {
  padding-left: 2.369rem !important;
  padding-right: 2.369rem !important; }

.py-5 {
  padding-top: 2.369rem !important;
  padding-bottom: 2.369rem !important; }

.p-6 {
  padding: 3.157rem !important; }

.pt-6 {
  padding-top: 3.157rem !important; }

.pr-6 {
  padding-right: 3.157rem !important; }

.pb-6 {
  padding-bottom: 3.157rem !important; }

.pl-6 {
  padding-left: 3.157rem !important; }

.px-6 {
  padding-left: 3.157rem !important;
  padding-right: 3.157rem !important; }

.py-6 {
  padding-top: 3.157rem !important;
  padding-bottom: 3.157rem !important; }

.p-7 {
  padding: 4.209rem !important; }

.pt-7 {
  padding-top: 4.209rem !important; }

.pr-7 {
  padding-right: 4.209rem !important; }

.pb-7 {
  padding-bottom: 4.209rem !important; }

.pl-7 {
  padding-left: 4.209rem !important; }

.px-7 {
  padding-left: 4.209rem !important;
  padding-right: 4.209rem !important; }

.py-7 {
  padding-top: 4.209rem !important;
  padding-bottom: 4.209rem !important; }

/*https://bulma.io/documentation/helpers/spacing-helpers/*/
.is-size-1 {
  font-size: 4.209rem !important; }

.is-size-2 {
  font-size: 3.157rem !important; }

.is-size-3 {
  font-size: 2.369rem !important; }

.is-size-4 {
  font-size: 1.777rem !important; }

.is-size-5 {
  font-size: 1.333rem !important; }

.is-size-6 {
  font-size: 1rem !important; }

.is-size-7 {
  font-size: 0.75rem !important; }

@media screen and (max-width: 768px) {
  .is-size-1-mobile {
    font-size: 4.209rem !important; }
  .is-size-2-mobile {
    font-size: 3.157rem !important; }
  .is-size-3-mobile {
    font-size: 2.369rem !important; }
  .is-size-4-mobile {
    font-size: 1.777rem !important; }
  .is-size-5-mobile {
    font-size: 1.333rem !important; }
  .is-size-6-mobile {
    font-size: 1rem !important; }
  .is-size-7-mobile {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 769px), print {
  .is-size-1-tablet {
    font-size: 4.209rem !important; }
  .is-size-2-tablet {
    font-size: 3.157rem !important; }
  .is-size-3-tablet {
    font-size: 2.369rem !important; }
  .is-size-4-tablet {
    font-size: 1.777rem !important; }
  .is-size-5-tablet {
    font-size: 1.333rem !important; }
  .is-size-6-tablet {
    font-size: 1rem !important; }
  .is-size-7-tablet {
    font-size: 0.75rem !important; } }

@media screen and (max-width: 1023px) {
  .is-size-1-touch {
    font-size: 4.209rem !important; }
  .is-size-2-touch {
    font-size: 3.157rem !important; }
  .is-size-3-touch {
    font-size: 2.369rem !important; }
  .is-size-4-touch {
    font-size: 1.777rem !important; }
  .is-size-5-touch {
    font-size: 1.333rem !important; }
  .is-size-6-touch {
    font-size: 1rem !important; }
  .is-size-7-touch {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 1024px) {
  .is-size-1-desktop {
    font-size: 4.209rem !important; }
  .is-size-2-desktop {
    font-size: 3.157rem !important; }
  .is-size-3-desktop {
    font-size: 2.369rem !important; }
  .is-size-4-desktop {
    font-size: 1.777rem !important; }
  .is-size-5-desktop {
    font-size: 1.333rem !important; }
  .is-size-6-desktop {
    font-size: 1rem !important; }
  .is-size-7-desktop {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 1216px) {
  .is-size-1-widescreen {
    font-size: 4.209rem !important; }
  .is-size-2-widescreen {
    font-size: 3.157rem !important; }
  .is-size-3-widescreen {
    font-size: 2.369rem !important; }
  .is-size-4-widescreen {
    font-size: 1.777rem !important; }
  .is-size-5-widescreen {
    font-size: 1.333rem !important; }
  .is-size-6-widescreen {
    font-size: 1rem !important; }
  .is-size-7-widescreen {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 1408px) {
  .is-size-1-fullhd {
    font-size: 4.209rem !important; }
  .is-size-2-fullhd {
    font-size: 3.157rem !important; }
  .is-size-3-fullhd {
    font-size: 2.369rem !important; }
  .is-size-4-fullhd {
    font-size: 1.777rem !important; }
  .is-size-5-fullhd {
    font-size: 1.333rem !important; }
  .is-size-6-fullhd {
    font-size: 1rem !important; }
  .is-size-7-fullhd {
    font-size: 0.75rem !important; } }

.has-text-centered {
  text-align: center !important; }

.has-text-justified {
  text-align: justify !important; }

.has-text-left {
  text-align: left !important; }

.has-text-right {
  text-align: right !important; }

@media screen and (max-width: 768px) {
  .has-text-centered-mobile {
    text-align: center !important; } }

@media screen and (min-width: 769px), print {
  .has-text-centered-tablet {
    text-align: center !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-centered-tablet-only {
    text-align: center !important; } }

@media screen and (max-width: 1023px) {
  .has-text-centered-touch {
    text-align: center !important; } }

@media screen and (min-width: 1024px) {
  .has-text-centered-desktop {
    text-align: center !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-centered-desktop-only {
    text-align: center !important; } }

@media screen and (min-width: 1216px) {
  .has-text-centered-widescreen {
    text-align: center !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-centered-widescreen-only {
    text-align: center !important; } }

@media screen and (min-width: 1408px) {
  .has-text-centered-fullhd {
    text-align: center !important; } }

@media screen and (max-width: 768px) {
  .has-text-justified-mobile {
    text-align: justify !important; } }

@media screen and (min-width: 769px), print {
  .has-text-justified-tablet {
    text-align: justify !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-justified-tablet-only {
    text-align: justify !important; } }

@media screen and (max-width: 1023px) {
  .has-text-justified-touch {
    text-align: justify !important; } }

@media screen and (min-width: 1024px) {
  .has-text-justified-desktop {
    text-align: justify !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-justified-desktop-only {
    text-align: justify !important; } }

@media screen and (min-width: 1216px) {
  .has-text-justified-widescreen {
    text-align: justify !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important; } }

@media screen and (min-width: 1408px) {
  .has-text-justified-fullhd {
    text-align: justify !important; } }

@media screen and (max-width: 768px) {
  .has-text-left-mobile {
    text-align: left !important; } }

@media screen and (min-width: 769px), print {
  .has-text-left-tablet {
    text-align: left !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-left-tablet-only {
    text-align: left !important; } }

@media screen and (max-width: 1023px) {
  .has-text-left-touch {
    text-align: left !important; } }

@media screen and (min-width: 1024px) {
  .has-text-left-desktop {
    text-align: left !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-left-desktop-only {
    text-align: left !important; } }

@media screen and (min-width: 1216px) {
  .has-text-left-widescreen {
    text-align: left !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-left-widescreen-only {
    text-align: left !important; } }

@media screen and (min-width: 1408px) {
  .has-text-left-fullhd {
    text-align: left !important; } }

@media screen and (max-width: 768px) {
  .has-text-right-mobile {
    text-align: right !important; } }

@media screen and (min-width: 769px), print {
  .has-text-right-tablet {
    text-align: right !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .has-text-right-tablet-only {
    text-align: right !important; } }

@media screen and (max-width: 1023px) {
  .has-text-right-touch {
    text-align: right !important; } }

@media screen and (min-width: 1024px) {
  .has-text-right-desktop {
    text-align: right !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .has-text-right-desktop-only {
    text-align: right !important; } }

@media screen and (min-width: 1216px) {
  .has-text-right-widescreen {
    text-align: right !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .has-text-right-widescreen-only {
    text-align: right !important; } }

@media screen and (min-width: 1408px) {
  .has-text-right-fullhd {
    text-align: right !important; } }

.is-capitalized {
  text-transform: capitalize !important; }

.is-lowercase {
  text-transform: lowercase !important; }

.is-uppercase {
  text-transform: uppercase !important; }

.is-italic {
  font-style: italic !important; }

.is-underlined {
  text-decoration: underline !important; }

.has-text-weight-light {
  font-weight: 300 !important; }

.has-text-weight-normal {
  font-weight: 400 !important; }

.has-text-weight-medium {
  font-weight: 500 !important; }

.has-text-weight-semibold {
  font-weight: 600 !important; }

.has-text-weight-bold {
  font-weight: 700 !important; }

.is-family-primary {
  font-family: "Open Sans", Helvetica, sans-serif !important; }

.is-family-secondary {
  font-family: Times !important; }

.is-family-sans-serif {
  font-family: "Open Sans", Helvetica, sans-serif !important; }

.is-family-monospace {
  font-family: monospace !important; }

.is-family-code {
  font-family: monospace !important; }

/*https://bulma.io/documentation/helpers/typography-helpers/*/
.is-block {
  display: block !important; }

@media screen and (max-width: 768px) {
  .is-block-mobile {
    display: block !important; } }

@media screen and (min-width: 769px), print {
  .is-block-tablet {
    display: block !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-block-tablet-only {
    display: block !important; } }

@media screen and (max-width: 1023px) {
  .is-block-touch {
    display: block !important; } }

@media screen and (min-width: 1024px) {
  .is-block-desktop {
    display: block !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-block-desktop-only {
    display: block !important; } }

@media screen and (min-width: 1216px) {
  .is-block-widescreen {
    display: block !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-block-widescreen-only {
    display: block !important; } }

@media screen and (min-width: 1408px) {
  .is-block-fullhd {
    display: block !important; } }

.is-flex {
  display: flex !important; }

@media screen and (max-width: 768px) {
  .is-flex-mobile {
    display: flex !important; } }

@media screen and (min-width: 769px), print {
  .is-flex-tablet {
    display: flex !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-flex-tablet-only {
    display: flex !important; } }

@media screen and (max-width: 1023px) {
  .is-flex-touch {
    display: flex !important; } }

@media screen and (min-width: 1024px) {
  .is-flex-desktop {
    display: flex !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-flex-desktop-only {
    display: flex !important; } }

@media screen and (min-width: 1216px) {
  .is-flex-widescreen {
    display: flex !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-flex-widescreen-only {
    display: flex !important; } }

@media screen and (min-width: 1408px) {
  .is-flex-fullhd {
    display: flex !important; } }

.is-inline {
  display: inline !important; }

@media screen and (max-width: 768px) {
  .is-inline-mobile {
    display: inline !important; } }

@media screen and (min-width: 769px), print {
  .is-inline-tablet {
    display: inline !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-tablet-only {
    display: inline !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-touch {
    display: inline !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-desktop {
    display: inline !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-desktop-only {
    display: inline !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-widescreen {
    display: inline !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-widescreen-only {
    display: inline !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-fullhd {
    display: inline !important; } }

.is-inline-block {
  display: inline-block !important; }

@media screen and (max-width: 768px) {
  .is-inline-block-mobile {
    display: inline-block !important; } }

@media screen and (min-width: 769px), print {
  .is-inline-block-tablet {
    display: inline-block !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-block-tablet-only {
    display: inline-block !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-block-touch {
    display: inline-block !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-block-desktop {
    display: inline-block !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-block-desktop-only {
    display: inline-block !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-block-widescreen {
    display: inline-block !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-block-widescreen-only {
    display: inline-block !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-block-fullhd {
    display: inline-block !important; } }

.is-inline-flex {
  display: inline-flex !important; }

@media screen and (max-width: 768px) {
  .is-inline-flex-mobile {
    display: inline-flex !important; } }

@media screen and (min-width: 769px), print {
  .is-inline-flex-tablet {
    display: inline-flex !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-inline-flex-tablet-only {
    display: inline-flex !important; } }

@media screen and (max-width: 1023px) {
  .is-inline-flex-touch {
    display: inline-flex !important; } }

@media screen and (min-width: 1024px) {
  .is-inline-flex-desktop {
    display: inline-flex !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-inline-flex-desktop-only {
    display: inline-flex !important; } }

@media screen and (min-width: 1216px) {
  .is-inline-flex-widescreen {
    display: inline-flex !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-inline-flex-widescreen-only {
    display: inline-flex !important; } }

@media screen and (min-width: 1408px) {
  .is-inline-flex-fullhd {
    display: inline-flex !important; } }

.is-hidden {
  display: none !important; }

.is-sr-only {
  border: none !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.01em !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.01em !important; }

@media screen and (max-width: 768px) {
  .is-hidden-mobile {
    display: none !important; } }

@media screen and (min-width: 769px), print {
  .is-hidden-tablet {
    display: none !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-hidden-tablet-only {
    display: none !important; } }

@media screen and (max-width: 1023px) {
  .is-hidden-touch {
    display: none !important; } }

@media screen and (min-width: 1024px) {
  .is-hidden-desktop {
    display: none !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-hidden-desktop-only {
    display: none !important; } }

@media screen and (min-width: 1216px) {
  .is-hidden-widescreen {
    display: none !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-hidden-widescreen-only {
    display: none !important; } }

@media screen and (min-width: 1408px) {
  .is-hidden-fullhd {
    display: none !important; } }

.is-invisible {
  visibility: hidden !important; }

@media screen and (max-width: 768px) {
  .is-invisible-mobile {
    visibility: hidden !important; } }

@media screen and (min-width: 769px), print {
  .is-invisible-tablet {
    visibility: hidden !important; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .is-invisible-tablet-only {
    visibility: hidden !important; } }

@media screen and (max-width: 1023px) {
  .is-invisible-touch {
    visibility: hidden !important; } }

@media screen and (min-width: 1024px) {
  .is-invisible-desktop {
    visibility: hidden !important; } }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .is-invisible-desktop-only {
    visibility: hidden !important; } }

@media screen and (min-width: 1216px) {
  .is-invisible-widescreen {
    visibility: hidden !important; } }

@media screen and (min-width: 1216px) and (max-width: 1407px) {
  .is-invisible-widescreen-only {
    visibility: hidden !important; } }

@media screen and (min-width: 1408px) {
  .is-invisible-fullhd {
    visibility: hidden !important; } }

/*https://bulma.io/documentation/helpers/visibility-helpers/*/
/* Bulma Layout */
.section {
  padding: 1rem 1.333rem; }
  @media screen and (min-width: 1024px) {
    .section {
      padding: 3rem 3rem; }
      .section.is-medium {
        padding: 1.777rem 1.333rem; }
      .section.is-large {
        padding: 3.157rem 1.333rem; } }

.is-checkradio[type="radio"],
.is-checkradio[type="checkbox"] {
  outline: 0;
  user-select: none;
  display: inline-block;
  position: absolute;
  opacity: 0; }
  .is-checkradio[type="radio"] + label,
  .is-checkradio[type="checkbox"] + label {
    position: relative;
    display: initial;
    cursor: pointer;
    vertical-align: middle;
    margin: .5em;
    padding: .2rem .5rem .2rem 0;
    border-radius: 4px; }
    .is-checkradio[type="radio"] + label:first-of-type,
    .is-checkradio[type="checkbox"] + label:first-of-type {
      margin-left: 0; }
    .is-checkradio[type="radio"] + label:hover::before, .is-checkradio[type="radio"] + label:hover:before,
    .is-checkradio[type="checkbox"] + label:hover::before,
    .is-checkradio[type="checkbox"] + label:hover:before {
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: hover-color; }
    .is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before,
    .is-checkradio[type="checkbox"] + label::before,
    .is-checkradio[type="checkbox"] + label:before {
      position: absolute;
      left: 0;
      top: 0rem;
      content: '';
      border: 0.1rem solid #000; }
    .is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after,
    .is-checkradio[type="checkbox"] + label::after,
    .is-checkradio[type="checkbox"] + label:after {
      position: absolute;
      display: none;
      content: '';
      top: 0rem; }
  .is-checkradio[type="radio"].is-rtl + label,
  .is-checkradio[type="checkbox"].is-rtl + label {
    margin-right: 0rem;
    margin-left: 0.5rem; }
    .is-checkradio[type="radio"].is-rtl + label::before, .is-checkradio[type="radio"].is-rtl + label:before,
    .is-checkradio[type="checkbox"].is-rtl + label::before,
    .is-checkradio[type="checkbox"].is-rtl + label:before {
      left: auto;
      right: 0; }
  .is-checkradio[type="radio"]:focus + label::before, .is-checkradio[type="radio"]:focus + label:before,
  .is-checkradio[type="checkbox"]:focus + label::before,
  .is-checkradio[type="checkbox"]:focus + label:before {
    outline: inherit; }
  .is-checkradio[type="radio"]:hover:not([disabled]) + label::before, .is-checkradio[type="radio"]:hover:not([disabled]) + label:before,
  .is-checkradio[type="checkbox"]:hover:not([disabled]) + label::before,
  .is-checkradio[type="checkbox"]:hover:not([disabled]) + label:before {
    border-color: #0070b8 !important; }
  .is-checkradio[type="radio"]:checked + label::before, .is-checkradio[type="radio"]:checked + label:before,
  .is-checkradio[type="checkbox"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label:before {
    border: 0.1rem solid #000; }
  .is-checkradio[type="radio"]:checked[disabled],
  .is-checkradio[type="checkbox"]:checked[disabled] {
    cursor: not-allowed; }
    .is-checkradio[type="radio"]:checked[disabled] + label,
    .is-checkradio[type="checkbox"]:checked[disabled] + label {
      opacity: 0.5; }
  .is-checkradio[type="radio"]:checked + label::before, .is-checkradio[type="radio"]:checked + label:before,
  .is-checkradio[type="checkbox"]:checked + label::before,
  .is-checkradio[type="checkbox"]:checked + label:before {
    animation-name: none; }
  .is-checkradio[type="radio"]:checked + label::after, .is-checkradio[type="radio"]:checked + label:after,
  .is-checkradio[type="checkbox"]:checked + label::after,
  .is-checkradio[type="checkbox"]:checked + label:after {
    display: inline-block; }
  .is-checkradio[type="radio"][disabled],
  .is-checkradio[type="checkbox"][disabled] {
    cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled] + label,
    .is-checkradio[type="checkbox"][disabled] + label {
      opacity: 0.5;
      cursor: not-allowed; }
      .is-checkradio[type="radio"][disabled] + label:hover, .is-checkradio[type="radio"][disabled] + label:before, .is-checkradio[type="radio"][disabled] + label::before, .is-checkradio[type="radio"][disabled] + label::after, .is-checkradio[type="radio"][disabled] + label:after,
      .is-checkradio[type="checkbox"][disabled] + label:hover,
      .is-checkradio[type="checkbox"][disabled] + label:before,
      .is-checkradio[type="checkbox"][disabled] + label::before,
      .is-checkradio[type="checkbox"][disabled] + label::after,
      .is-checkradio[type="checkbox"][disabled] + label:after {
        cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled]:hover,
    .is-checkradio[type="checkbox"][disabled]:hover {
      cursor: not-allowed; }
      .is-checkradio[type="radio"][disabled]:hover::before, .is-checkradio[type="radio"][disabled]:hover:before,
      .is-checkradio[type="checkbox"][disabled]:hover::before,
      .is-checkradio[type="checkbox"][disabled]:hover:before {
        animation-name: none; }
    .is-checkradio[type="radio"][disabled]::before, .is-checkradio[type="radio"][disabled]:before,
    .is-checkradio[type="checkbox"][disabled]::before,
    .is-checkradio[type="checkbox"][disabled]:before {
      cursor: not-allowed; }
    .is-checkradio[type="radio"][disabled]::after, .is-checkradio[type="radio"][disabled]:after,
    .is-checkradio[type="checkbox"][disabled]::after,
    .is-checkradio[type="checkbox"][disabled]:after {
      cursor: not-allowed; }
  .is-checkradio[type="radio"].has-no-border + label::before, .is-checkradio[type="radio"].has-no-border + label:before,
  .is-checkradio[type="checkbox"].has-no-border + label::before,
  .is-checkradio[type="checkbox"].has-no-border + label:before {
    border: none !important; }
  .is-checkradio[type="radio"].is-block,
  .is-checkradio[type="checkbox"].is-block {
    display: none !important; }
    .is-checkradio[type="radio"].is-block + label,
    .is-checkradio[type="checkbox"].is-block + label {
      width: 100% !important;
      background: whitesmoke;
      color: rgba(0, 0, 0, 0.7);
      padding-right: .75em; }
    .is-checkradio[type="radio"].is-block:hover:not([disabled]) + label,
    .is-checkradio[type="checkbox"].is-block:hover:not([disabled]) + label {
      background: #e8e8e8; }

.is-checkradio[type="checkbox"] + label::before, .is-checkradio[type="checkbox"] + label:before {
  border-radius: 4px; }

.is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
  box-sizing: border-box;
  transform: translateY(0rem) rotate(45deg);
  border-width: 3px;
  border-style: solid;
  border-color: #0070b8;
  border-top: 0;
  border-left: 0; }

.is-checkradio[type="checkbox"].is-circle + label::before, .is-checkradio[type="checkbox"].is-circle + label:before {
  border-radius: 50%; }

.is-checkradio[type="checkbox"] + label {
  font-size: 1rem;
  padding-left: 2rem; }
  .is-checkradio[type="checkbox"] + label::before, .is-checkradio[type="checkbox"] + label:before {
    width: 1.5rem;
    height: 1.5rem; }
  .is-checkradio[type="checkbox"] + label::after, .is-checkradio[type="checkbox"] + label:after {
    width: 0.375rem;
    height: 0.6rem;
    top: 0.405rem;
    left: 0.6rem; }

.is-checkradio[type="checkbox"].is-block + label::before, .is-checkradio[type="checkbox"].is-block + label:before {
  width: 1.25rem;
  height: 1.25rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-block + label::after, .is-checkradio[type="checkbox"].is-block + label:after {
  top: 0.325rem;
  left: 0.65rem; }

.is-checkradio[type="checkbox"].is-rtl + label {
  padding-left: 0;
  padding-right: 2rem; }
  .is-checkradio[type="checkbox"].is-rtl + label::after, .is-checkradio[type="checkbox"].is-rtl + label:after {
    left: auto;
    right: 0.6rem; }

.is-checkradio[type="checkbox"].is-small + label {
  font-size: 0.75rem;
  padding-left: 1.5rem; }
  .is-checkradio[type="checkbox"].is-small + label::before, .is-checkradio[type="checkbox"].is-small + label:before {
    width: 1.125rem;
    height: 1.125rem; }
  .is-checkradio[type="checkbox"].is-small + label::after, .is-checkradio[type="checkbox"].is-small + label:after {
    width: 0.28125rem;
    height: 0.45rem;
    top: 0.30375rem;
    left: 0.45rem; }

.is-checkradio[type="checkbox"].is-small.is-block + label::before, .is-checkradio[type="checkbox"].is-small.is-block + label:before {
  width: 0.9375rem;
  height: 0.9375rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-small.is-block + label::after, .is-checkradio[type="checkbox"].is-small.is-block + label:after {
  top: 0.29375rem;
  left: 0.5375rem; }

.is-checkradio[type="checkbox"].is-small.is-rtl + label {
  padding-left: 0;
  padding-right: 1.5rem; }
  .is-checkradio[type="checkbox"].is-small.is-rtl + label::after, .is-checkradio[type="checkbox"].is-small.is-rtl + label:after {
    left: auto;
    right: 0.45rem; }

.is-checkradio[type="checkbox"].is-medium + label {
  font-size: 1.333rem;
  padding-left: 2.666rem; }
  .is-checkradio[type="checkbox"].is-medium + label::before, .is-checkradio[type="checkbox"].is-medium + label:before {
    width: 1.9995rem;
    height: 1.9995rem; }
  .is-checkradio[type="checkbox"].is-medium + label::after, .is-checkradio[type="checkbox"].is-medium + label:after {
    width: 0.49987rem;
    height: 0.7998rem;
    top: 0.53987rem;
    left: 0.7998rem; }

.is-checkradio[type="checkbox"].is-medium.is-block + label::before, .is-checkradio[type="checkbox"].is-medium.is-block + label:before {
  width: 1.66625rem;
  height: 1.66625rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-medium.is-block + label::after, .is-checkradio[type="checkbox"].is-medium.is-block + label:after {
  top: 0.36662rem;
  left: 0.79985rem; }

.is-checkradio[type="checkbox"].is-medium.is-rtl + label {
  padding-left: 0;
  padding-right: 2.666rem; }
  .is-checkradio[type="checkbox"].is-medium.is-rtl + label::after, .is-checkradio[type="checkbox"].is-medium.is-rtl + label:after {
    left: auto;
    right: 0.7998rem; }

.is-checkradio[type="checkbox"].is-large + label {
  font-size: 1.777rem;
  padding-left: 3.554rem; }
  .is-checkradio[type="checkbox"].is-large + label::before, .is-checkradio[type="checkbox"].is-large + label:before {
    width: 2.6655rem;
    height: 2.6655rem; }
  .is-checkradio[type="checkbox"].is-large + label::after, .is-checkradio[type="checkbox"].is-large + label:after {
    width: 0.66637rem;
    height: 1.0662rem;
    top: 0.71969rem;
    left: 1.0662rem; }

.is-checkradio[type="checkbox"].is-large.is-block + label::before, .is-checkradio[type="checkbox"].is-large.is-block + label:before {
  width: 2.22125rem;
  height: 2.22125rem;
  left: .175rem;
  top: .175rem; }

.is-checkradio[type="checkbox"].is-large.is-block + label::after, .is-checkradio[type="checkbox"].is-large.is-block + label:after {
  top: 0.42212rem;
  left: 0.99965rem; }

.is-checkradio[type="checkbox"].is-large.is-rtl + label {
  padding-left: 0;
  padding-right: 3.554rem; }
  .is-checkradio[type="checkbox"].is-large.is-rtl + label::after, .is-checkradio[type="checkbox"].is-large.is-rtl + label:after {
    left: auto;
    right: 1.0662rem; }

.is-checkradio[type="checkbox"].is-white.has-background-color + label::before, .is-checkradio[type="checkbox"].is-white.has-background-color + label:before {
  border-color: transparent !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked + label::after, .is-checkradio[type="checkbox"].is-white:checked + label:after {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-white:checked.has-background-color + label:after {
  border-color: #0a0a0a !important;
  background-color: white !important; }

.is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white.is-block:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="checkbox"].is-white.is-block:checked + label {
  color: #0a0a0a;
  border-color: white !important;
  background: white; }
  .is-checkradio[type="checkbox"].is-white.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-white.is-block:checked + label:after {
    border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label {
  background: #f2f2f2; }
  .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-white.is-block:checked:hover:not([disabled]) + label:before {
    border-color: black !important; }

.is-checkradio[type="checkbox"].is-black.has-background-color + label::before, .is-checkradio[type="checkbox"].is-black.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked + label::after, .is-checkradio[type="checkbox"].is-black:checked + label:after {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-black:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-black:checked.has-background-color + label:after {
  border-color: white !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black.is-block:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="checkbox"].is-black.is-block:checked + label {
  color: white;
  border-color: #0a0a0a !important;
  background: #0a0a0a; }
  .is-checkradio[type="checkbox"].is-black.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-black.is-block:checked + label:after {
    border-color: white !important; }

.is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label {
  background: black; }
  .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-black.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-light.has-background-color + label::before, .is-checkradio[type="checkbox"].is-light.has-background-color + label:before {
  border-color: transparent !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked + label::after, .is-checkradio[type="checkbox"].is-light:checked + label:after {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-light:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light.is-block:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="checkbox"].is-light.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: whitesmoke !important;
  background: whitesmoke; }
  .is-checkradio[type="checkbox"].is-light.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-light.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label {
  background: #e8e8e8; }
  .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-light.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-dark.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark:hover:not([disabled]) + label:before {
  border-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark:checked + label::after, .is-checkradio[type="checkbox"].is-dark:checked + label:after {
  border-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark.is-block:hover:not([disabled]) + label:before {
  border-color: #363636 !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:checked + label {
  color: #fff;
  border-color: #363636 !important;
  background: #363636; }
  .is-checkradio[type="checkbox"].is-dark.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label {
  background: #292929; }
  .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-dark.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-primary.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary:hover:not([disabled]) + label:before {
  border-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary:checked + label::after, .is-checkradio[type="checkbox"].is-primary:checked + label:after {
  border-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-primary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary.is-block:hover:not([disabled]) + label:before {
  border-color: #0070b8 !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:checked + label {
  color: #fff;
  border-color: #0070b8 !important;
  background: #0070b8; }
  .is-checkradio[type="checkbox"].is-primary.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label {
  background: #00609f; }
  .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-link.has-background-color + label::before, .is-checkradio[type="checkbox"].is-link.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked + label::after, .is-checkradio[type="checkbox"].is-link:checked + label:after {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-link:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-link:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link.is-block:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="checkbox"].is-link.is-block:checked + label {
  color: #fff;
  border-color: #485fc7 !important;
  background: #485fc7; }
  .is-checkradio[type="checkbox"].is-link.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-link.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label {
  background: #3a51bb; }
  .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-link.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-info.has-background-color + label::before, .is-checkradio[type="checkbox"].is-info.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked + label::after, .is-checkradio[type="checkbox"].is-info:checked + label:after {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-info:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-info:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info.is-block:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="checkbox"].is-info.is-block:checked + label {
  color: #fff;
  border-color: #3e8ed0 !important;
  background: #3e8ed0; }
  .is-checkradio[type="checkbox"].is-info.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-info.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label {
  background: #3082c5; }
  .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-info.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-success.has-background-color + label::before, .is-checkradio[type="checkbox"].is-success.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success:hover:not([disabled]) + label:before {
  border-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success:checked + label::after, .is-checkradio[type="checkbox"].is-success:checked + label:after {
  border-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-success:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-success:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success.is-block:hover:not([disabled]) + label:before {
  border-color: #63aa16 !important; }

.is-checkradio[type="checkbox"].is-success.is-block:checked + label {
  color: #fff;
  border-color: #63aa16 !important;
  background: #63aa16; }
  .is-checkradio[type="checkbox"].is-success.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-success.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label {
  background: #569313; }
  .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-success.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-warning.has-background-color + label::before, .is-checkradio[type="checkbox"].is-warning.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked + label::after, .is-checkradio[type="checkbox"].is-warning:checked + label:after {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-warning:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning.is-block:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #ffe08a !important;
  background: #ffe08a; }
  .is-checkradio[type="checkbox"].is-warning.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label {
  background: #ffd970; }
  .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-warning.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-danger.has-background-color + label::before, .is-checkradio[type="checkbox"].is-danger.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger:hover:not([disabled]) + label:before {
  border-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger:checked + label::after, .is-checkradio[type="checkbox"].is-danger:checked + label:after {
  border-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-danger:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger.is-block:hover:not([disabled]) + label:before {
  border-color: #d0021b !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:checked + label {
  color: #fff;
  border-color: #d0021b !important;
  background: #d0021b; }
  .is-checkradio[type="checkbox"].is-danger.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label {
  background: #b70218; }
  .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-danger.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-primary-light.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-light.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-light:hover:not([disabled]) + label:before {
  border-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light:checked + label::after, .is-checkradio[type="checkbox"].is-primary-light:checked + label:after {
  border-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-primary-light:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-primary-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-light.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-light.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-light.is-block:hover:not([disabled]) + label:before {
  border-color: #cce2f1 !important; }

.is-checkradio[type="checkbox"].is-primary-light.is-block:checked + label {
  color: rgba(0, 0, 0, 0.7);
  border-color: #cce2f1 !important;
  background: #cce2f1; }
  .is-checkradio[type="checkbox"].is-primary-light.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-primary-light.is-block:checked + label:after {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-primary-light.is-block:checked:hover:not([disabled]) + label {
  background: #b8d7ec; }
  .is-checkradio[type="checkbox"].is-primary-light.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-primary-light.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-primary-light.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-primary-light.is-block:checked:hover:not([disabled]) + label:before {
    border-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="checkbox"].is-secondary.has-background-color + label::before, .is-checkradio[type="checkbox"].is-secondary.has-background-color + label:before {
  border-color: transparent !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary:hover:not([disabled]) + label:before {
  border-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary:checked + label::after, .is-checkradio[type="checkbox"].is-secondary:checked + label:after {
  border-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-secondary:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-secondary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-secondary.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-secondary.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary.is-block:hover:not([disabled]) + label:before {
  border-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="checkbox"].is-secondary.is-block:checked + label {
  color: #fff;
  border-color: rgba(0, 112, 184, 0.4) !important;
  background: rgba(0, 112, 184, 0.4); }
  .is-checkradio[type="checkbox"].is-secondary.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-secondary.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-secondary.is-block:checked:hover:not([disabled]) + label {
  background: rgba(0, 96, 159, 0.4); }
  .is-checkradio[type="checkbox"].is-secondary.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-secondary.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-secondary.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"].is-secondary-dark.has-background-color + label::before, .is-checkradio[type="checkbox"].is-secondary-dark.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary-dark:hover:not([disabled]) + label:before {
  border-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark:checked + label::after, .is-checkradio[type="checkbox"].is-secondary-dark:checked + label:after {
  border-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark:checked.has-background-color + label::before, .is-checkradio[type="checkbox"].is-secondary-dark:checked.has-background-color + label:before {
  border-color: transparent !important;
  background-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark:checked.has-background-color + label::after, .is-checkradio[type="checkbox"].is-secondary-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark.is-block:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:hover:not([disabled]) + label:before {
  border-color: #01375d !important; }

.is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked + label {
  color: #fff;
  border-color: #01375d !important;
  background: #01375d; }
  .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked + label::after, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked + label:after {
    border-color: #fff !important; }

.is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked:hover:not([disabled]) + label {
  background: #012844; }
  .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked:hover:not([disabled]) + label::after, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked:hover:not([disabled]) + label:after, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked:hover:not([disabled]) + label::before, .is-checkradio[type="checkbox"].is-secondary-dark.is-block:checked:hover:not([disabled]) + label:before {
    border-color: #f2f2f2 !important; }

.is-checkradio[type="checkbox"]:indeterminate + label::after, .is-checkradio[type="checkbox"]:indeterminate + label:after {
  display: inline-block;
  transform: rotate(90deg);
  border-bottom: none; }

.is-checkradio[type="checkbox"]:indeterminate.is-white + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-white + label:after {
  border-color: white; }

.is-checkradio[type="checkbox"]:indeterminate.is-black + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-black + label:after {
  border-color: #0a0a0a; }

.is-checkradio[type="checkbox"]:indeterminate.is-light + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-light + label:after {
  border-color: whitesmoke; }

.is-checkradio[type="checkbox"]:indeterminate.is-dark + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-dark + label:after {
  border-color: #363636; }

.is-checkradio[type="checkbox"]:indeterminate.is-primary + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-primary + label:after {
  border-color: #0070b8; }

.is-checkradio[type="checkbox"]:indeterminate.is-link + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-link + label:after {
  border-color: #485fc7; }

.is-checkradio[type="checkbox"]:indeterminate.is-info + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-info + label:after {
  border-color: #3e8ed0; }

.is-checkradio[type="checkbox"]:indeterminate.is-success + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-success + label:after {
  border-color: #63aa16; }

.is-checkradio[type="checkbox"]:indeterminate.is-warning + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-warning + label:after {
  border-color: #ffe08a; }

.is-checkradio[type="checkbox"]:indeterminate.is-danger + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-danger + label:after {
  border-color: #d0021b; }

.is-checkradio[type="checkbox"]:indeterminate.is-primary-light + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-primary-light + label:after {
  border-color: #cce2f1; }

.is-checkradio[type="checkbox"]:indeterminate.is-secondary + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-secondary + label:after {
  border-color: rgba(0, 112, 184, 0.4); }

.is-checkradio[type="checkbox"]:indeterminate.is-secondary-dark + label::after, .is-checkradio[type="checkbox"]:indeterminate.is-secondary-dark + label:after {
  border-color: #01375d; }

.is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before {
  border-radius: 50%; }

.is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
  border-radius: 50%;
  background: #0070b8;
  left: 0;
  transform: scale(0.5); }

.is-checkradio[type="radio"]:checked.has-background-color + label::before, .is-checkradio[type="radio"]:checked.has-background-color + label:before {
  border-color: #4a4a4a !important;
  background-color: #4a4a4a !important; }

.is-checkradio[type="radio"]:checked.has-background-color + label::after, .is-checkradio[type="radio"]:checked.has-background-color + label:after {
  border-color: #4a4a4a !important;
  background-color: #4a4a4a !important; }

.is-checkradio[type="radio"].is-rtl + label {
  padding-left: 0; }
  .is-checkradio[type="radio"].is-rtl + label::after, .is-checkradio[type="radio"].is-rtl + label:after {
    left: auto;
    right: 0; }

.is-checkradio[type="radio"] + label {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 2rem; }
  .is-checkradio[type="radio"] + label::before, .is-checkradio[type="radio"] + label:before, .is-checkradio[type="radio"] + label::after, .is-checkradio[type="radio"] + label:after {
    width: 1.5rem;
    height: 1.5rem; }

.is-checkradio[type="radio"].is-rtl + label {
  padding-right: 2rem; }

.is-checkradio[type="radio"].is-small + label {
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-left: 1.5rem; }
  .is-checkradio[type="radio"].is-small + label::before, .is-checkradio[type="radio"].is-small + label:before, .is-checkradio[type="radio"].is-small + label::after, .is-checkradio[type="radio"].is-small + label:after {
    width: 1.125rem;
    height: 1.125rem; }

.is-checkradio[type="radio"].is-small.is-rtl + label {
  padding-right: 1.5rem; }

.is-checkradio[type="radio"].is-medium + label {
  font-size: 1.333rem;
  line-height: 1.9995rem;
  padding-left: 2.666rem; }
  .is-checkradio[type="radio"].is-medium + label::before, .is-checkradio[type="radio"].is-medium + label:before, .is-checkradio[type="radio"].is-medium + label::after, .is-checkradio[type="radio"].is-medium + label:after {
    width: 1.9995rem;
    height: 1.9995rem; }

.is-checkradio[type="radio"].is-medium.is-rtl + label {
  padding-right: 2.666rem; }

.is-checkradio[type="radio"].is-large + label {
  font-size: 1.777rem;
  line-height: 2.6655rem;
  padding-left: 3.554rem; }
  .is-checkradio[type="radio"].is-large + label::before, .is-checkradio[type="radio"].is-large + label:before, .is-checkradio[type="radio"].is-large + label::after, .is-checkradio[type="radio"].is-large + label:after {
    width: 2.6655rem;
    height: 2.6655rem; }

.is-checkradio[type="radio"].is-large.is-rtl + label {
  padding-right: 3.554rem; }

.is-checkradio[type="radio"].is-white.has-background-color + label::before, .is-checkradio[type="radio"].is-white.has-background-color + label:before {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-white:hover:not([disabled]) + label:before {
  border-color: white !important; }

.is-checkradio[type="radio"].is-white:checked + label::after, .is-checkradio[type="radio"].is-white:checked + label:after {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-white:checked.has-background-color + label:before {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-white:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-white:checked.has-background-color + label:after {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black.has-background-color + label::before, .is-checkradio[type="radio"].is-black.has-background-color + label:before {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-black:hover:not([disabled]) + label:before {
  border-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked + label::after, .is-checkradio[type="radio"].is-black:checked + label:after {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-black:checked.has-background-color + label:before {
  border-color: #0a0a0a !important;
  background-color: #0a0a0a !important; }

.is-checkradio[type="radio"].is-black:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-black:checked.has-background-color + label:after {
  border-color: white !important;
  background-color: white !important; }

.is-checkradio[type="radio"].is-light.has-background-color + label::before, .is-checkradio[type="radio"].is-light.has-background-color + label:before {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-light:hover:not([disabled]) + label:before {
  border-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked + label::after, .is-checkradio[type="radio"].is-light:checked + label:after {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-light:checked.has-background-color + label:before {
  border-color: whitesmoke !important;
  background-color: whitesmoke !important; }

.is-checkradio[type="radio"].is-light:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-dark.has-background-color + label::before, .is-checkradio[type="radio"].is-dark.has-background-color + label:before {
  border-color: #363636 !important;
  background-color: #363636 !important; }

.is-checkradio[type="radio"].is-dark:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-dark:hover:not([disabled]) + label:before {
  border-color: #363636 !important; }

.is-checkradio[type="radio"].is-dark:checked + label::after, .is-checkradio[type="radio"].is-dark:checked + label:after {
  border-color: #363636 !important;
  background-color: #363636 !important; }

.is-checkradio[type="radio"].is-dark:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-dark:checked.has-background-color + label:before {
  border-color: #363636 !important;
  background-color: #363636 !important; }

.is-checkradio[type="radio"].is-dark:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-primary.has-background-color + label::before, .is-checkradio[type="radio"].is-primary.has-background-color + label:before {
  border-color: #0070b8 !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="radio"].is-primary:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-primary:hover:not([disabled]) + label:before {
  border-color: #0070b8 !important; }

.is-checkradio[type="radio"].is-primary:checked + label::after, .is-checkradio[type="radio"].is-primary:checked + label:after {
  border-color: #0070b8 !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="radio"].is-primary:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-primary:checked.has-background-color + label:before {
  border-color: #0070b8 !important;
  background-color: #0070b8 !important; }

.is-checkradio[type="radio"].is-primary:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-primary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-link.has-background-color + label::before, .is-checkradio[type="radio"].is-link.has-background-color + label:before {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-link:hover:not([disabled]) + label:before {
  border-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked + label::after, .is-checkradio[type="radio"].is-link:checked + label:after {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-link:checked.has-background-color + label:before {
  border-color: #485fc7 !important;
  background-color: #485fc7 !important; }

.is-checkradio[type="radio"].is-link:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-link:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-info.has-background-color + label::before, .is-checkradio[type="radio"].is-info.has-background-color + label:before {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-info:hover:not([disabled]) + label:before {
  border-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked + label::after, .is-checkradio[type="radio"].is-info:checked + label:after {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-info:checked.has-background-color + label:before {
  border-color: #3e8ed0 !important;
  background-color: #3e8ed0 !important; }

.is-checkradio[type="radio"].is-info:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-info:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-success.has-background-color + label::before, .is-checkradio[type="radio"].is-success.has-background-color + label:before {
  border-color: #63aa16 !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="radio"].is-success:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-success:hover:not([disabled]) + label:before {
  border-color: #63aa16 !important; }

.is-checkradio[type="radio"].is-success:checked + label::after, .is-checkradio[type="radio"].is-success:checked + label:after {
  border-color: #63aa16 !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="radio"].is-success:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-success:checked.has-background-color + label:before {
  border-color: #63aa16 !important;
  background-color: #63aa16 !important; }

.is-checkradio[type="radio"].is-success:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-success:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-warning.has-background-color + label::before, .is-checkradio[type="radio"].is-warning.has-background-color + label:before {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-warning:hover:not([disabled]) + label:before {
  border-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked + label::after, .is-checkradio[type="radio"].is-warning:checked + label:after {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-warning:checked.has-background-color + label:before {
  border-color: #ffe08a !important;
  background-color: #ffe08a !important; }

.is-checkradio[type="radio"].is-warning:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-warning:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-danger.has-background-color + label::before, .is-checkradio[type="radio"].is-danger.has-background-color + label:before {
  border-color: #d0021b !important;
  background-color: #d0021b !important; }

.is-checkradio[type="radio"].is-danger:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-danger:hover:not([disabled]) + label:before {
  border-color: #d0021b !important; }

.is-checkradio[type="radio"].is-danger:checked + label::after, .is-checkradio[type="radio"].is-danger:checked + label:after {
  border-color: #d0021b !important;
  background-color: #d0021b !important; }

.is-checkradio[type="radio"].is-danger:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-danger:checked.has-background-color + label:before {
  border-color: #d0021b !important;
  background-color: #d0021b !important; }

.is-checkradio[type="radio"].is-danger:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-danger:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-primary-light.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-light.has-background-color + label:before {
  border-color: #cce2f1 !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="radio"].is-primary-light:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-primary-light:hover:not([disabled]) + label:before {
  border-color: #cce2f1 !important; }

.is-checkradio[type="radio"].is-primary-light:checked + label::after, .is-checkradio[type="radio"].is-primary-light:checked + label:after {
  border-color: #cce2f1 !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="radio"].is-primary-light:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-primary-light:checked.has-background-color + label:before {
  border-color: #cce2f1 !important;
  background-color: #cce2f1 !important; }

.is-checkradio[type="radio"].is-primary-light:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-primary-light:checked.has-background-color + label:after {
  border-color: rgba(0, 0, 0, 0.7) !important;
  background-color: rgba(0, 0, 0, 0.7) !important; }

.is-checkradio[type="radio"].is-secondary.has-background-color + label::before, .is-checkradio[type="radio"].is-secondary.has-background-color + label:before {
  border-color: rgba(0, 112, 184, 0.4) !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="radio"].is-secondary:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-secondary:hover:not([disabled]) + label:before {
  border-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="radio"].is-secondary:checked + label::after, .is-checkradio[type="radio"].is-secondary:checked + label:after {
  border-color: rgba(0, 112, 184, 0.4) !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="radio"].is-secondary:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-secondary:checked.has-background-color + label:before {
  border-color: rgba(0, 112, 184, 0.4) !important;
  background-color: rgba(0, 112, 184, 0.4) !important; }

.is-checkradio[type="radio"].is-secondary:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-secondary:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

.is-checkradio[type="radio"].is-secondary-dark.has-background-color + label::before, .is-checkradio[type="radio"].is-secondary-dark.has-background-color + label:before {
  border-color: #01375d !important;
  background-color: #01375d !important; }

.is-checkradio[type="radio"].is-secondary-dark:hover:not([disabled]) + label::before, .is-checkradio[type="radio"].is-secondary-dark:hover:not([disabled]) + label:before {
  border-color: #01375d !important; }

.is-checkradio[type="radio"].is-secondary-dark:checked + label::after, .is-checkradio[type="radio"].is-secondary-dark:checked + label:after {
  border-color: #01375d !important;
  background-color: #01375d !important; }

.is-checkradio[type="radio"].is-secondary-dark:checked.has-background-color + label::before, .is-checkradio[type="radio"].is-secondary-dark:checked.has-background-color + label:before {
  border-color: #01375d !important;
  background-color: #01375d !important; }

.is-checkradio[type="radio"].is-secondary-dark:checked.has-background-color + label::after, .is-checkradio[type="radio"].is-secondary-dark:checked.has-background-color + label:after {
  border-color: #fff !important;
  background-color: #fff !important; }

html {
  scroll-behavior: smooth; }

body {
  line-height: 1.5;
  overflow-x: hidden; }

p {
  font-weight: 300;
  font-size: 1.125rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2; }

h1 {
  font-size: 1.777rem;
  font-weight: 300; }
  @media screen and (min-width: 769px), print {
    h1 {
      font-size: 2.369rem; } }
  @media screen and (min-width: 1024px) {
    h1 {
      font-size: 3.157rem; } }

h2 {
  font-size: 2.369rem;
  font-weight: 300; }
  h2.p {
    font-size: 1.333rem;
    text-transform: uppercase;
    font-weight: 600; }
  h2.fons-color {
    background: #f2f2f2;
    padding: 0.75rem 3.157rem;
    font-weight: 300 !important; }
    @media screen and (min-width: 1024px) {
      h2.fons-color {
        position: relative;
        font-weight: normal; }
        h2.fons-color::after {
          content: "";
          display: block;
          position: absolute;
          left: 100%;
          right: -200%;
          top: 0;
          bottom: 0;
          width: 200%;
          background-color: inherit; } }

h3 {
  font-size: 1.777rem;
  font-weight: 600; }
  h3.f {
    background: #f2f2f2;
    padding: 0.75rem 3.157rem;
    font-weight: normal; }
    @media screen and (min-width: 1024px) {
      h3.f {
        position: relative; }
        h3.f::after {
          content: "";
          display: block;
          position: absolute;
          left: 100%;
          right: -200%;
          top: 0;
          bottom: 0;
          width: 200%;
          background-color: inherit; } }

h4 {
  font-size: 1.333rem; }

h5 {
  font-size: 1.333rem;
  font-weight: 600;
  margin: 0; }

h6 {
  font-size: 1rem;
  margin: 0; }
  h6:not(:first-child) {
    margin-top: 3.157rem !important; }

ul,
ol {
  margin: 0; }

ul {
  margin-left: 0;
  padding-left: 0; }
  @media screen and (min-width: 769px), print {
    ul {
      margin-left: 2.369rem; } }
  ul > * + * {
    margin-top: 1rem; }
  ul li {
    position: relative;
    padding: 0 0 1rem 1.777rem;
    list-style: none;
    border-bottom: solid 1px #d5d5d5; }
    ul li::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #0070b8;
      left: 0;
      top: 0.4em; }
    ul li ul {
      padding: 0.75rem 0 0 0;
      margin-left: 0; }
      ul li ul > * + * {
        margin-top: 1.333rem; }
      ul li ul li {
        padding: 0 0 0 1.333rem;
        margin-bottom: 0;
        border-bottom: 0;
        font-weight: normal; }
        ul li ul li::before {
          width: 8px;
          height: 8px;
          top: 0.5em; }
        ul li ul li ul li {
          font-weight: 300;
          padding: 0 0 0 1rem; }
          ul li ul li ul li::before {
            width: 4px;
            height: 4px;
            top: 0.6em; }

ol {
  padding-left: 0;
  /*list-style-position: inside;*/ }
  @media screen and (min-width: 769px), print {
    ol {
      margin-left: 2.369rem; } }
  ol > * + * {
    margin-top: 1rem; }
  ol li {
    padding: 0; }
    ol li::marker {
      color: #0070b8;
      font-size: 1.333rem;
      font-weight: 300; }

a {
  color: #0070b8;
  transition: text-decoration-color 0.1s ease-in; }
  a:hover {
    text-decoration-color: transparent; }

hr {
  border: 0;
  border-top: 1px solid #333; }

address {
  font-style: normal; }

abbr {
  text-decoration: none; }

strong {
  font-weight: bold; }

em {
  font-style: italic; }

em > strong,
strong > em {
  font-weight: bold;
  font-style: italic; }

figcaption {
  font-size: 0.75rem;
  margin-top: 0.75rem; }

img {
  height: auto; }

fieldset {
  border: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0; }

legend {
  font-size: 1.777rem; }

[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
  display: none; }

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.gm-style [aria-hidden="true"],
.slider [aria-hidden="true"],
.icon[aria-hidden="true"] {
  /* 1 */
  display: block;
  /* 2 */
  display: initial; }

table {
  border-spacing: 10px;
  margin: -10px; }
  table tbody tr:nth-child(odd) th,
  table tbody tr:nth-child(odd) td {
    background-color: #f2f2f2; }
  table tbody tr td {
    padding: 1rem; }
  table tbody tr th {
    padding: 1rem;
    text-align: left; }
  table thead tr th {
    padding: 1rem;
    text-align: left;
    background-color: #0070b8;
    color: white;
    font-weight: normal; }
    table thead tr th:not(:first-child) {
      color: #0a0a0a;
      background-color: #cce2f1; }

.noticies time {
  font-weight: bold;
  position: relative;
  margin-bottom: 1.333rem;
  display: block; }
  .noticies time::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 0.1px;
    background: black;
    bottom: -10px; }

iframe {
  border: none; }

.icon-download {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px 0px;
  width: 30px;
  height: 20px; }

.icon-enllacos-pdf {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px -20px;
  width: 22px;
  height: 23px; }

.icon-enllacos {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -64px -40px;
  width: 11px;
  height: 18px; }

.icon-link {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px -43px;
  width: 22px;
  height: 23px; }

.icon-menu-small {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: 0px 0px;
  width: 40px;
  height: 40px; }

.logo-linkedin {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: 0px -40px;
  width: 32px;
  height: 32px; }

.logo-twitter {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -32px -40px;
  width: 32px;
  height: 32px; }

.top {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -40px 0px;
  width: 43px;
  height: 24px; }

.icon-download {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px 0px;
  width: 30px;
  height: 20px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-download {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.icon-enllacos-pdf {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px -20px;
  width: 22px;
  height: 23px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-enllacos-pdf {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.icon-enllacos {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -64px -40px;
  width: 11px;
  height: 18px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-enllacos {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.icon-link {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -83px -43px;
  width: 22px;
  height: 23px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-link {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.icon-menu-small {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: 0px 0px;
  width: 40px;
  height: 40px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-menu-small {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.logo-linkedin {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: 0px -40px;
  width: 32px;
  height: 32px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .logo-linkedin {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.logo-twitter {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -32px -40px;
  width: 32px;
  height: 32px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .logo-twitter {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.top {
  background-image: url(img/spritesheet.png?1603949503088);
  background-position: -40px 0px;
  width: 43px;
  height: 24px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .top {
      background-image: url(img/spritesheet@2x.png?1603949503088);
      background-size: 113px 72px; } }

.columns2 > .column > * + *,
.columns3 > .column > * + * {
  margin-top: 1.333rem; }

.columns:not(:last-child) {
  margin-bottom: 0; }

.sidebar-left {
  display: flex;
  flex-wrap: wrap;
  gap: 1.333rem; }
  .sidebar-left > :first-child {
    flex-grow: 1;
    flex-basis: 25rem; }
  .sidebar-left > :last-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%; }
    .sidebar-left > :last-child > * + * {
      margin-top: 1rem; }

.sidebar-right {
  display: flex;
  flex-wrap: wrap;
  gap: 1.333rem; }
  .sidebar-right > :first-child {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 50%; }
    .sidebar-right > :first-child > * + * {
      margin-top: 1rem; }
  .sidebar-right > :last-child {
    flex-grow: 1;
    flex-basis: 25rem; }

.back {
  color: #000;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none; }
  .back:hover {
    text-decoration: underline; }

.banner-treballa {
  text-align: center; }
  .banner-treballa a {
    display: block;
    color: white;
    text-decoration: none;
    font-size: 1.333rem;
    padding: 1.333rem;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    font-weight: 600; }
    .banner-treballa a:hover:before {
      background-color: rgba(16, 113, 181, 0.6);
      transition: background-color .2s ease-in; }
    .banner-treballa a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      mix-blend-mode: multiply;
      background-color: rgba(16, 113, 181, 0.8); }
    .banner-treballa a span {
      position: relative; }

.flow--5 .banner-treballa {
  margin-top: 1.333rem; }

.box {
  height: 100%;
  background-color: #f2f2f2;
  padding: 1.333rem; }

.download-doc {
  border: 1px solid #000;
  padding: 1rem 3.157rem 1rem 1.777rem;
  border-radius: 34px;
  position: relative;
  display: block;
  font-size: 1rem; }
  @media screen and (max-width: 768px) {
    .download-doc {
      width: auto; } }
  .download-doc:before {
    content: '';
    background-image: url("img/data-transfer-down.svg");
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    right: 1rem; }
  .download-doc a {
    text-decoration: none; }
    .download-doc a:hover {
      text-decoration: underline; }

.flow > * + * {
  margin-top: 1rem; }

.flow--1 > * + * {
  margin-top: 0.75rem; }

.flow--3 > * + * {
  margin-top: 1.333rem; }

.flow--4 > * + * {
  margin-top: 1.777rem; }

.flow--5 > * + * {
  margin-top: 2.369rem; }

.flow--6 > * + * {
  margin-top: 3.157rem; }

.flow--7 > * + * {
  margin-top: 4.209rem; }

.gmaps {
  background-image: url("img/imatge-gmaps.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 255px;
  height: 255px;
  margin-top: 1.333rem; }
  .gmaps p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 70%; }
    .gmaps p.button {
      background: transparent;
      border-color: white; }
      .gmaps p.button a {
        text-decoration: none;
        color: white; }
        .gmaps p.button a:hover {
          color: #0070b8; }
      .gmaps p.button:hover {
        background: white; }

.highlighted-link {
  border: 1px solid #000;
  padding: 1rem 3.157rem;
  border-radius: 34px;
  position: relative;
  display: block;
  font-size: 1rem; }
  @media screen and (max-width: 768px) {
    .highlighted-link {
      width: auto; } }
  .highlighted-link:before {
    content: '';
    background-image: url("img/link-alt.svg");
    width: 24px;
    height: 12px;
    display: inline-block;
    position: absolute;
    left: 1rem;
    top: 22px; }
  .highlighted-link a {
    text-decoration: none; }
    .highlighted-link a:hover {
      text-decoration: underline; }

.highlighted-p-1 {
  border-left: 3px solid #979797;
  padding-left: 1.777rem;
  max-width: 50ch;
  font-size: 1.333rem; }

.highlighted-p-2 {
  border-top: 3px solid #979797;
  border-bottom: 3px solid #979797;
  padding: 1.777rem 0;
  font-size: 1.333rem; }

.highlighted-p-3 {
  padding: 1.777rem;
  background-color: #f2f2f2; }

.link-dn,
.link-dn a {
  text-decoration-color: transparent; }
  .link-dn:hover,
  .link-dn a:hover {
    text-decoration-color: inherit; }

.module > * + * {
  margin-top: 1.333rem; }

.list-noborder {
  margin-left: 1.777rem; }
  .list-noborder li {
    border: 0; }

.list-nocercle li {
  padding-left: 0 !important;
  margin-top: 0 !important; }
  .list-nocercle li:before {
    display: none; }

.no-deco {
  text-decoration: none; }
  .no-deco:hover {
    text-decoration: underline; }
  .no-deco--black {
    color: black; }

.dnone {
  display: none; }

.radius {
  border-radius: 4px; }

ul.rel-docs {
  margin-left: 0; }
  ul.rel-docs > * + * {
    margin-top: 1rem; }
  ul.rel-docs li {
    border: 1px solid #000;
    padding: 1rem 3.157rem 1rem 1.777rem;
    border-radius: 28px;
    position: relative;
    display: block;
    font-weight: normal; }
    @media screen and (max-width: 768px) {
      ul.rel-docs li {
        width: auto; } }
    .has-background-primary-light ul.rel-docs li {
      background-color: #fff; }
    ul.rel-docs li:after {
      content: "";
      background-image: url("img/data-transfer-down.svg");
      width: 24px;
      height: 24px;
      display: inline-block;
      position: absolute;
      right: 1rem; }
    ul.rel-docs li a {
      text-decoration: none; }
      ul.rel-docs li a:hover {
        text-decoration: underline; }
    ul.rel-docs li:before {
      content: none; }

.rounded {
  border-radius: 50%; }

.social-follow {
  gap: 1.333rem;
  max-width: 200px; }

@media screen and (min-width: 769px), print {
  .text-columns {
    column-count: 2;
    column-gap: 1.777rem; } }

.text-columns > * + * {
  margin-top: 1rem; }

.unflow > * + * {
  margin-top: 0 !important; }

.unstyle-list {
  margin-left: 0; }
  .unstyle-list > * + * {
    margin-top: 1.777rem; }
  .unstyle-list li {
    padding: 0;
    border-bottom: 0; }
    .unstyle-list li:before {
      display: none; }

.unstyle ul {
  margin-right: 80px;
  margin-left: 0; }
  .unstyle ul li {
    padding-left: 0; }
    .unstyle ul li:before {
      display: none; }
    .unstyle ul li a {
      text-decoration: none; }
      .unstyle ul li a:hover {
        text-decoration: underline; }

.wrapper {
  /*max-width: 80rem;*/
  max-width: 98.125rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.777rem;
  padding-right: 1.777rem; }
  .wrapper--narrow {
    max-width: 60rem; }

address {
  color: #0070b8;
  font-style: normal; }

.blau {
  color: #0070b8; }

.avis {
  background: #fffbcc;
  padding: 2.369rem 4.209rem;
  text-align: center; }
  .avis .button {
    margin-top: 1.333rem;
    background: transparent;
    border-color: #0070b8 !important;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    padding-left: 1.777rem;
    padding-right: 1.777rem; }
    .avis .button a {
      text-decoration: none; }
    .avis .button:hover {
      background: #0070b8; }
      .avis .button:hover a {
        color: white; }

.box-extra h2 a {
  text-decoration: none; }
  .box-extra h2 a:hover {
    background-color: #cce2f169; }

/* Override Bulma icon margin styles */
.button .icon:first-child {
  margin: 0 0.5em 0 0 !important; }

.button {
  border-radius: 24px;
  text-decoration: none;
  font-weight: 600; }
  .button a {
    /*padding-left: $size-3;
        padding-right: $size-3;*/
    text-decoration: none; }

.button.is-primary a {
  color: white;
  text-decoration: none; }

.button.is-outlined {
  color: #0070b8;
  background-color: transparent;
  border-color: #423737; }
  .button.is-outlined a {
    color: #0070b8;
    text-decoration: none; }
  .button.is-outlined:hover {
    background-color: #0070b8;
    transition: 0.2s ease all;
    color: white; }
    .button.is-outlined:hover a {
      color: white; }

.button.is-inverted {
  background-color: transparent; }

.breadcrumb,
.breadcrumbs {
  margin-left: 5px !important;
  margin-bottom: 0.75rem; }
  .breadcrumb li,
  .breadcrumbs li {
    display: inline;
    padding: 0 1.333rem 0 0;
    border: 0;
    font-size: 0.75rem; }
    .breadcrumb li a,
    .breadcrumbs li a {
      text-decoration: none;
      color: #000; }
      .breadcrumb li a:hover,
      .breadcrumbs li a:hover {
        text-decoration: underline; }
      .breadcrumb li a:last-child,
      .breadcrumbs li a:last-child {
        text-transform: none; }
    .breadcrumb li:before,
    .breadcrumbs li:before {
      content: ">";
      margin: 0;
      background: none;
      left: -13px;
      top: 0; }
    .breadcrumb li:first-child:before,
    .breadcrumbs li:first-child:before {
      display: none; }

/*@media screen and (min-width: 769px), print {
    ol.breadcrumb  {margin-left: 0!important;border: 1px green solid;}
}*/
.card h3 {
  margin-top: 1rem !important; }

.card-grey {
  padding: 1.375rem;
  background-color: #f2f2f2; }
  .card-grey dl {
    margin-top: 1rem; }
    .card-grey dl dt {
      margin-top: 0.75rem; }

.is-checkradio[type="checkbox"] + label::before {
  top: 0.25rem; }

.is-checkradio[type="checkbox"] + label::after {
  top: 0.6rem; }

.is-checkradio[type="checkbox"]:checked + label::before {
  background-color: #0070b8;
  border-color: #0070b8; }

.is-checkradio[type="checkbox"]:checked + label::after {
  border-color: white; }

.is-checkradio[type="radio"] + label::before {
  top: 0.25rem; }

.is-checkradio[type="radio"] + label::after {
  top: 0.25rem; }

.js .collapsible .collapsible__title {
  font-size: 1.333rem;
  margin-top: inherit !important;
  font-weight: 600; }
  .js .collapsible .collapsible__title button {
    all: inherit;
    border: 0;
    width: 100%;
    margin-top: 0;
    border-bottom: 1px solid #979797;
    align-items: center; }
    .js .collapsible .collapsible__title button:hover, .js .collapsible .collapsible__title button:active {
      background-color: inherit; }
    .js .collapsible .collapsible__title button[aria-expanded="true"] {
      border-bottom: 0; }
  .js .collapsible .collapsible__title + div {
    margin-top: 0 !important;
    padding: 0 1rem 1rem; }
    @media screen and (min-width: 769px), print {
      .js .collapsible .collapsible__title + div {
        padding-left: 3.157rem;
        padding-right: 3.157rem; } }
    .js .collapsible .collapsible__title + div:not(hidden) {
      border-bottom: 1px solid #979797; }
    .js .collapsible .collapsible__title + div h2,
    .js .collapsible .collapsible__title + div h3,
    .js .collapsible .collapsible__title + div h4,
    .js .collapsible .collapsible__title + div h5,
    .js .collapsible .collapsible__title + div h6 {
      margin-top: revert !important; }

.collapsible button svg {
  margin-left: 3.157rem;
  flex-shrink: 0; }

.collapsible [aria-expanded="true"] svg {
  transform: rotate(0.5turn);
  margin-right: 0;
  margin-left: 3.157rem; }

.collapsible h4 {
  font-weight: normal; }

.collapsible li {
  font-weight: normal;
  padding-bottom: 0;
  padding-left: 1rem;
  border-bottom: none; }
  .collapsible li::before {
    width: 6px;
    height: 6px;
    top: 0.5em; }

[class^="cols-list-"] {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  font-weight: 600;
  font-size: 1rem; }
  [class^="cols-list-"] li {
    border: 0;
    flex: none;
    margin: 0.75rem;
    width: calc(100% - 1.5rem);
    padding: 0.75rem 1.5rem;
    background-color: #0070b8;
    color: white; }
    [class^="cols-list-"] li::before {
      display: none; }
    .submenu [class^="cols-list-"] li {
      background-color: transparent;
      border-bottom: solid 1px #000;
      padding: 0 0 1rem 0; }
  [class^="cols-list-"] a {
    color: white;
    text-decoration: none;
    display: block; }
    .submenu [class^="cols-list-"] a {
      color: #0070b8; }
    [class^="cols-list-"] a:hover {
      text-decoration: underline; }
  [class^="cols-list-"] ::marker {
    content: "";
    font-size: 0;
    /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */ }

@media screen and (min-width: 769px), print {
  .cols-list-2 li {
    width: 50%;
    width: calc(50% - 1.5rem); } }

@media screen and (min-width: 1024px) {
  .cols-list-2 li {
    width: 50%;
    width: calc(50% - 1.5rem); } }

@media screen and (min-width: 769px), print {
  .cols-list-3 li {
    width: 33.333%;
    width: calc(33.333% - 1.5rem); } }

@media screen and (min-width: 1024px) {
  .cols-list-3 li {
    width: 33.333%;
    width: calc(33.333% - 1.5rem); } }

@media screen and (min-width: 769px), print {
  .cols-list-4 li {
    width: 33.333%;
    width: calc(33.333% - 1.5rem); } }

@media screen and (min-width: 1024px) {
  .cols-list-4 li {
    width: 25%;
    width: calc(25% - 1.5rem); } }

.blue-box li {
  min-height: 100px;
  /*a {
            padding-top: $spacing-2;
        }*/ }

.compartir {
  margin: 1em 0; }
  .compartir li {
    padding-left: 0;
    display: inline;
    border: 0;
    margin: 0 1em; }
    .compartir li::before {
      display: none; }
    .compartir li:first-child {
      margin-left: 0; }

.custom-list-2 {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  font-weight: 600;
  font-size: 1rem; }
  .custom-list-2 li {
    position: relative;
    padding: 1.333rem;
    border: 0;
    flex: none;
    margin: 0.75rem;
    width: calc(100% - 1.5rem);
    background-color: #f2f2f2; }
    .custom-list-2 li::before {
      display: none; }
    @media screen and (min-width: 769px), print {
      .custom-list-2 li {
        width: 50%;
        width: calc(50% - 1.5rem); } }
    .custom-list-2 li.clic {
      cursor: pointer; }
    .custom-list-2 li p {
      font-size: .9375rem; }

.ofertes__estat {
  position: relative;
  color: #273d3f;
  font-size: 0.75rem !important;
  border-radius: 8px;
  margin-left: 30px;
  margin-top: 5px; }
  .ofertes__estat:before {
    content: '';
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    left: -30px;
    top: -3px; }
  @media screen and (min-width: 769px), print {
    .ofertes__estat {
      position: absolute;
      top: 1rem;
      right: 1.777rem; } }

.ofertes__estat--1:before {
  background-color: #c00; }

.ofertes__estat--2:before {
  background-color: #d5692a; }

.ofertes__estat--0:before {
  background-color: #76ac37; }

.ofertes__estat--3:before {
  background-color: #0bc !important; }

.ofertes li {
  position: relative; }

.ofertes a h3 {
  font-size: 1.333rem;
  display: block;
  max-width: 300px; }

.box-banner-portada {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover; }
  .box-banner-portada h2 {
    line-height: 1;
    hyphens: auto; }
  .box-banner-portada h2,
  .box-banner-portada a {
    height: 100%;
    position: relative;
    color: white; }
    .box-banner-portada h2:hover,
    .box-banner-portada a:hover {
      text-decoration: none;
      color: white;
      background-blend-mode: multiply; }
      .box-banner-portada h2:hover:before,
      .box-banner-portada a:hover:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.2);
        transition: background-color .1s ease-in; }
    .box-banner-portada h2:before,
    .box-banner-portada a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0; }
  .box-banner-portada a {
    padding: 1rem; }
    .box-banner-portada a span {
      /*position: relative;
            top: $spacing-2;
            left: $spacing-2;
            max-width: 200px;*/
      display: block; }

.text-titol-butlleti h2 {
  font-weight: 700 !important;
  font-size: 3.157rem !important; }

.text-titol-butlleti span {
  max-width: 400px !important; }

@media screen and (max-width: 768px) {
  .has-submenu > ul {
    margin-bottom: 1.333rem; } }

.has-submenu > ul > li > a {
  display: block; }

.has-submenu > ul ul > * + * {
  margin-top: 0; }

.has-submenu > ul ul li {
  padding-left: 0; }
  .has-submenu > ul ul li::before {
    display: none; }
  .has-submenu > ul ul li:first-child a {
    padding-top: 0; }

@media screen and (min-width: 769px), print {
  .has-submenu {
    position: relative;
    transition-duration: 0.5s; }
    .has-submenu:hover > ul,
    .has-submenu:focus-within > ul,
    .has-submenu ul:hover,
    .has-submenu ul:focus {
      cursor: pointer;
      visibility: visible;
      opacity: 1;
      display: block;
      z-index: 1; }
    .has-submenu::after {
      content: "\2193"; }
    .has-submenu > ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      min-width: 15rem;
      transition: all 0.5s ease;
      display: none; }
      .has-submenu > ul::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: inset 0.5rem;
        border-color: #0070b8 transparent transparent transparent;
        border-top-style: solid;
        position: absolute;
        top: 0;
        left: 0; }
      .has-submenu > ul ul {
        margin-left: 0; } }

footer {
  margin-top: 4.209rem; }
  footer ul:first-of-type {
    letter-spacing: 0.8px; }
    @media screen and (max-width: 768px) {
      footer ul:first-of-type > * + * {
        padding-top: 1rem; } }
    @media screen and (min-width: 769px), print {
      footer ul:first-of-type {
        display: flex; } }
  footer li {
    margin-top: 0 !important;
    margin-left: 1rem;
    margin-right: 1rem; }
  footer a {
    text-decoration: none; }
    footer a:hover {
      text-decoration: underline; }
  footer [aria-current="page"] {
    background: none;
    text-decoration: underline; }
  @media screen and (min-width: 1024px) {
    footer img + ul {
      transform: scale(0.65); } }
  footer .icon {
    fill: #000;
    width: auto;
    height: auto; }

.home footer {
  margin-top: 2.369rem; }

.help {
  font-weight: 600;
  margin-top: 0.75rem; }
  .help::before {
    content: "";
    display: inline-block; }
  .help.is-success::before {
    width: 26px;
    height: 20px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNNS41OTMgMTYuNTUgOS4xMTMgMjBsLjAwNy0uMDA3LjAwNC4wMDQgMy41MS0zLjQ0Ljc1NS0uNzQ5TDI2IDMuNDQ0IDIyLjQ4OCAwIDkuMTE4IDEzLjEwOCAzLjUxMiA3LjYxMiAwIDExLjA1NWw1LjU5OSA1LjQ5eiIgZmlsbD0iIzYzQUExNiIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPgo="); }
  .help.is-danger::before {
    width: 23px;
    height: 23px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMjEuMDI5IDEuOTcxYTQuMTUgNC4xNSAwIDAgMC0yLjk0NC0xLjIyIDQuMTQ5IDQuMTQ5IDAgMCAwLTIuOTQ0IDEuMjJMMi4zNjggMTQuNzQ0LjExIDIxLjQwMWExLjE2NCAxLjE2NCAwIDAgMCAuMjgxIDEuMjA1bC4wMDQuMDA0YTEuMTYyIDEuMTYyIDAgMCAwIDEuMjE0LjI3OGwtLjAwOC4wMDMgNi42NTctMi4yNThMMjEuMDMxIDcuODZhNC4xNSA0LjE1IDAgMCAwIDEuMjItMi45NDQgNC4xNDkgNC4xNDkgMCAwIDAtMS4yMi0yLjk0NGwtLjAwMi0uMDAxek03LjQ0MyAxOS4zMjVsLTUuNzAyIDEuOTM0IDEuOTM0LTUuNzAyIDkuNzg1LTkuNzg1IDMuNzY3IDMuNzY3LTkuNzg0IDkuNzg2ek0xOS45NjkgNi43OTlsLTEuNjggMS42OC0zLjc2Ny0zLjc2NyAxLjY4LTEuNjhhMi42NjQgMi42NjQgMCAxIDEgMy43NjcgMy43Njd6IiBmaWxsPSIjRDAwMjFCIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KPC9zdmc+Cg=="); }

.franja-menu {
  background: #f2f2f2;
  position: relative;
  z-index: 1000; }
  @media screen and (min-width: 1024px) {
    .franja-menu {
      background: rgba(0, 112, 184, 0.8); }
      .home .franja-menu {
        z-index: 1;
        position: relative; } }

.full-bleed {
  width: 100vw;
  margin-left: calc(50% - 50vw); }
  .full-bleed--img {
    padding-top: 0;
    padding-bottom: 0; }
    .full-bleed--img img {
      width: 100vw;
      margin-left: calc(50% - 50vw);
      max-width: none; }

.grid-gallery {
  line-height: 0;
  font-size: 0;
  margin-left: -5px; }
  .grid-gallery img {
    display: inline-block;
    margin: 5px; }
    @media screen and (min-width: 769px), print {
      .grid-gallery img {
        height: 18rem; } }

@media screen and (min-width: 1024px) {
  .half-bleed {
    width: 150%;
    height: 26rem; }
    .half-bleed img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

@media screen and (max-width: 1023px) {
  header .wrapper > div:first-child {
    justify-content: center !important; } }

.home-logos-list {
  gap: 1.777rem;
  height: 100%;
  flex-direction: column; }
  .home-logos-list li {
    flex-basis: 50%; }
  .home-logos-list img {
    margin: auto; }

.houdiniForm legend.field {
  color: #c5c5c5;
  font-size: 1.777rem;
  font-weight: bold;
  padding-top: 1rem;
  display: block; }
  .houdiniForm legend.field.sub {
    font-size: 16px;
    color: black; }

.houdiniForm .help-form p {
  font-size: 14px;
  margin-top: 0.5rem;
  color: #a19f9f; }

.houdiniForm label.label {
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .has-background-secondary-dark .houdiniForm label.label {
    color: white; }

.houdiniForm label.error {
  color: #d0021b;
  font-size: 14px;
  background: url(/media/css/img/ico-avis-camp-obligatori.png) no-repeat;
  padding: 0.75rem 1.777rem;
  background-position: left;
  display: block; }

.houdiniForm .protecciodades {
  margin-top: 1.777rem;
  margin-bottom: 1rem; }

.ok-form,
.error-form {
  /* falten les imatges */ }

.sForm {
  width: 100%; }

/**
 * Default icon style
 */
.icon {
  /* Use the current text color as the icon’s fill color. */
  fill: currentColor;
  /* Inherit the text’s size too. Also allows sizing the icon by changing its font-size. */
  width: 32px;
  height: 32px;
  /* The default vertical-align is `baseline`, which leaves a few pixels of space below the icon. Using `center` prevents this. For icons shown alongside text, you may want to use a more precise value, e.g. `vertical-align: -4px` or `vertical-align: -0.15em`. */
  vertical-align: middle;
  /* Paths and strokes that overflow the viewBox can show in IE11. */
  overflow: hidden; }
  .icon--outline-thin, .icon--outline-normal, .icon--outline-thick {
    fill: none;
    stroke: currentColor; }
  .icon--outline-thin {
    stroke-width: 16px; }
  .icon--outline-normal {
    stroke-width: 32px; }
  .icon--outline-thick {
    stroke-width: 24px; }
  .icon--small {
    width: 16px;
    height: 16px; }
  .icon--large {
    width: 64px;
    height: 64px; }

#gdpr-cookie-message {
  position: fixed;
  right: 0;
  bottom: 1.777rem;
  max-width: 35rem;
  opacity: 0.9;
  box-shadow: 0px 0px 0.6px rgba(0, 0, 0, 0.02), 0px 0px 2.4px rgba(0, 0, 0, 0.028), 0px 0px 6.3px rgba(0, 0, 0, 0.035), 0px 0px 14px rgba(0, 0, 0, 0.042), 0px 0px 30.6px rgba(0, 0, 0, 0.05), 0px 0px 80px rgba(0, 0, 0, 0.07);
  z-index: 9999; }
  #gdpr-cookie-message [role="list"] {
    list-style: none; }
  @media screen and (max-width: 768px) {
    #gdpr-cookie-message {
      position: static;
      box-shadow: none;
      max-width: none; } }

.logos-footer {
  display: block !important; }
  .logos-footer li {
    display: block !important;
    text-align: center !important;
    margin-bottom: 1.777rem; }
    @media screen and (min-width: 769px), print {
      .logos-footer li {
        margin-right: 4.209rem; }
        .logos-footer li:last-child {
          margin-right: 0; } }
    .logos-footer li img {
      display: inline; }
  @media screen and (min-width: 769px), print {
    .logos-footer {
      display: flex !important; } }

.llista-borders {
  margin-left: 0; }
  .llista-borders li {
    border-bottom: 1px solid #0070b8;
    padding-bottom: 0.75rem;
    padding-left: 1.333rem;
    font-weight: normal; }
    .llista-borders li::before {
      display: none; }
    .llista-borders li a {
      color: #0a0a0a;
      text-decoration: none; }
      .llista-borders li a:hover {
        text-decoration: underline; }

.llista-blocks {
  margin: 30px 0 0 0;
  display: flex;
  align-items: baseline;
  gap: 1.333rem; }
  .llista-blocks li {
    padding: 0;
    display: inline-block;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    background: rgba(0, 112, 184, 0.8);
    mix-blend-mode: multiply;
    background-blend-mode: multiply; }
  .llista-blocks a {
    font-style: normal;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-decoration: none;
    font-size: 1.333rem;
    padding: 6rem 3.157rem 1.333rem 1.333rem;
    min-height: 215px;
    font-weight: 600;
    position: relative; }
    .llista-blocks a:hover:before {
      background-color: rgba(16, 113, 181, 0.6);
      transition: background-color .2s ease-in; }
    .llista-blocks a:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(16, 113, 181, 0.8); }
    .llista-blocks a span {
      position: relative; }

@media (max-width: 864px) {
  .llista-blocks li,
  .llista-blocks {
    display: block;
    text-align: center; }
  .llista-blocks li a {
    min-height: auto;
    padding: 1.333rem; } }

@media screen and (min-width: 1024px) {
  .home .main-content {
    margin-top: -3.125rem; } }

@media screen and (min-width: 1216px) {
  .home .main-content {
    margin-top: -3.5rem; } }

@media screen and (min-width: 1408px) {
  .home .main-content {
    margin-top: -4.166rem; } }

.contacte .main-content {
  margin-top: 0; }

.main-nav {
  padding-left: 0;
  margin-left: 0; }
  @media screen and (max-width: 1023px) {
    .main-nav {
      background: rgba(0, 112, 184, 0.8);
      margin-top: 1.777rem;
      margin-bottom: 1.777rem;
      margin-left: -1.777rem;
      margin-right: -1.777rem; } }
  @media screen and (min-width: 1024px) {
    .main-nav {
      display: flex;
      flex-wrap: wrap; } }
  @media screen and (min-width: 1408px) {
    .main-nav {
      letter-spacing: 0.8px; } }
  .main-nav > li {
    border: 0;
    padding: 0;
    text-transform: uppercase;
    margin-top: 0; }
    .main-nav > li > a {
      color: white;
      text-decoration: none;
      padding: 1rem 1.333rem;
      display: block;
      /*@include widescreen {
                padding-left: $spacing-1;
                padding-right: $spacing-1;
                border:1px green solid;
            }*/ }
      @media screen and (min-width: 769px), print {
        .main-nav > li > a {
          padding-left: 0.75rem;
          padding-right: 0.75rem; } }
      @media screen and (min-width: 1408px) {
        .main-nav > li > a {
          padding: 1.333rem 1.333rem; } }
      .main-nav > li > a:hover {
        background-color: #01375d; }
    .main-nav > li:before {
      display: none; }

[aria-current="page"] {
  background: #01375d; }

.map {
  position: relative; }
  @media screen and (min-width: 769px), print {
    .map {
      height: 385px;
      width: 100%; } }

.grid-item-gallery {
  width: 200px; }

.grid-item--width2 {
  width: 400px; }

.mobile-img {
  width: 100%;
  height: 20rem;
  object-fit: cover; }

.hamburger {
  cursor: default; }
  @media screen and (min-width: 1024px) {
    .hamburger {
      display: none; } }

@media screen and (min-width: 1024px) {
  #menu {
    display: block; } }

.news-list .column {
  padding: 1.333rem 1.333rem 3.157rem 1.333rem;
  border-bottom: 1px #d5d5d5 solid; }
  .news-list .column h3 {
    font-weight: normal; }
  .news-list .column a {
    text-decoration: none; }
    .news-list .column a:hover {
      text-decoration: underline; }

.news-list .resum p {
  font-size: 1rem; }

.content-6 p {
  font-size: 1rem;
  margin-bottom: 1.4rem; }

.newsletter-form input {
  border: none; }

.newsletter-form .is-checkradio[type="checkbox"]:checked + label::before {
  border-color: #fff; }

.newsletter-form .is-checkradio[type="checkbox"] + label::before {
  border-color: #fff; }

.newsletter-form .button {
  border-color: #fff; }

.pagination-list, .pagination {
  text-align: center;
  margin-top: 2.369rem; }
  .pagination-list li, .pagination li {
    padding: 0;
    display: inline-block;
    font-size: rem-calc(20);
    vertical-align: middle;
    margin-top: 0 !important;
    border: 0; }
    .pagination-list li:before, .pagination li:before {
      display: none; }
  .pagination-list a, .pagination a {
    text-decoration: none;
    display: block;
    color: black;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    width: 2.369rem;
    height: 2.369rem;
    border-radius: 50%;
    text-align: center;
    margin: 0 0.75rem;
    line-height: 2.3; }
  .pagination-list .current a,
  .pagination-list a:hover,
  .pagination-list a:focus, .pagination .current a,
  .pagination a:hover,
  .pagination a:focus {
    color: #fff;
    -webkit-text-fill-color: #fff;
    background: #0070b8;
    border-color: #0070b8; }
  .pagination-list .unavailable, .pagination .unavailable {
    opacity: 0.5; }
    .pagination-list .unavailable a, .pagination .unavailable a {
      background-color: transparent; }
      .pagination-list .unavailable a:hover, .pagination-list .unavailable a:focus, .pagination .unavailable a:hover, .pagination .unavailable a:focus {
        color: inherit;
        text-decoration: underline;
        background-color: transparent; }
  .pagination-list .prev,
  .pagination-list .next, .pagination .prev,
  .pagination .next {
    font-size: 0;
    text-align: center; }
    .pagination-list .prev a,
    .pagination-list .next a, .pagination .prev a,
    .pagination .next a {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: transparent; }
      .pagination-list .prev a:before,
      .pagination-list .next a:before, .pagination .prev a:before,
      .pagination .next a:before {
        content: "";
        display: inline-block; }
      .pagination-list .prev a:hover,
      .pagination-list .next a:hover, .pagination .prev a:hover,
      .pagination .next a:hover {
        background: none !important; }
  .pagination-list .prev a:before, .pagination .prev a:before {
    background: url(/media/css/img/ico-fletxa.svg) no-repeat;
    width: 15px;
    height: 24px; }
  .pagination-list .next a:before, .pagination .next a:before {
    background: url(/media/css/img/ico-fletxa.svg) no-repeat;
    width: 15px;
    height: 24px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }

.pagination {
  position: relative; }
  .pagination .pagination-previous, .pagination .pagination-next {
    font-size: 0;
    text-align: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    position: absolute; }
    .pagination .pagination-previous:before, .pagination .pagination-next:before {
      content: "";
      display: inline-block; }
      @media screen and (max-width: 768px) {
        .pagination .pagination-previous:before, .pagination .pagination-next:before {
          content: none; } }
    .pagination .pagination-previous:hover, .pagination .pagination-next:hover {
      background: none !important; }
  .pagination .pagination-previous {
    left: 0; }
    .pagination .pagination-previous:before {
      background: url(/media/css/img/ico-fletxa.svg) no-repeat;
      width: 15px;
      height: 24px; }
  .pagination .pagination-next {
    right: 0; }
    .pagination .pagination-next:before {
      background: url(/media/css/img/ico-fletxa.svg) no-repeat;
      width: 15px;
      height: 24px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1); }

.mfp-arrow::after,
.mfp-arrow::before {
  border: none; }

.mfp-arrow::after {
  content: "";
  display: block;
  width: 15px;
  height: 24px; }

.mfp-arrow-right::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIgMiAxMCAxMEwyIDIyIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+"); }

.mfp-arrow-left::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDIgMyAxMmwxMCAxMCIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="); }

.mfp-figure::after {
  box-shadow: none; }

.mfp-figure figcaption {
  margin-top: 0; }

@media screen and (min-width: 1024px) and (max-width: 1215px) {
  .search-form {
    flex-basis: 12rem; }
    .search-form .control:last-child {
      flex-shrink: 0; } }

@media screen and (max-width: 1023px) {
  .search-form .field {
    justify-content: center; } }

.search-form input {
  border-top-left-radius: 24px !important;
  border-bottom-left-radius: 24px !important;
  border-right: 0; }

.search-form button {
  border-color: #000;
  border-left: 0; }

.search-form input:hover,
.search-form button:hover {
  border-color: black; }

.search-form svg {
  height: auto !important;
  width: auto !important; }

@media screen and (min-width: 1216px) {
  .search-form--home .field {
    justify-content: flex-start; } }

.search-form--home input {
  border-top-left-radius: 27px !important;
  border-bottom-left-radius: 27px !important; }

.search-form--home button {
  border-top-right-radius: 27px;
  border-bottom-right-radius: 27px; }

.search-form--home .control:first-of-type {
  flex-basis: 30rem; }

@media screen and (max-width: 768px) {
  .search-form--home .control:last-child {
    flex-shrink: 0; } }

.search-form-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
  top: 18rem; }
  @media screen and (max-width: 768px) {
    .search-form-container {
      top: 15em; } }

.scrolltop {
  opacity: 0;
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;
  transition: all 400ms ease-in-out; }
  .scrolltop.visible {
    opacity: 1; }
  .scrolltop circle {
    fill: yellow;
    fill: #0070b8; }
  .scrolltop path {
    fill: white; }

.section {
  position: relative; }
  .section::before, .section::after {
    content: "";
    display: block;
    position: absolute;
    background-color: inherit;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0; }
  .section::before {
    left: -100%;
    right: 0; }
  .section::after {
    left: 100%; }
  .section h2 {
    font-weight: bold;
    font-size: 1.777rem;
    font-weight: bold; }

.share .icon {
  width: 37px;
  height: 37px;
  fill: black; }
  @media screen and (min-width: 1024px) {
    .share .icon {
      transform: scale(0.65); } }

.share li {
  display: inline-block;
  border-right: 1px black solid;
  padding: 0 1.333rem; }
  .share li:last-child {
    border-right: 0; }
  .share li:first-child {
    padding-left: 0; }

@media screen and (max-width: 768px) {
  .site-title {
    width: 11.5rem;
    height: 3.125rem; } }

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .site-title {
    width: 18.5rem;
    height: 5.125rem; } }

.skip-link {
  position: absolute;
  top: 1.777rem;
  right: 100%;
  /* moves off screen */
  background-color: #fff;
  padding: 1rem;
  font-size: 1rem;
  text-decoration: none;
  color: #000 !important;
  font-weight: normal; }
  .skip-link:focus {
    right: auto;
    left: 1.777rem;
    z-index: 10; }

.js .slider {
  visibility: hidden; }
  .js .slider.slick-initialized {
    visibility: visible; }

.slider .slick-slide img {
  height: 26rem;
  object-fit: cover;
  width: 100%;
  display: block; }
  @media screen and (min-width: 769px), print {
    .slider .slick-slide img {
      height: 24rem; } }
  @media screen and (min-width: 1024px) {
    .slider .slick-slide img {
      height: 30rem; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide img {
      height: 24rem; } }

.slider__caption {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 10rem; }
  .slider__caption .slider__text {
    width: 60%; }
    @media (min-width: 1300px) {
      .slider__caption .slider__text {
        width: 50%; } }
    @media (max-width: 1100px) {
      .slider__caption .slider__text {
        width: 70%; } }
    @media (max-width: 950px) {
      .slider__caption .slider__text {
        width: 100%; }
        .slider__caption .slider__text h2 {
          font-size: 1.333rem; }
        .slider__caption .slider__text p {
          font-size: 1rem; } }

.slider .slick-dots {
  max-width: 98.125rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.777rem;
  padding-right: 1.777rem;
  bottom: 1.333rem;
  text-align: right;
  width: 100%;
  left: 50%;
  transform: translateX(-50%); }
  .slider .slick-dots li {
    border: 0; }
    .slider .slick-dots li:before {
      display: none; }

.slider .dot {
  width: 2.369rem;
  height: 2.369rem;
  cursor: pointer; }

.slider .slick-active .dot {
  background-color: #7a7a7a !important; }

.slick-slide {
  position: relative; }
  .slick-slide::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #fff 46%, rgba(255, 255, 255, 0) 80%); }

.slick-pause-icon {
  display: none !important; }

@media screen and (min-width: 1024px) {
  .social-home {
    padding-left: 4.209rem;
    padding-right: 4.209rem; } }

.sub-nav {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .sub-nav {
      margin-top: 4.209rem; } }
  .sub-nav li {
    padding: 0;
    border: 0;
    margin-bottom: 0.75rem; }
    .sub-nav li:before {
      display: none; }
    .sub-nav li a {
      display: block;
      text-decoration: none;
      color: black;
      border-radius: 34px;
      padding: 0.75rem 1.333rem;
      border: 2px white solid; }
      .sub-nav li a:hover {
        border: 2px #0070b8 solid;
        transition: border 0.15s ease-in-out; }
    .sub-nav li.active a {
      border: 2px #0070b8 solid; }

#sub-nav > ul {
  width: 100%;
  margin-left: 0; }
  @media screen and (min-width: 1024px) {
    #sub-nav > ul {
      margin-top: 1rem; } }
  #sub-nav > ul > li {
    padding: 0;
    border: 0;
    margin-top: 0;
    font-weight: normal; }
    #sub-nav > ul > li:before {
      display: none; }
    #sub-nav > ul > li > a {
      display: block;
      text-decoration: none;
      color: black;
      border-radius: 34px;
      padding: 0.6rem 1.333rem;
      border: 2px white solid; }
      #sub-nav > ul > li > a:hover {
        background-color: #eee; }
    #sub-nav > ul > li [aria-current="page"] {
      background: transparent; }
    #sub-nav > ul > li.active a {
      border-color: #0070b8; }
  #sub-nav > ul ul {
    padding-top: 0;
    font-size: 0.75rem;
    margin-left: 2.5rem; }
    #sub-nav > ul ul li {
      padding: 0;
      font-weight: 300;
      margin-top: 0.5rem; }
      #sub-nav > ul ul li::before {
        display: none; }
      #sub-nav > ul ul li.active a {
        font-weight: bold; }
    #sub-nav > ul ul a {
      text-decoration: none;
      color: #000; }
      #sub-nav > ul ul a:hover {
        text-decoration: underline; }

.sub-nav-nav {
  margin-bottom: 3.157rem;
  margin-left: 0; }
  .sub-nav-nav li {
    display: inline-block;
    border: 0;
    padding: 0;
    font-size: 1.777rem;
    padding: 0.75rem 1.333rem;
    border-right: 1px #979797 solid;
    font-size: 24px; }
    .sub-nav-nav li:first-child {
      padding-left: 0; }
    .sub-nav-nav li a {
      text-decoration: none; }
      .sub-nav-nav li a:hover {
        text-decoration: underline; }
    .sub-nav-nav li:before {
      display: none; }
    .sub-nav-nav li:last-child {
      border: 0; }

.js .tab-menu {
  padding-left: 0;
  display: flex;
  list-style: none; }
  .js .tab-menu li {
    padding: 0;
    margin: 0 1rem 0 0; }
    .js .tab-menu li:last-child {
      margin-right: 0; }
  .js .tab-menu a {
    text-decoration: none;
    display: block;
    padding: 0.75rem 1rem;
    color: white;
    background-color: #0070b8; }
    .js .tab-menu a:hover, .js .tab-menu a[aria-selected] {
      outline: 0;
      color: #0a0a0a;
      background-color: white; }

.tab-panel {
  border: 1px solid #cce2f1;
  border-top: 0; }
  .js .tab-panel {
    margin-top: 0; }
  .tab-panel h2 {
    font-size: 1.777rem; }
  .tab-panel h2,
  .tab-panel h3,
  .tab-panel h4,
  .tab-panel h5,
  .tab-panel h6 {
    margin-top: revert !important; }

@media screen and (max-width: 768px) {
  .js .tab-menu {
    display: block; }
    .js .tab-menu li {
      display: block;
      margin-right: 0;
      margin-top: 1px; }
      .js .tab-menu li:first-child {
        margin-top: 0; } }

@media screen and (min-width: 769px), print {
  .js .tabbed--vertical {
    display: flex; }
    .js .tabbed--vertical .tab-menu {
      display: block;
      flex: 1 0 25%; }
      .js .tabbed--vertical .tab-menu li {
        display: block;
        margin: 0 0 1rem 0; }
      .js .tabbed--vertical .tab-menu a {
        display: block; }
    .js .tabbed--vertical .tab-panel {
      flex-basis: 75%; } }

#table-professionals {
  background-color: white; }

#table-professionals.dataTable thead .sorting_desc:after, #table-professionals.dataTable thead .sorting_asc:after, #table-professionals.dataTable thead .sorting:after {
  opacity: 1;
  content: '';
  background-image: url("img/ico-up-down.svg");
  width: 13px;
  height: 20px;
  display: block; }

#table-professionals_filter .control-label {
  display: inline-block; }
  #table-professionals_filter .control-label label {
    margin-top: 7px;
    display: block; }

#table-professionals_wrapper .select {
  margin-top: -8px; }

.tels a {
  white-space: nowrap; }

@media screen and (max-width: 1023px) {
  .tels {
    justify-content: center; }
    .tels li {
      font-size: 0.75rem; }
    .tels a {
      font-size: 1rem !important; } }

@media screen and (min-width: 1024px) {
  .tels {
    justify-content: flex-end;
    margin-top: 0; } }

.tels svg {
  width: auto;
  height: auto;
  position: relative;
  top: -2px; }

@media screen and (min-width: 1024px) {
  .tels.tels--center {
    justify-content: flex-start; } }

h2.fons-color {
  font-size: 1.777rem;
  background-color: #f2f2f2;
  padding: 0.75rem 1.777rem; }

@media screen and (min-width: 1024px) {
  .fons-color ~ :not(.fons-color) {
    margin-left: 3.157rem; }
  .fons-color ~ ol {
    margin-left: 72px !important; } }

.cercador.list h2 {
  margin-top: 0 !important;
  font-weight: bold; }

.cercador.list a {
  text-decoration: none; }
  .cercador.list a:hover {
    text-decoration: underline; }

.cercador.list article {
  margin-bottom: 3.157rem; }

.contacte {
  /*.button {
        margin-right: $spacing-1;
        &:last-child {
            margin-right: 0;
        }
    }*/ }
  .contacte .columns {
    column-gap: 3.157rem; }
  .contacte h2 {
    font-size: 26px; }
  .contacte .bk {
    background: linear-gradient(to bottom, #d1d1d1, white); }
    .contacte .bk h2 {
      font-weight: bold; }
  .contacte .c-espai {
    padding: 3.157rem;
    margin-top: 0; }
  .contacte small {
    display: block;
    margin-bottom: 1rem; }
  .contacte hr {
    border-top: 1px #979797 solid;
    margin-top: 2.369rem;
    margin-bottom: 1.777rem; }

.professionals .list-metges li {
  background: none;
  color: black;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0; }
  .professionals .list-metges li a {
    color: black;
    text-decoration: none; }
    .professionals .list-metges li a:hover {
      text-decoration: underline; }
  .professionals .list-metges li img {
    margin-bottom: 1rem; }

.bg-w li {
  background-color: transparent;
  color: black;
  border-bottom: 0; }

.serveis .servei ul {
  margin-left: 0; }
  .serveis .servei ul li {
    /*padding-left: 0;*/
    font-size: calc(18px);
    font-weight: 100;
    border-bottom: 1px #0070b8 solid; }
    .serveis .servei ul li:before {
      display: none; }
    .serveis .servei ul li ul {
      padding-left: 2.369rem;
      /*border-top: 1px $primary solid;*/ }
      .serveis .servei ul li ul li {
        border: 0;
        font-size: calc(16px);
        font-weight: 600; }
  .serveis .servei ul.bg-w li {
    border-bottom: 0; }

.serveis .servei h2 {
  text-transform: uppercase;
  font-size: calc(22px);
  font-weight: 600; }

.serveis .servei h3 {
  font-weight: 600;
  font-size: 1.333rem; }

.serveis .servei .telf .b {
  font-size: calc(24px);
  font-weight: 600; }

.serveis .servei .avis {
  max-width: none; }

.serveis .servei .info-servei p {
  font-size: calc(16px); }

.serveis .servei .collapsible ul {
  margin-left: 1rem; }
  .serveis .servei .collapsible ul li {
    border: 0;
    font-size: 16px; }
    .serveis .servei .collapsible ul li:before {
      display: block; }

@media screen and (min-width: 1024px) {
  .serveis .servei .fons-color ~ :not(.fons-color) {
    margin-left: 3.157rem; } }

.serveis .servei ul:not([class]) + hr {
  display: none; }
