.box-banner-portada {
    height: 100%;
    //background: url("img/imatge-demanar-hora.png");
    background-repeat: no-repeat;
    background-size: cover;
    h2 {
        line-height: 1;
        hyphens: auto
    }
    h2,
    a {
        height: 100%;
        position: relative;
        color: white;
        &:hover {
            text-decoration: none;
            color: white;
            background-blend-mode: multiply;
            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(255, 255, 255, 0.2);
                transition: background-color .1s ease-in;
            }
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    a {
        padding: $spacing-2;
        span {
            /*position: relative;
            top: $spacing-2;
            left: $spacing-2;
            max-width: 200px;*/
            display: block;
        }
    }
}

.text-titol-butlleti {
    h2 {
        font-weight: 700 !important;
        font-size: 3.157rem !important;
    }
    span {
        max-width: 400px !important;

    }
}