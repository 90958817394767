.sub-nav-nav {
    margin-bottom: $spacing-6;
    margin-left:  0;
    li {
        display: inline-block;
        border: 0;
        padding: 0;
        font-size: $size-4;
        padding: $spacing-1 $spacing-3;
        border-right: 1px #979797 solid;
        font-size: 24px;
        &:first-child {
            padding-left:0;
        }
        a {
            //padding: $spacing-1 $spacing-3;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &:before {
            display: none;
        }
        &:last-child {
            border: 0;
        }
    }
}
