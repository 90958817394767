.newsletter-form {
    input {
        border: none;
    }
    .is-checkradio[type="checkbox"] {
        &:checked {
            + label {
                &::before {
                    border-color: #fff;
                }
            }
        }
        + label {
            &::before {
                border-color: #fff;
            }
        }
    }
    .button {
        border-color: #fff;
    }
}
