
.compartir {
    margin: 1em 0;
    li {
        &::before {
            display: none;
        }
        padding-left: 0;
        display: inline;
        border:0;
        margin: 0 1em;
        &:first-child {
            margin-left: 0;
        }
    }
}
