.logos-footer {
    display: block!important;
    li {
        display: block!important;
        text-align: center!important;
        margin-bottom: $spacing-4;
        @include tablet {
            margin-right: $spacing-7;
            &:last-child {
                margin-right: 0;
            }
        }
        img {
            display: inline;
        }
    }
    @include tablet {
        display: flex!important;
    }
}
