.franja-menu {
    background: $grey-lighter;
    position: relative;
    z-index: 1000;
    @include desktop {
        background: rgba($primary, 0.8);
        .home & {
            z-index: 1;
            position: relative;
        }
    }
}
