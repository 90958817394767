.search-form-container {
    //@include desktop {
        //top: 15rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;
    //}
    //@include widescreen {
        top: 18rem;
        @include mobile {
            top: 15em;
        }
    //}
}
