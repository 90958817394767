/**
 * Default icon style
 */
.icon {
    /* Use the current text color as the icon’s fill color. */
    fill: currentColor;
    /* Inherit the text’s size too. Also allows sizing the icon by changing its font-size. */
    // width: 1em;
    // height: 1em;
    width: 32px;
    height: 32px;
    /* The default vertical-align is `baseline`, which leaves a few pixels of space below the icon. Using `center` prevents this. For icons shown alongside text, you may want to use a more precise value, e.g. `vertical-align: -4px` or `vertical-align: -0.15em`. */
    vertical-align: middle;
    /* Paths and strokes that overflow the viewBox can show in IE11. */
    overflow: hidden;

    // icones outline
    &--outline-thin,
    &--outline-normal,
    &--outline-thick {
        fill: none;
        stroke: currentColor;
    }
    &--outline-thin {
        stroke-width: 16px;
    }
    &--outline-normal {
        stroke-width: 32px;
    }
    &--outline-thick {
        stroke-width: 24px;
    }

    // mides icones
    &--small {
        width: 16px;
        height: 16px;
    }
    &--large {
        width: 64px;
        height: 64px;
    }
}
