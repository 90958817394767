[class^="cols-list-"] {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    // margin-top: -0.75rem;
    font-weight: 600;
    font-size: $size-6;
    li {
        border: 0;
        flex: none;
        margin: 0.75rem;
        width: calc(100% - 1.5rem);
        padding: 0.75rem 1.5rem;
        // margin-top: 0;
        background-color: $primary;
        color: $white;
        //min-height: 100px;
        &::before {
            display: none;
        }
        .submenu & {
            background-color: transparent;
            border-bottom: solid 1px #000;
            padding: 0 0 1rem 0;
        }
    }
    a {
        color: $white;
        text-decoration: none;
        //padding-top: $spacing-2;
        display: block;
        .submenu & {
            color: $primary;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    ::marker {
        content: "";
        font-size: 0; /* Safari support is limited to color and font-size. See bug https://bugs.webkit.org/show_bug.cgi?id=204163 */
    }
}
// 2 columnes
.cols-list-2 li {
    @include tablet {
        width: 50%;
        width: calc(50% - 1.5rem);
    }
    @include desktop {
        width: 50%;
        width: calc(50% - 1.5rem);
    }
}
// 3 columnes
.cols-list-3 li {
    @include tablet {
        width: 33.333%;
        width: calc(33.333% - 1.5rem);
    }
    @include desktop {
        width: 33.333%;
        width: calc(33.333% - 1.5rem);
    }
}
// 4 columnes
.cols-list-4 li {
    @include tablet {
        width: 33.333%;
        width: calc(33.333% - 1.5rem);
    }
    @include desktop {
        width: 25%;
        width: calc(25% - 1.5rem);
    }
}
.blue-box {
    li {
        min-height: 100px;
        /*a {
            padding-top: $spacing-2;
        }*/
    }
}