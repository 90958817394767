ul.rel-docs {
    margin-left: 0;
    > * + * {
        margin-top: $spacing-2;
    }
    li {
        border: 1px solid #000;
        padding: $spacing-2 $spacing-6 $spacing-2 $spacing-4;
        border-radius: 28px;
        //width: 760px;
        position: relative;
        display: block;
        font-weight: normal;
        @include mobile {
            width: auto;
        }
        .has-background-primary-light & {
            background-color: #fff;
        }
        &:after {
            content: "";
            background-image: url("img/data-transfer-down.svg");
            width: 24px;
            height: 24px;
            display: inline-block;
            position: absolute;
            right: $spacing-2;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        &:before {
            content: none;
        }
    }
}
