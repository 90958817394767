.wrapper {
    /*max-width: 80rem;*/
    max-width: 98.125rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-4;
    padding-right: $spacing-4;
    &--narrow {
        max-width: 60rem;
    }
}
