#table-professionals {
    background-color: $white;
}
#table-professionals.dataTable thead .sorting_desc:after, #table-professionals.dataTable thead .sorting_asc:after, #table-professionals.dataTable thead .sorting:after  {
    opacity: 1;
    content: '';
    background-image: url('img/ico-up-down.svg');
    width: 13px;
    height: 20px;
    display: block;
}
#table-professionals_filter {
    .control-label {
        display: inline-block;
        label {
            margin-top: 7px;
            display: block;
        }
    }
}
#table-professionals_wrapper {
    .select {
        margin-top: -8px;
    }
}