.tab-menu {
    .js & {
        padding-left: 0;
        display: flex;
        list-style: none;
        li {
            padding: 0;
            margin: 0 $spacing-2 0 0;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            text-decoration: none;
            display: block;
            padding: $spacing-1 $spacing-2;
            color: $white;
            background-color: $primary;
            &:hover,
            &[aria-selected] {
                outline: 0;
                color: $black;
                background-color: $white;
            }
        }
    }
}

.tab-panel {
    border: 1px solid $primary-light;
    border-top: 0;
    .js & {
        margin-top: 0;
    }
    // Estils pels elements de text dins el panel
    h2 {
        font-size: $size-4;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: revert !important;
    }
}

// Pestanyes en mòbil
@include mobile {
    .js .tab-menu {
        display: block;
        li {
            display: block;
            margin-right: 0;
            margin-top: 1px;
            &:first-child {
                margin-top: 0;
            }
        }
        // [aria-selected] {
        //     outline: 0;
        // }
    }
}
// Pestanyes verticals
.js .tabbed--vertical {
    @include tablet {
        display: flex;
        .tab-menu {
            display: block;
            flex: 1 0 25%;
            li {
                display: block;
                margin: 0 0 $spacing-2 0;
            }
            a {
                display: block;
            }
        }
        .tab-panel {
            flex-basis: 75%;
        }
    }
}
