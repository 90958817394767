.llista-borders {
    margin-left: 0;
    li {
        border-bottom: 1px solid $primary;
        padding-bottom: $spacing-1;
        padding-left: $spacing-3;
        font-weight: normal;
        &::before {
            display: none;
        }
        a {
            color: $black;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
