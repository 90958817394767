.mfp-arrow::after,
.mfp-arrow::before {
    border: none;
}
.mfp-arrow::after {
    content: "";
    display: block;
    width: 15px;
    height: 24px;
}
.mfp-arrow-right::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIgMiAxMCAxMEwyIDIyIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iNCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
}
.mfp-arrow-left::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDIgMyAxMmwxMCAxMCIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
}
.mfp-figure {
    &::after {
        box-shadow: none;
    }
    figcaption {
        margin-top: 0;
    }
}
