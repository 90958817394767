/* Override Bulma icon margin styles */
.button .icon:first-child {
    margin: 0 0.5em 0 0 !important;
}
.button {
    border-radius: 24px;
    text-decoration: none;
    font-weight: 600;
    a {
        /*padding-left: $size-3;
        padding-right: $size-3;*/
        text-decoration: none;
    }
}
.button.is-primary {
    a {
        color: $white;
        text-decoration: none;
    }
}
.button.is-outlined {
    color: $primary;
    background-color: transparent;
    border-color: rgb(66, 55, 55);
    a {
        color: $primary;
        text-decoration: none;
    }
    &:hover {
        background-color: $primary;
        transition: 0.2s ease all;
        color: white;
        a {
            color: white;
        }
    }
}
.button.is-inverted {
    background-color: transparent;
}
