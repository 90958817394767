.scrolltop {
    opacity: 0;
    position: fixed;
    bottom: $spacing-1;
    right: $spacing-1;
    transition: all 400ms ease-in-out;
    &.visible {
        opacity: 1;
    }
    circle {
        fill: yellow;
        fill: $primary;
    }
    path {
        fill: $white;
    }
}
