.cercador.list {
    h2 {
        margin-top: 0!important;
        font-weight: bold;
    }
    a {
        text-decoration: none;
        &:hover { 
            text-decoration: underline;
        }
    }
    article {
        margin-bottom: $spacing-6;
    }
}
