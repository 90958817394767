.banner-treballa {
    text-align: center;
    a {
        display: block;
        color: white;
        text-decoration: none;
        font-size: $size-5;
        padding: $spacing-3;
        //background-image: url("img/caixa-treballa.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        font-weight: 600;
        &:hover {
            &:before {
                background-color: rgba(#1071b5, .6);
                transition: background-color .2s ease-in;
            }
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            mix-blend-mode: multiply;
            background-color: rgba(#1071b5, .8);
        }
        span {
            position: relative;
        }
    }
}
.flow--5 .banner-treballa {
    margin-top: $spacing-3;
}
