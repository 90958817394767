.collapsible {
    .js & {
        .collapsible__title {
            font-size: $size-5;
            margin-top: inherit !important;
            font-weight: 600;
            button {
                all: inherit;
                border: 0;
                width: 100%;
                margin-top: 0;
                border-bottom: 1px solid #979797;
                align-items: center;
                &:hover,
                &:active {
                    background-color: inherit;
                }
                // &:focus {
                //     outline-style: solid;
                //     outline-color: transparent;
                //       box-shadow: 0 0 0 4px $focus-color;
                // }
                &[aria-expanded="true"] {
                    border-bottom: 0;
                }
            }
            + div {
                margin-top: 0 !important;
                padding: 0 $spacing-2 $spacing-2;
                @include tablet {
                    padding-left: $spacing-6;
                    padding-right: $spacing-6;
                }
                &:not(hidden) {
                    border-bottom: 1px solid #979797;
                }
                h2,
                h3,
                h4,
                h5,
                h6 {
                    margin-top: revert !important;
                }
            }
        }
    }
    button svg {
        // width: $size-6;
        margin-left: $size-2;
        // display: initial;
        flex-shrink: 0;
    }
    [aria-expanded="true"] svg {
        transform: rotate(0.5turn);
        margin-right: 0;
        margin-left: $size-2;
    }
}

.collapsible {
    h4 {
        font-weight: normal;
    }
    li {
        font-weight: normal;
        padding-bottom: 0;
        padding-left: $size-6;
        border-bottom: none;
        &::before {
            width: 6px;
            height: 6px;
            top: 0.5em;
        }
    }
}
