.professionals {
    .list-metges {
        li {
            background: none;
            color: black;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            a {
                color: black;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            img {
                margin-bottom: $spacing-2;
            }
        }
    }
}

.bg-w {
    li {
        background-color: transparent;
        color: black;
        border-bottom: 0;
    }
}
