.share {
    .icon {
        width: 37px;
        height: 37px;
        fill: black;
        @include desktop {
            transform: scale(0.65);
        }
    }
    li {
        display: inline-block;
        border-right: 1px black solid;
        padding: 0 $spacing-3;
        &:last-child {
            border-right: 0;
        }
        &:first-child {
            padding-left: 0;
        }
    }
}
