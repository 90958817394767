.pagination-list, .pagination {
    text-align: center;
    margin-top: $spacing-5;
    li {
      padding: 0;
      display: inline-block;
      font-size: rem-calc(20);
      vertical-align: middle;
      margin-top: 0 !important;
      border:0;
      &:before {
        display: none;
      }
    }
    a {
      text-decoration: none;
      display: block;
      color: black;
      border: 1px solid #f2f2f2;
      background: #f2f2f2;
      width: $spacing-5;
      height: $spacing-5;
      border-radius: 50%;
      text-align: center;
      margin: 0 $spacing-1;
      line-height: 2.3;
    }
    .current a,
    a:hover,
    a:focus {
      color: #fff;
      -webkit-text-fill-color: #fff;
      background: $primary;
      border-color: $primary;
    }
    .unavailable {
      opacity: 0.5;
      a {
        // width: auto;
        background-color: transparent;
        &:hover,
        &:focus {
          color: inherit;
          text-decoration: underline;
          background-color: transparent;
        }
      }
    }
    .prev,
    .next {
        font-size: 0;
        text-align: center;
      a {
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: transparent;
        &:before {
          content: "";
          display: inline-block;
        }
        &:hover {
          background: none !important;
        }
      }
    }
    .prev  {
      a {
        &:before {
          //@include sprite("bt-paginacio-anterior");
          background: url(/media/css/img/ico-fletxa.svg) no-repeat;
          width: 15px;
          height: 24px;
        }
      }
    }
    .next  {
      a {
        &:before {
          //@include sprite("bt-paginacio-seguent");
          //content: ">";
          background: url(/media/css/img/ico-fletxa.svg) no-repeat;
          width: 15px;
          height: 24px;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }
    }
  }
.pagination {
    position: relative;
  .pagination-previous, .pagination-next {
    font-size: 0;
    text-align: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
    position: absolute;
    &:before {
      content: "";
      display: inline-block;
      @include mobile {
        content: none;
      }
    }
    &:hover {
      background: none !important;
    }
  }
    .pagination-previous {
      left: 0;
      &:before {
        //@include sprite("bt-paginacio-anterior");
        background: url(/media/css/img/ico-fletxa.svg) no-repeat;
        width: 15px;
        height: 24px;
       
       
      }
    }


    .pagination-next {
      right: 0;
      &:before {
        //@include sprite("bt-paginacio-seguent");
        //content: ">";
        background: url(/media/css/img/ico-fletxa.svg) no-repeat;
        width: 15px;
        height: 24px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        
      }
    }
  
}