.map {
    position: relative;
    //background-image: url('/media/img/map.jpg');
    //background-size: cover;
    //background-repeat: no-repeat;
    @include tablet{
        height: 385px;
        width: 100%;
    }
}
