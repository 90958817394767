.contacte {
    .columns {
        column-gap: $spacing-6;
    }
    h2 {
        font-size: 26px;
    }
    .bk {
        background: linear-gradient(to bottom, #d1d1d1, white);
        h2 {
            font-weight: bold;
        }
    }
    .c-espai {
        padding: $spacing-6;
        margin-top: 0;
    }
    /*.button {
        margin-right: $spacing-1;
        &:last-child {
            margin-right: 0;
        }
    }*/
    small {
        display: block;
        margin-bottom: $spacing-2;
    }
    hr {
        border-top: 1px #979797 solid;
        margin-top: $spacing-5;
        margin-bottom: $spacing-4;
    }
}
