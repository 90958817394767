.main-nav {
    padding-left: 0;
    margin-left: 0;
    @include touch {
        background: rgba($primary, 0.8);
        margin-top: $spacing-4;
        margin-bottom: $spacing-4;
        margin-left: -$spacing-4;
        margin-right: -$spacing-4;
    }
    @include desktop {
        display: flex;
        flex-wrap: wrap;
    }
    @include fullhd {
        letter-spacing: 0.8px;
    }
    > li {
        border: 0;
        padding: 0;
        text-transform: uppercase;
        margin-top: 0;
        > a {
            color: white;
            text-decoration: none;
            padding: $spacing-2 $spacing-3;
            display: block;
            /*@include widescreen {
                padding-left: $spacing-1;
                padding-right: $spacing-1;
                border:1px green solid;
            }*/
            @include tablet {
                padding-left: $spacing-1;
                padding-right: $spacing-1;
            }
            @include fullhd {
                padding: $spacing-3 $spacing-3;
            }
            &:hover {
                background-color: #01375d;
            }
        }
        &:before {
            display: none;
        }
    }
}
[aria-current="page"] {
    background: #01375d;
}
