.unstyle-list {
    margin-left: 0;
    > * + * {
        margin-top: $spacing-4;
    }
    li {
        padding: 0;
        border-bottom: 0;
        &:before {
            display: none;
        }
    }
}
