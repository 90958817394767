.grid-gallery {
    line-height: 0;
    font-size: 0;
    margin-left: -5px;
    img {
        display: inline-block;
        margin: 5px;
        @include tablet {
            height: 18rem;
        }
    }
}
