.home .main-content {
    @include desktop {
        margin-top: -3.125rem;
    }
    @include widescreen {
        margin-top: -3.5rem;
    }
    @include fullhd {
        margin-top: -4.166rem;
    }
}
.contacte .main-content {
    margin-top: 0;
}
