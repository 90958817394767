// mòbil
.hamburger {
    cursor: default;
    @include desktop {
        display: none;
    }
}

#menu {
    @include desktop {
        display: block;
    }
}
