h2.fons-color {
    font-size: $size-4;
    background-color: #f2f2f2;
    padding: $spacing-1 $spacing-4;
}
@include desktop {
    .fons-color ~ :not(.fons-color) {
        margin-left: $spacing-6;
    }
    .fons-color ~ ol {
        margin-left: 72px !important;
    }
}
