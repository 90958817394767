.section {
    position: relative;
    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: inherit;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    &::before {
        left: -100%;
        right: 0;
    }
    &::after {
        left: 100%;
    }
    h2 {
        font-weight: bold;
        font-size: $size-4;
        font-weight: bold;
    }
}
