html {
    scroll-behavior: smooth;
}
body {
    line-height: 1.5;
    overflow-x: hidden;
}
p {
    font-weight: 300;
    // font-size: $size-5;
    font-size: 1.125rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

h1 {
    font-size: $size-4;
    font-weight: 300;
    @include tablet {
        font-size: $size-3;
    }
    @include desktop {
        font-size: $size-2;
    }
}

h2 {
    font-size: $size-3;
    font-weight: 300;
    // color: $secondary;
  
    &.p {
        font-size: $size-5;
        text-transform: uppercase;
        font-weight: 600;
    }
    &.fons-color {
        background: #f2f2f2;
        padding: $spacing-1 $spacing-6;
        font-weight: 300!important;
        @include desktop {
            position: relative;
            font-weight: normal;
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                right: -200%;
                top: 0;
                bottom: 0;
                width: 200%;
                background-color: inherit;
            }
        }
    }
}

h3 {
    font-size: $size-4;
    font-weight: 600;
    &.f {
        background: #f2f2f2;
        padding: $spacing-1 $spacing-6;
        font-weight: normal;
        @include desktop {
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                right: -200%;
                top: 0;
                bottom: 0;
                width: 200%;
                background-color: inherit;
            }
        }
    }
    
}

h4 {
    font-size: $size-5;
    // font-weight: normal;
    
}
h5 {
    font-size: $size-5;
    font-weight: 600;
    margin: 0;
    &:not(:first-child) {
        //margin-top: $size-2 !important;
    }
}
h6 {
    font-size: $size-6;
    margin: 0;
    &:not(:first-child) {
        margin-top: $size-2 !important;
    }
}

ul,
ol {
    margin: 0;
}

ul {
    margin-left: 0;
    padding-left: 0;
    @include tablet {
        margin-left: $spacing-5;
    }
    > * + * {
        margin-top: $size-6;
    }
    li {
        position: relative;
        padding: 0 0 $size-6 $size-4;
        list-style: none;
        border-bottom: solid 1px #d5d5d5;
        //font-weight: 600;
        &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $primary;
            left: 0;
            top: 0.4em;
        }
        ul {
            padding: $size-7 0 0 0;
            margin-left: 0;
            > * + * {
                margin-top: $size-5;
            }
            li {
                padding: 0 0 0 $size-5;
                margin-bottom: 0;
                border-bottom: 0;
                font-weight: normal;
                &::before {
                    width: 8px;
                    height: 8px;
                    top: 0.5em;
                }
                ul {
                    li {
                        font-weight: 300;
                        padding: 0 0 0 $size-6;
                        &::before {
                            width: 4px;
                            height: 4px;
                            top: 0.6em;
                        }
                    }
                }
            }
        }
    }
}

ol {
    padding-left: 0;
    /*list-style-position: inside;*/
    @include tablet {
        margin-left: $spacing-5;
    }
    > * + * {
        margin-top: $size-6;
    }
    li {
        padding: 0;
        &::marker {
            color: $primary;
            font-size: $size-5;
            font-weight: 300;
        }
    }
}

a {
    // color: currentColor;
    color: $primary;
    transition: text-decoration-color 0.1s ease-in;
    &:hover {
        text-decoration-color: transparent;
    }
}

hr {
    border: 0;
    border-top: 1px solid #333;
}

address {
    font-style: normal;
}

abbr {
    text-decoration: none;
}

// :focus {
//   /*https://matthiasott.com/notes/focus-visible-is-here*/
//   outline: 2px dotted;
//   outline-offset: 0.25rem;
//   &:not(:focus-visible) {
//     outline: 0;
//   }
//   &:focus-visible {
//     outline: 2px dotted;
//     outline-offset: 0.25rem;
//   }
// }

strong {
    font-weight: bold;
}
em {
    font-style: italic;
}
em > strong,
strong > em {
    font-weight: bold;
    font-style: italic;
}

figcaption {
    font-size: $size-7;
    margin-top: $spacing-1;
}

img {
    height: auto;
}

fieldset {
    border: 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}
legend {
    font-size: $size-4;
}

// summary {
//   letter-spacing: 1px;
//   padding: get-size("300") * 0.75 get-size("400");
//   position: relative;
//   &::marker {
//     content: "";
//   }
//   /*Safari: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details#customizing_the_disclosure_widget*/
//   &::-webkit-details-marker {
//     display: none;
//   }
//   &::after {
//     content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzMDQpIHRyYW5zbGF0ZSgxMTQ4IDIzMDQpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     position: absolute;
//     right: get-size("400");
//     top: 50%;
//     transform: translateY(-50%);
//   }
//   + div {
//     padding: get-size("300") get-size("400") get-size("400");
//     > ul:not([role]) {
//       margin-left: 0;
//       > li {
//         padding-left: get-size("400");
//         &::before {
//           content: "";
//           width: get-size("300") * 0.5;
//           height: get-size("300") * 0.5;
//           background-color: get-color("primary");
//           top: 0.4em;
//         }
//       }
//     }
//   }
// }
// details {
//   border: 2px solid get-color("black");
//   &[open] {
//     summary::after {
//       content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDIwIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOS45OTggMTEuNzU0TDEwLjAwMSAxMS43NTYgMTIuNDMxIDkuMzI2IDEyLjk2MyA4Ljc4OSAxOS4xMzEgMi42MjEgMTYuNyAwLjE5IDEwLjAwMiA2Ljg4OCAzLjMwNCAwLjE5IDAuODczIDIuNjIxIDcuNTY5IDkuMzIyeiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExNDggLTIzNzkpIHJvdGF0ZSgtMTgwIDU4NCAxMTk1LjUpIi8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
//     }
//     * {
//       font-size: get-size("300");
//     }
//   }
// }

// A11y toggle (http://edenspiekermann.github.io/a11y-toggle/)
[aria-hidden="true"],
[data-a11y-toggle]:not([aria-controls]) {
    display: none;
}

// Excepcions (https://github.com/edenspiekermann/a11y-toggle/commit/74d9d7ff3fe98dd78f8ae96f08595691ea52c329)
/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.gm-style [aria-hidden="true"],
.slider [aria-hidden="true"],
.icon[aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
}

table {
    border-spacing: 10px;
    margin: -10px;
    tbody {
        tr {
            &:nth-child(odd) th,
            &:nth-child(odd) td
             {
                background-color: #f2f2f2;
            }
            td {
                padding: $spacing-2;
            }
            th {
                padding: $spacing-2;
                text-align: left;
            }
        }
    }
    thead {
        tr {
            th {
                padding: $spacing-2;
                text-align: left;
                background-color: $primary;
                color: $white;
                font-weight: normal;
                &:not(:first-child) {
                    color: $black;
                    background-color: $primary-light;
                }
            }
        }
    }
}

time {
    .noticies & {
    font-weight: bold;
    position: relative;
    margin-bottom: $spacing-3;
    display: block;
    &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 0.1px;
        background: black;
        bottom: -10px;
    }
}
}
iframe {
    border: none;
}

//estils per pàgina
