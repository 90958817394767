// ample de punta a punta
.full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    &--img {
        padding-top: 0;
        padding-bottom: 0;
        img {
            width: 100vw;
            margin-left: calc(50% - 50vw);
            max-width: none;
        }
    }
    // &--primary {
    //     color: white;
    //     background-color: $primary;
    // }
    // &--primary-dark {
    //     color: white;
    //     background-color: $primary-dark;
    // }
    // &--secondary-dark {
    //     color: white;
    //     background: #01375d;
    // }
}
