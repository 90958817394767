.houdiniForm {
    legend {
        &.field {
            color: #c5c5c5;
            font-size: $size-4;
            font-weight: bold;
            padding-top: $spacing-2;
            display: block;
            &.sub {
                font-size: 16px;
                color: black;
            }
        }
    }
    .help-form {
        p {
            font-size: 14px;
            margin-top: 0.5rem;
            color: #a19f9f;
        }
    }
    label {
        &.label {
            margin-top: $spacing-2;
            margin-bottom: $spacing-2;
            .has-background-secondary-dark & {
                color: $white;
            }
        }
        &.error {
            color:#d0021b;
            font-size: 14px;
            background: url(/media/css/img/ico-avis-camp-obligatori.png) no-repeat;
            padding: $spacing-1 $spacing-4;
            background-position: left;
            display: block;
        }
    }
    .protecciodades {
        margin-top: $spacing-4;
        margin-bottom: $spacing-2;
    }
    
}
.ok-form,
.error-form {
    /* falten les imatges */
}
.sForm {
    width: 100%;
}