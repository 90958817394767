.has-submenu {
    // subemnú mòbil
    > ul {
        @include mobile {
            margin-bottom: $spacing-3;
        }
        > li > a {
            display: block;
        }
        ul {
            > * + * {
                margin-top: 0;
            }
            li {
                padding-left: 0;
                &::before {
                    display: none;
                }
                &:first-child a {
                    padding-top: 0;
                }
            }
        }
    }

    // submenú desktop
    @include tablet {
        position: relative;
        transition-duration: 0.5s;
        &:hover > ul,
        &:focus-within > ul,
        ul:hover,
        ul:focus {
            cursor: pointer;
            visibility: visible;
            opacity: 1;
            display: block;
            z-index: 1;
        }
        &::after {
            content: "\2193";
        }
        > ul {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            min-width: 15rem;
            transition: all 0.5s ease;
            display: none;
            &::before {
                @include triangle(0.5rem, $primary, top);
                position: absolute;
                top: 0;
                left: 0;
            }
            ul {
                margin-left: 0;
            }
        }
    }
}
