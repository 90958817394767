.news-list {
    .column {
        padding: $spacing-3 $spacing-3 $spacing-6 $spacing-3;
        border-bottom: 1px #d5d5d5 solid;
        h3 {

            font-weight: normal;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .resum p {
        font-size: $size-6;
    }
}
.content-6 p {
    font-size: $size-6;
    margin-bottom: 1.4rem;
}
