.gmaps {
    background-image: url("img/imatge-gmaps.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 255px;
    height: 255px;
    margin-top: $spacing-3;
    p {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 70%;
        &.button {
            background: transparent;
            border-color: white;
            a {
                text-decoration: none;
                color: white;
                &:hover {
                    color: $primary;
                }
            }
            &:hover {
                background: white;
            }
        }
    }
}
