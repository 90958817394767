.is-checkradio {
    &[type="checkbox"] {
        + label {
            &::before {
                top: 0.25rem;
            }
            &::after {
                top: 0.6rem;
            }
        }
        &:checked {
            + label {
                &::before {
                    background-color: $primary;
                    border-color: $primary;
                }
                &::after {
                    border-color: $white;
                }
            }
        }
    }
    &[type="radio"] {
        + label {
            &::before {
                top: 0.25rem;
            }
            &::after {
                top: 0.25rem;
            }
        }
    }
}
