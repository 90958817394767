.avis {
    background: #fffbcc;
    padding: $spacing-5 $spacing-7;
    text-align: center;
    .button {
        margin-top: $spacing-3;
        background: transparent;
        border-color: $primary !important;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        padding-left: $spacing-4;
        padding-right: $spacing-4;
        a {
            text-decoration: none;
        }
        &:hover {
            background: $primary;
            a {
                color: white;
            }
        }
    }
}
