.download-doc {
    border: 1px solid #000;
    padding: $size-6 $size-2 $size-6 $size-4;
    border-radius: 34px;
    //width: 380px;
    position: relative;
    display: block;
    font-size: $size-6;
    @include mobile {
        width: auto;
    }
    &:before {
        content: '';
        background-image: url('img/data-transfer-down.svg');
        width: 24px;
        height: 24px;
        display: inline-block;
        position: absolute;
        right: $size-6;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
