.help {
    font-weight: 600;
    margin-top: $size-7;
    &::before {
        content: "";
        display: inline-block;
    }
    &.is-success::before {
        width: 26px;
        height: 20px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNNS41OTMgMTYuNTUgOS4xMTMgMjBsLjAwNy0uMDA3LjAwNC4wMDQgMy41MS0zLjQ0Ljc1NS0uNzQ5TDI2IDMuNDQ0IDIyLjQ4OCAwIDkuMTE4IDEzLjEwOCAzLjUxMiA3LjYxMiAwIDExLjA1NWw1LjU5OSA1LjQ5eiIgZmlsbD0iIzYzQUExNiIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPgo=");
    }
    &.is-danger::before {
        width: 23px;
        height: 23px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjMiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMjEuMDI5IDEuOTcxYTQuMTUgNC4xNSAwIDAgMC0yLjk0NC0xLjIyIDQuMTQ5IDQuMTQ5IDAgMCAwLTIuOTQ0IDEuMjJMMi4zNjggMTQuNzQ0LjExIDIxLjQwMWExLjE2NCAxLjE2NCAwIDAgMCAuMjgxIDEuMjA1bC4wMDQuMDA0YTEuMTYyIDEuMTYyIDAgMCAwIDEuMjE0LjI3OGwtLjAwOC4wMDMgNi42NTctMi4yNThMMjEuMDMxIDcuODZhNC4xNSA0LjE1IDAgMCAwIDEuMjItMi45NDQgNC4xNDkgNC4xNDkgMCAwIDAtMS4yMi0yLjk0NGwtLjAwMi0uMDAxek03LjQ0MyAxOS4zMjVsLTUuNzAyIDEuOTM0IDEuOTM0LTUuNzAyIDkuNzg1LTkuNzg1IDMuNzY3IDMuNzY3LTkuNzg0IDkuNzg2ek0xOS45NjkgNi43OTlsLTEuNjggMS42OC0zLjc2Ny0zLjc2NyAxLjY4LTEuNjhhMi42NjQgMi42NjQgMCAxIDEgMy43NjcgMy43Njd6IiBmaWxsPSIjRDAwMjFCIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KPC9zdmc+Cg==");
    }
}
