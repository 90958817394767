.sub-nav {
    width: 100%;
    @include desktop {
        margin-top: $spacing-7;
    }
    li {
        padding: 0;
        border: 0;
        margin-bottom: $spacing-1;
        &:before {
            display: none;
        }
        a {
            display: block;
            text-decoration: none;
            color: black;
            border-radius: 34px;
            padding: $spacing-1 $spacing-3;
            border: 2px white solid;
            &:hover {
                border: 2px $primary solid;
                transition: border 0.15s ease-in-out;
            }
        }
        &.active {
            a {
                border: 2px $primary solid;
            }
        }
    }
}
#sub-nav {
    > ul {
        width: 100%;
        margin-left: 0;
        @include desktop {
            margin-top: 1rem;
        }
        > li {
            padding: 0;
            border: 0;
            margin-top: 0;
            font-weight: normal;
            &:before {
                display: none;
            }
            > a {
                display: block;
                text-decoration: none;
                color: black;
                border-radius: 34px;
                padding: 0.6rem $spacing-3;
                border: 2px white solid;
                &:hover {
                    // border-color: $primary;
                    // transition: border 0.15s ease-in-out;
                    background-color: #eee;
                }
            }
            [aria-current="page"] {
                background: transparent;
            }
            &.active {
                a {
                    border-color: $primary;
                }
            }
        }
        ul {
            padding-top: 0;
            font-size: $size-7;
            margin-left: 2.5rem;
            // margin-top: 0.5rem;
            //margin-bottom: 1rem;
            li {
                padding: 0;
                font-weight: 300;
                margin-top: 0.5rem;
                &::before {
                    display: none;
                }
                &.active {
                    a {
                        font-weight: bold;
                    }
                }
            }
            a {
                text-decoration: none;
                color: #000;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
