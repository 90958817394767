.unstyle {
    ul {
        margin-right: 80px;
        margin-left: 0;
        li {
            padding-left: 0;
            &:before {
                display: none;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
