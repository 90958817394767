// ample cap a la dreta
@include desktop {
    .half-bleed {
        width: 150%;
        height: 26rem;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
