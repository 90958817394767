.llista-blocks {
    margin: 30px 0 0 0;
    display: flex;
    align-items: baseline;
    gap: $spacing-3;
    li {
        padding:0;
        display: inline-block;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
        background: rgba($primary, 0.8);
        mix-blend-mode: multiply;
        background-blend-mode: multiply;
    }
    a {
        font-style: normal;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        text-decoration: none;
        font-size: $size-5;
        padding: 6rem $spacing-6 $spacing-3 $spacing-3;
        min-height: 215px;
        font-weight: 600;
        position: relative;
        &:hover {
            &:before {
                background-color: rgba(#1071b5, .6);
                transition: background-color .2s ease-in;
            }
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            background-color: rgba(#1071b5, .8);
        }
        span {
            position: relative;
        }
    }
}

@media (max-width: 864px) {
 .llista-blocks li,
 .llista-blocks {
     display: block;
     text-align: center;
 }
 .llista-blocks li a {
     min-height: auto;
     padding: $spacing-3;
 }
}