@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url(/fonts/Open-Sans-italic-300.woff) format('woff'), url(/fonts/Open-Sans-italic-300.ttf) format('truetype'), url(/fonts/Open-Sans-italic-300.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url(/fonts/Open-Sans-italic-400.woff) format('woff'), url(/fonts/Open-Sans-italic-400.ttf) format('truetype'), url(/fonts/Open-Sans-italic-400.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url(/fonts/Open-Sans-italic-700.woff) format('woff'), url(/fonts/Open-Sans-italic-700.ttf) format('truetype'), url(/fonts/Open-Sans-italic-700.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/Open-Sans-normal-300.woff) format('woff'), url(/fonts/Open-Sans-normal-300.ttf) format('truetype'), url(/fonts/Open-Sans-normal-300.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Open-Sans-normal-400.eot);
  src: local('Open Sans'), url(/fonts/Open-Sans-normal-400.woff) format('woff'), url(/fonts/Open-Sans-normal-400.svg#OpenSans) format('svg'), url(/fonts/Open-Sans-normal-400.ttf) format('truetype'), url(/fonts/Open-Sans-normal-400.eot?#iefix) format('embedded-opentype'), url(/fonts/Open-Sans-normal-400.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/Open-Sans-normal-600.woff) format('woff'), url(/fonts/Open-Sans-normal-600.ttf) format('truetype'), url(/fonts/Open-Sans-normal-600.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/Open-Sans-normal-700.woff) format('woff'), url(/fonts/Open-Sans-normal-700.ttf) format('truetype'), url(/fonts/Open-Sans-normal-700.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url(/fonts/Open-Sans-normal-800.woff) format('woff'), url(/fonts/Open-Sans-normal-800.ttf) format('truetype'), url(/fonts/Open-Sans-normal-800.woff2) format('woff2');
  font-display: swap;
}

