.breadcrumb,
.breadcrumbs {
    margin-left: 5px!important;
    //border: 1px green solid;
    margin-bottom: $spacing-1;
    li {
        display: inline;
        padding: 0 $spacing-3 0 0;
        border: 0;
        font-size: $size-7;
        a { 
            text-decoration: none;
            color: #000;
            &:hover {
                text-decoration: underline;
            }
            &:last-child {
                text-transform: none;
            }
        }
        &:before {
            content: ">";
            margin: 0;
            background: none;
            left: -13px;
            top: 0;
        }
        &:first-child {
            &:before {
                display: none;
            }
            a {
                //text-transform: uppercase;
            } 
        }
    }
}

/*@media screen and (min-width: 769px), print {
    ol.breadcrumb  {margin-left: 0!important;border: 1px green solid;}
}*/
