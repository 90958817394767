.search-form {
    @include desktop-only {
        flex-basis: 12rem;
        .control:last-child {
            flex-shrink: 0;
        }
    }
    .field {
        @include touch {
            justify-content: center;
        }
    }
    input {
        border-top-left-radius: $input-radius !important;
        border-bottom-left-radius: $input-radius !important;
        border-right: 0;
    }
    button {
        border-color: #000;
        border-left: 0;
    }
    input,
    button {
        &:hover {
            border-color: black;
        }
    }
    svg {
        height: auto !important;
        width: auto !important;
    }

    // home search
    &--home {
        .field {
            @include widescreen {
                justify-content: flex-start;
            }
        }
        input {
            border-top-left-radius: 27px !important;
            border-bottom-left-radius: 27px !important;
        }
        button {
            border-top-right-radius: 27px;
            border-bottom-right-radius: 27px;
        }
        .control:first-of-type {
            flex-basis: 30rem;
        }
        @include mobile {
            .control:last-child {
                flex-shrink: 0;
            }
        }
    }
}
