.slider {
    .js & {
        // visualització al carregar
        visibility: hidden;
        &.slick-initialized {
            visibility: visible;
        }
    }
    .slick-slide img {
        height: 26rem;
        object-fit: cover;
        width: 100%;
        display: block;

        @include tablet {
            height: 24rem;
        }
        @include desktop {
            height: 30rem;
        }
        @include mobile {
            height: 24rem;
        }

    }
    &__caption {
        position: absolute;
        z-index: 1;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 10rem;
        .slider__text {
            width: 60%;
            @media (min-width: 1300px) {
                width: 50%;
            }
            @media (max-width: 1100px) {
                width: 70%;
            }
            @media (max-width: 950px) {
                width: 100%;
                h2 {font-size: $size-5;}
                p {font-size: $size-6;}
            }
        }
    }
    .slick-dots {
        max-width: 98.125rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: $spacing-4;
        padding-right: $spacing-4;
        bottom: $spacing-3;
        // bottom: 0;
        text-align: right;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        li {
            border: 0;
            &:before {
                display: none;
            }
        }
    }
    .dot {
        width: $size-3;
        height: $size-3;
        cursor: pointer;
        // &::hover,
        // &::focus {
        //     background-color: red !important;
        // }
    }
    .slick-active {
        .dot {
            background-color: $grey !important;
        }
    }
}

.slick-slide {
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(#fff, 0.7);
        // @include desktop {
        // background-color: transparent;
        background-image: linear-gradient(to right, #fff 46%, rgba(255, 255, 255, 0) 80%);
        // }
    }
}

.slick-pause-icon {
    display: none !important;
}