.custom-list-2 {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    // margin-top: -0.75rem;
    font-weight: 600;
    font-size: $size-6;
    li {
      position: relative;
      padding: $spacing-3;
      border: 0;
      flex: none;
      margin: 0.75rem;
      width: calc(100% - 1.5rem);
      background-color: #f2f2f2;
        &::before {
            display: none;
        }
      @include tablet {
        width: 50%;
        width: calc(50% - 1.5rem);
        }
      &.clic {
        cursor: pointer;
      }
      p {
        font-size: .9375rem;
      }
    }
  }
  .ofertes__estat {
    position: relative;
    //padding: 5px;
    color: #273d3f;
    font-size: $size-7 !important;
    border-radius: 8px;
    margin-left: 30px;
    margin-top: 5px;
    &:before {
        content: '';
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        display: inline-block;
        left: -30px;
        top: -3px;
    }
    @include tablet {
        position: absolute;
        top: $spacing-2;
        right: $spacing-4;
        
    }
}
.ofertes__estat--1 {
    &:before {
    background-color: #c00;
    }
}
.ofertes__estat--2 {
    &:before {
    background-color: #d5692a;
    }
}
.ofertes__estat--0 {
    &:before {
    background-color: #76ac37;
    }
}
.ofertes__estat--3 {
    &:before {
    background-color: #0bc!important;
    }
}
.ofertes {
    li {
        position: relative;
    }
}
.ofertes {
  a {
    h3 {
      font-size: $size-5;
      display: block;
      max-width: 300px;
    }
  }
}