footer {
    margin-top: $spacing-7;
    ul:first-of-type {
        letter-spacing: 0.8px;
        @include mobile {
            > * + * {
                padding-top: $spacing-2;
            }
        }
        @include tablet {
            display: flex;
        }
    }
    li {
        margin-top: 0 !important;
        margin-left: $spacing-2;
        margin-right: $spacing-2;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    [aria-current="page"] {
        background: none;
        text-decoration: underline;
    }
    img + ul {
        @include desktop {
            transform: scale(0.65);
        }
    }
    .icon {
        fill: #000;
        width: auto;
        height: auto;
    }
}
.home footer {
    margin-top: $spacing-5;
}