.tels {
    a {
        white-space: nowrap;
    }
    @include touch {
        justify-content: center;
        li {
            font-size: $size-7;
        }
        a {
            font-size: $size-6 !important;
        }
    }
    @include desktop {
        justify-content: flex-end;
        margin-top: 0;
    }
    svg {
        width: auto;
        height: auto;
        position: relative;
        top: -2px;
    }
    &.tels--center {
        @include desktop {
            justify-content: flex-start;
        }
    }
}
