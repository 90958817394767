.home-logos-list {
    gap: $spacing-4;
    height: 100%;
    flex-direction: column;
    li {
        flex-basis: 50%;
        //background-color: #f2f2f2;
    }
    //a {
        //height: 100%;
    //}
    img {
        margin: auto;
    }
}
