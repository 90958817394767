.serveis .servei {
    ul {
        margin-left: 0;
        
        li {
            /*padding-left: 0;*/
            font-size: calc(18px);
            font-weight: 100;
            border-bottom: 1px $primary solid;
            &:before {
                display: none;
            }
            ul {
                //margin-top: $spacing-2;
                //margin-bottom: $spacing-4;
                padding-left: $spacing-5;
                //padding-top: $spacing-4;
                /*border-top: 1px $primary solid;*/
                li {
                    border: 0;
                    font-size: calc(16px);
                    font-weight: 600;
                }
            }
        }
        &.bg-w {
            li {
                border-bottom: 0;
            }
        }
    }
    h2 {
        text-transform: uppercase;
        font-size: calc(22px);
        font-weight: 600;
        //margin-top: $spacing-6!important;
        //margin-bottom: $spacing-6!important;
    }
    h3 {
        //margin-left: 0;
        font-weight: 600;
        font-size: $size-5;
    }
    .telf {
        .b {
            font-size: calc(24px);
            font-weight: 600;
        }
    }
    .avis {
        max-width: none;
    }
    .info-servei {
        p {
            font-size: calc(16px);
        }
    }
    .collapsible ul {
        margin-left: $spacing-2;
        li {
            border: 0;
            font-size: 16px;
            &:before {
                display: block;
            }
        }
    }
    @include desktop {
        .fons-color ~ :not(.fons-color) {
            margin-left: $spacing-6;
        }
    }
    ul:not([class]) + hr {
        display: none
    }
    
}

