.site-title {
    @include mobile {
        width: 11.5rem;
        height: 3.125rem;
    }
    @include tablet-only {
        width: 18.5rem;
        height: 5.125rem;
    }
}
